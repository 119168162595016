<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="620"
    content-class="my-shadow--e2"
  >
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2">
        Edit field <v-icon small right>mdi-pencil</v-icon>
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="form.title"
              hide-details
              dense
              outlined
              label="Title name"
            />
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="form.title"
              hide-details
              dense
              outlined
              label="Type"
              placeholder="Type to search"
              :items="filtersData.types"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn large class="text-normal px-6" @click="dialog = false"
          >Close</v-btn
        >
        <v-btn
          large
          class="text-normal px-6"
          @click="handleCreate"
          color="success"
          >Edit field <v-icon small right>mdi-pencil</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "@/mixins/Dialog";

export default {
  mixins: [Dialog],

  data: () => ({
    filtersData: {
      types: [],
    },
    form: {
      title: null,
    },
  }),

  methods: {
    handleCreate() {
      console.log(this.form);
    },
  },
};
</script>
