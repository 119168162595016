import moment from "moment";
import { DEFAULT_MOMENT_DATE_FORMAT } from "@/utils/defaultData";

export function uid() {
  return "id" + Math.random().toString(16).slice(2);
}

export const deepClone = (data) => JSON.parse(JSON.stringify(data));

export function getPreviousWeekday(format = DEFAULT_MOMENT_DATE_FORMAT) {
  const today = moment();
  let previousDay = today.clone().subtract(1, "days");

  while (previousDay.day() === 0 || previousDay.day() === 6) {
    previousDay = previousDay.subtract(1, "days");
  }

  return previousDay.format(format);
}

export function getColorOfPercent(value) {
  const hue = ((value / 100) * 120).toString(10);
  return "hsl(" + hue + `, 100%, ${value < 30 ? "52%" : "45%"})`;
}
export function customTableSort(items, sortBy, sortDesc) {
  let sortedItems = null;

  sortedItems = items.sort((a, b) => {
    for (let i = 0; i < sortBy.length; i++) {
      if (a[sortBy[i]] > b[sortBy[i]]) {
        return sortDesc[i] ? 1 : -1;
      }
      if (a[sortBy[i]] < b[sortBy[i]]) {
        return sortDesc[i] ? -1 : 1;
      }
    }
    return 0;
  });

  if (sortedItems === null) {
    return items;
  }
  return sortedItems;
}

export function remove(arr, ...args) {
  let set = new Set(args);
  return arr.filter((v, k) => !set.has(k));
}

export const get = (from, ...selectors) =>
  [...selectors].map((s) =>
    s
      .replace(/\[([^[\]]*)\]/g, ".$1.")
      .split(".")
      .filter((t) => t !== "")
      .reduce((prev, cur) => prev && prev[cur], from)
  )[0];

export const set = (schema, path, value) => {
  const pList = path.split(".");
  const len = pList.length;
  for (let i = 0; i < len - 1; i++) {
    const elem = pList[i];
    if (!schema[elem]) schema[elem] = {};
    schema = schema[elem];
  }

  schema[pList[len - 1]] = value;
};

export function CookieHandler() {
  this.set = function (name, value, expirationDays = 7) {
    const date = new Date();
    date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${encodeURIComponent(
      value
    )}; ${expires}; path=/; domain=.serpinsider.com;`;
  };

  this.get = function (name) {
    const cookies = document.cookie
      .split(";")
      .map((cookie) => cookie.trim().split("="))
      .reduce((accumulator, [name, value]) => {
        accumulator[name] = decodeURIComponent(value);
        return accumulator;
      }, {});

    return cookies[name];
  };

  this.removeSingle = function (name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };
}

export function isJsonValid(jsonString) {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
}

export function getInitials(name) {
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

  let initials = [...name.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();

  return initials;
}

export function isEmpty(value) {
  if (value == null) return true; // null or undefined
  if (typeof value === "object") return Object.keys(value).length === 0;
  if (Array.isArray(value)) return value.length === 0;
  if (typeof value === "string") return value.trim().length === 0;
  return false;
}

export const formatCamelCaseString = (input) => {
  return input
    .replace(/([A-Z])/g, " $1") // Добавляем пробел перед каждой заглавной буквой
    .trim() // Убираем лишние пробелы по краям
    .replace(/^./, (char) => char.toUpperCase()); // Делаем первую букву заглавной
};
