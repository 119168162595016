<template>
  <v-card rounded="lg" class="styled-card--default">
    <v-card-title>
      <section-title :title="data.title" :data="data" />
    </v-card-title>
    <v-divider />
    <v-card-title>
      <v-text-field
        v-model="searchQuery"
        hide-details
        dense
        outlined
        placeholder="Type to search"
        append-icon="mdi-magnify"
        clearable
      />
    </v-card-title>
    <v-divider />
    <v-card-text>
      <div style="max-width: 1200px; margin: auto">
        <v-data-table
          :search="searchQuery"
          class="progress-table relative-tr"
          :headers="getHeaders"
          dense
          :items="data.data[0].items"
          :items-per-page="-1"
          hide-default-footer
        >
          <template
            v-for="(h, idx) in getHeaders"
            #[`item.${h.value}`]="{ item }"
          >
            <template
              v-if="![0, getHeaders.length - 1].includes(idx) && item[h.value]"
            >
              <v-tooltip bottom :key="h.value">
                <template #activator="{ on }">
                  <div v-on="on" class="progress-table__progress-cell">
                    <div class="progress-cell__value">
                      {{
                        String(item[h.value].total) === "0"
                          ? ""
                          : item[h.value].total
                      }}
                    </div>
                    <div
                      class="progress-cell__progress"
                      :style="{
                        width: `calc(${
                          item[h.value].percent && item[h.value].percent >= 100
                            ? 100 + '%'
                            : item[h.value].percent + '%' || '0%'
                        } ${
                          item[h.value].percent && item[h.value].percent >= 100
                            ? '- 10px'
                            : ''
                        })`,
                      }"
                    />
                  </div>
                </template>
                <div>
                  {{ item[h.value].value }} /
                  {{ item[h.value].total }}
                </div>
              </v-tooltip>
            </template>
            <template
              v-else-if="idx === getHeaders.length - 1 && item[h.value]"
            >
              <div :key="h.value" style="margin: 2px 0">
                <v-sparkline
                  :value="item[h.value]"
                  line-width="6"
                  type="bar"
                  :gradient="['#639ee0', '#639ee0']"
                />
              </div>
            </template>
            <template v-else>
              {{ item[h.value] }}
            </template>
          </template>
        </v-data-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import SectionTitle from "@/components/Dashboard4DX/components/SectionTitle.vue";

export default {
  components: { SectionTitle },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    searchQuery: null,
  }),
  computed: {
    getHeaders() {
      return [
        {
          text: "Page type",
          value: "name",
          width: "140px",
        },
        {
          text: "All",
          value: "total",
          align: "center",
          width: "95px",
        },
        {
          text: "1",
          value: "1",
          align: "center",
          width: "95px",
        },
        {
          text: "2-3",
          value: "2-3",
          align: "center",
          width: "95px",
        },
        {
          text: "4-5",
          value: "4-5",
          align: "center",
          width: "95px",
        },
        {
          text: "6-10",
          value: "6-10",
          align: "center",
          width: "95px",
        },
        {
          text: "11-20",
          value: "11-20",
          align: "center",
          width: "95px",
        },
        {
          text: "21-50",
          value: "21-50",
          align: "center",
          width: "95px",
        },
        {
          text: "51-100",
          value: "51-100",
          align: "center",
          width: "95px",
        },
        {
          text: "Dynamic",
          value: "sparkline",
          align: "center",
          width: "120px",
        },
      ];
    },
  },
  methods: {
    getSparklineOptions() {
      return {
        chart: {
          type: "bar",
          width: 100,
          height: 35,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "80%",
          },
        },
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function () {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      };
    },
  },
};
</script>

<style lang="scss">
.progress-table {
  tbody {
    tr {
      td {
        position: relative;
        border-right: 1px solid rgba(155, 155, 155, 0.3);
      }
    }
  }
  &__progress-cell {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    .progress-cell {
      &__value {
        z-index: 2;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-weight: 600;
      }
      &__progress {
        z-index: 1;
        position: absolute;
        left: 5px;
        top: 5px;
        display: inline-block;
        height: calc(100% - 10px);
        background-color: rgba(92, 162, 215, 0.49);
        border-radius: 4px;
      }
    }
  }
}
</style>
