<template>
  <div id="chart">
    <apexchart
      type="bar"
      height="40"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "BarChart",
  props: ["series"],
  components: { apexchart: VueApexCharts },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "bar",
          height: "90px",
          stacked: true,
          stackType: "100%",
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          enabled: true,
          theme: "dark",
          x: {
            show: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        grid: {
          borderColor: "transparent",
          padding: {
            left: 0,
            right: 0,
            bottom: -10,
            top: -20,
          },
        },
        legend: {
          show: false,
        },
      },
    };
  },
};
</script>

<style scoped>
.apexcharts-xaxistooltip {
  background: #1b213b;
  color: #fff;
}
</style>
