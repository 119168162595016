<template>
  <v-container fluid style="padding-bottom: 200px">
    <v-row>
      <v-col cols="12">
        <h2>Url Control</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
      <v-col cols="12">
        <v-form ref="formRef">
          <v-row dense>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.project_categories"
                :items="filtersData.project_categories"
                :rules="computedProjectAndCategoriesRules"
                multiple
                hide-details="auto"
                dense
                outlined
                label="Project categories"
                placeholder="Type to search"
              />
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.project"
                label="Project"
                :items="filtersData.projects"
                :loading="filtersDataPendingKeys?.projects"
                :rules="computedProjectAndCategoriesRules"
                outlined
                dense
                clearable
                hide-details="auto"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill"></v-col>
            <v-col cols="6" md="4" lg="2" xl="fill"></v-col>
            <v-col cols="6" md="4" lg="2" xl="fill"></v-col>
            <v-col cols="6" md="4" lg="2" xl="fill" class="d-flex justify-end">
              <v-btn
                color="primary"
                height="40"
                class="text-normal px-6"
                @click="loadData"
                :loading="loading"
              >
                Get data <v-icon small right>mdi-refresh</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="12">
        <div class="mb-2 d-flex">
          <v-menu
            bottom
            offset-y
            content-class="my-shadow--e3"
            :close-on-content-click="false"
          >
            <template #activator="{ on }">
              <v-btn class="text-normal" v-on="on">
                <v-icon left small>mdi-chevron-down</v-icon>
                Actions
                <my-badge
                  class="ml-1 success white--text"
                  v-if="selected.length > 0"
                >
                  {{ selected.length }}
                </my-badge>
              </v-btn>
            </template>
            <v-card class="styled-card pa-0">
              <v-list dense>
                <v-list-item @click="handleAction(selected, 'resetSw')">
                  <v-list-item-icon>
                    <v-icon v-if="!loadings.resetSw">mdi-tray-remove</v-icon>
                    <v-progress-circular
                      v-else
                      size="18"
                      width="2"
                      indeterminate
                    />
                  </v-list-item-icon>
                  <v-list-item-content> Reset SW </v-list-item-content>
                </v-list-item>
                <v-list-item @click="handleAction(selected, 'resetSwStatus')">
                  <v-list-item-icon>
                    <v-icon v-if="!loadings.resetSwStatus"
                      >mdi-tray-remove</v-icon
                    >
                    <v-progress-circular
                      v-else
                      size="18"
                      width="2"
                      indeterminate
                    />
                  </v-list-item-icon>
                  <v-list-item-content> Reset SW Status </v-list-item-content>
                </v-list-item>
                <v-list-item @click="handleAction(selected, 'removeUrl')">
                  <v-list-item-icon>
                    <v-icon v-if="!loadings.removeUrl">mdi-trash-can</v-icon>
                    <v-progress-circular
                      v-else
                      size="18"
                      width="2"
                      indeterminate
                    />
                  </v-list-item-icon>
                  <v-list-item-content> Remove URL </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>

          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            placeholder="Type to search"
            dense
            outlined
            hide-details
            style="max-width: 320px"
          ></v-text-field>
        </div>
        <v-card rounded="lg" outlined flat>
          <v-card-text class="pa-0 pt-1">
            <template v-if="loading">
              <v-skeleton-loader type="table" />
            </template>
            <template v-else>
              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="items"
                multi-sort
                :sort-by="['priority']"
                :sort-desc="[false]"
                :custom-sort="customSort"
                :search="search"
                show-select
                item-key="url_id"
              >
                <template #[`item.url`]="{ item }">
                  <div class="d-flex justify-space-between">
                    <span>{{ item.url }}</span>
                    <v-btn icon @click="changeUrl(item)">
                      <v-icon small>mdi-pencil-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <template #[`item.actions`]="{ item }">
                  <v-menu
                    bottom
                    offset-y
                    content-class="my-shadow--e3"
                    :close-on-content-click="false"
                  >
                    <template #activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-card class="styled-card pa-0">
                      <v-list dense>
                        <v-list-item @click="handleAction([item], 'resetSw')">
                          <v-list-item-icon>
                            <v-icon v-if="!loadings.resetSw"
                              >mdi-tray-remove</v-icon
                            >
                            <v-progress-circular
                              v-else
                              size="18"
                              width="2"
                              indeterminate
                            />
                          </v-list-item-icon>
                          <v-list-item-content> Reset SW </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          @click="handleAction([item], 'resetSwStatus')"
                        >
                          <v-list-item-icon>
                            <v-icon v-if="!loadings.resetSwStatus"
                              >mdi-tray-remove</v-icon
                            >
                            <v-progress-circular
                              v-else
                              size="18"
                              width="2"
                              indeterminate
                            />
                          </v-list-item-icon>
                          <v-list-item-content>
                            Reset SW Status
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="handleAction([item], 'removeUrl')">
                          <v-list-item-icon>
                            <v-icon v-if="!loadings.removeUrl"
                              >mdi-trash-can</v-icon
                            >
                            <v-progress-circular
                              v-else
                              size="18"
                              width="2"
                              indeterminate
                            />
                          </v-list-item-icon>
                          <v-list-item-content>
                            Remove URL
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                </template>
              </v-data-table>
            </template>
          </v-card-text>
        </v-card>
        <UploadUrls />
      </v-col>
    </v-row>
    <v-dialog
      v-model="changeUrlDialogModel"
      content-class="my-shadow--e4"
      max-width="450"
    >
      <v-card flat outlined rounded="lg">
        <v-card-title class="text-body-2">
          Update Url
          <v-spacer />
          <v-icon @click="changeUrlDialogModel = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-subtitle class="pt-2">
          Enter the url that belongs to the project.
        </v-card-subtitle>
        <v-divider />
        <v-card-text class="pt-4">
          <v-text-field
            v-model="changeUrlData.value"
            label="Value"
            clearable
            hide-details
            dense
            filled
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            large
            @click="changeUrlDialogModel = false"
            class="px-6 text-normal"
          >
            Close
          </v-btn>
          <v-btn
            @click="handleSaveNewUrl"
            :loading="loadings.changeUrl"
            large
            class="px-6 text-normal"
            :color="hasValidationError ? 'error' : 'success'"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import UploadUrls from "@/components/UrlControl/Dialogs/UploadUrls";
import MyBadge from "@/components/UI/MyBadge.vue";
import service from "@/plugins/service";
import FiltersHandling from "../../mixins/components/FiltersHandling";
import UploadFiltersDataMixin from "../../mixins/UploadFiltersDataMixin";

export default {
  name: "Index",
  components: { MyBadge, UploadUrls },
  mixins: [UploadFiltersDataMixin, FiltersHandling],
  data: () => ({
    filters: {
      project_categories: [],
      project: null,
    },
    filtersData: {
      projects: [],
      project_categories: [],
    },
    selected: [],
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Site workplace",
        disabled: true,
      },
      {
        text: "Url control",
        disabled: true,
      },
    ],
    search: "",
    loading: false,
    hasValidationError: false,
    headers: [
      { text: "Url", value: "url", align: "left" },
      { text: "Canonical", value: "canonical", align: "left" },
      { text: "Code", value: "code", align: "center" },
      { text: "Priority", value: "priority", align: "center" },
      { text: "Need Top", value: "need_top", align: "center" },
      {
        text: "Workplace Deadline",
        value: "workplace_deadline",
        align: "center",
      },
      { text: "", align: "start", value: "actions", width: 70 },
    ],
    items: [],
    changeUrlDialogModel: false,
    loadings: {
      changeUrl: false,
      resetSw: false,
      resetSwStatus: false,
      removeUrl: false,
    },
    changeUrlData: {
      project: null,
      url: null,
      value: null,
    },
  }),
  async mounted() {
    await this.parseQuery("filters");
    this.uploadFiltersData(["project_categories", "projects"]);

    if (Object.keys(this.$route.query).length) this.loadData();
  },
  computed: {
    computedProjectAndCategoriesRules() {
      let rules = [];

      if (!this.filters.project)
        rules.push("Select project or Project category.");
      if (!this.filters.project_categories.length)
        rules.push("Select project or Project category.");

      if (this.filters.project || !!this.filters.project_categories.length)
        rules = [];

      console.log("rules", rules, this.filters);

      return rules;
    },
    id_project() {
      return this.filters.project;
    },
  },
  methods: {
    async handleAction(items, type) {
      if (!confirm("Are you sure?")) return;

      const actions = {
        resetSw: this.handleResetSW,
        resetSwStatus: this.handleResetSWStatus,
        removeUrl: this.handleRemoveUrl,
      };

      const action = actions[type];

      this.loadings[type] = true;

      const urls = items.map((v) => v.url_id);

      await action(urls);

      this.loadings[type] = false;

      this.loadData();
    },
    async handleResetSW(urls) {
      try {
        const url = "/url-control/reset-sw";

        const payload = {
          urls,
          project_id: this.id_project,
        };

        return await service.post(url, payload);
      } catch (e) {
        console.error(e);
      }
    },
    async handleResetSWStatus(urls) {
      try {
        const url = "/url-control/reset-sw-status";

        const payload = {
          urls,
          project_id: this.id_project,
        };

        return await service.post(url, payload);
      } catch (e) {
        console.error(e);
      }
    },
    async handleRemoveUrl(urls) {
      try {
        const url = "/url-control/delete-urls";

        const payload = {
          urls,
          project_id: this.id_project,
        };

        return await service.post(url, payload);
      } catch (e) {
        console.error(e);
      }
    },
    changeUrl(item) {
      this.changeUrlData.project = this.id_project;
      this.changeUrlData.url = item.url;
      this.changeUrlDialogModel = true;
    },
    async handleSaveNewUrl() {
      try {
        this.loadings.changeUrl = true;

        const resp = await this.$store.dispatch(
          "url-control/updateUrl",
          this.changeUrlData
        );

        if (resp && resp.success) {
          this.$message.notification({
            type: "success",
            duration: 10000,
            title: "Success",
            text: resp.message,
            position: "lt",
          });

          this.changeUrlData.value = null;
          this.changeUrlDialogModel = false;
          this.loadData();
        }

        this.loadings.changeUrl = false;
      } catch (error) {
        this.hasValidationError = true;
        setTimeout(() => (this.hasValidationError = false), 2000);
        this.$message.notification({
          type: "warning",
          duration: 10000,
          title: "Validation",
          text: error.response.data.message,
        });

        this.loadings.changeUrl = false;
      }
    },
    customSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (sortBy[i] === "priority" && parseInt(a[sortBy[i]]) === 0)
            return 1;
          if (sortBy[i] === "priority" && parseInt(b[sortBy[i]]) === 0)
            return -1;

          if (parseInt(a[sortBy[i]]) > parseInt(b[sortBy[i]]))
            return sortDesc[i] ? -1 : 1;
          if (parseInt(a[sortBy[i]]) < parseInt(b[sortBy[i]]))
            return sortDesc[i] ? 1 : -1;
        }

        return 0;
      });

      return items;
    },
    async loadData() {
      if (!this.$refs.formRef?.validate()) return;

      try {
        this.loading = true;

        const url = `/url-control`;

        const config = {
          params: {
            projectID: this.filters.project,
            ...this.filters,
          },
        };

        const response = await service.get(url, true, config);

        if (response) {
          this.items = response.data.items;
          this.headers = response.data.headers;
        }
      } catch (e) {
        this.sheet = true;
        console.error("Error while loading url control data.", e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
