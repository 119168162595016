<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <page-header
          title="Status manager"
          :breadcrumbs="[
            { text: 'Home', to: '/' },
            {
              text: 'Site workplace',
              to: { name: 'site-workplace.dashboard' },
            },
            { text: 'Status manager', disabled: true },
          ]"
        />
      </v-col>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="text-body-2 py-2">
            <b>Statuses</b>
            <v-spacer />
            <v-btn
              class="text-normal"
              color="primary"
              @click="handleCreateStatus"
            >
              Create new status <v-icon small right>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="px-0 pt-0 pb-2">
            <v-data-table
              :items="tableData.items"
              :headers="[...tableData.headers, { text: '', value: '_actions' }]"
            >
              <template #[`item._actions`]="{ item }">
                <div class="d-flex justify-end">
                  <v-btn class="text-normal" @click="handleEditStatus(item)">
                    Edit <v-icon small right>mdi-pencil</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <create-status-dialog v-model="isCreateStatusDialogVisible" />
    <edit-status-dialog
      v-model="isEditStatusDialogVisible"
      :data="editStatusData"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/UI/PageHeader.vue";
import { MOCK } from "./defaults";
import CreateStatusDialog from "./components/CreateStatusDialog.vue";
import EditStatusDialog from "./components/EditStatusDialog.vue";

export default {
  components: { EditStatusDialog, CreateStatusDialog, PageHeader },

  data: () => ({
    isCreateStatusDialogVisible: false,
    isEditStatusDialogVisible: false,
    editStatusData: null,
    tableData: {
      items: MOCK.TABLE_ITEMS,
      headers: MOCK.TABLE_HEADERS,
    },
    mockEditData: {
      titles: [
        {
          from: null,
          to: null,
          title: "Test title 1",
          id: "id66e1d405094c5",
        },
      ],
      fields: [
        {
          status: null,
          performer: null,
          id: "id0aec9854d564d",
        },
      ],
      approves: [
        {
          status: null,
          performer: null,
          id: "id5be8e86fcb247",
        },
        {
          status: null,
          performer: null,
          id: "id52790e06faad5",
        },
      ],
    },
  }),

  methods: {
    handleCreateStatus() {
      this.isCreateStatusDialogVisible = true;
    },
    handleEditStatus(statusData) {
      console.log(statusData);
      this.isEditStatusDialogVisible = true;
      this.editStatusData = this.mockEditData;
    },
  },
};
</script>

<style lang="scss" scoped></style>
