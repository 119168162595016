<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col cols="12">
          <MainFilter />
        </v-col>
        <v-col v-show="prod" cols="12">
          <v-card flat class="pa-6">
            <div>
              <div class="d-flex justify-space-between" style="gap: 0.25rem">
                <strong style="font-size: 1.3rem">{{ url_data.title }}</strong>
                <v-chip
                  class="rounded-lg flat-chip--auto"
                  :style="{ color: getCodeColor(String(url_data.code)) }"
                >
                  Code - <b class="pl-2">{{ url_data.code }}</b>
                </v-chip>
              </div>
            </div>
            <div>
              <div>
                {{ url_data.description }}
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <div>
        <v-row v-show="prod">
          <v-col md="3">
            <TopSmallGoogleIndex />
          </v-col>
          <v-col md="3">
            <TopSmallFollow />
          </v-col>
          <v-col md="3">
            <TopSmallLinkTypes />
          </v-col>
          <v-col md="3">
            <TopSmallAnchorTypes />
          </v-col>
        </v-row>
        <v-row v-show="prod">
          <v-col>
            <Positions />
          </v-col>
        </v-row>
        <v-row v-show="prod">
          <v-col>
            <Links prop-type="default" />
          </v-col>
        </v-row>
        <v-row v-show="prod">
          <v-col md="4">
            <LinkSmallChart
              prop-ref="follow"
              prop-type="default"
              prop-title="DoFollow / NoFollow"
            />
          </v-col>
          <v-col md="4">
            <LinkSmallChart
              prop-ref="link_types"
              prop-type="default"
              prop-title="By Link Types"
            />
          </v-col>
          <v-col md="4">
            <LinkSmallChart
              prop-ref="anchor_types"
              prop-type="default"
              prop-title="By Anchor Types"
            />
          </v-col>
        </v-row>
        <v-row v-show="prod">
          <v-col>
            <Ltt />
          </v-col>
        </v-row>
        <v-row v-show="prod">
          <v-col>
            <Dynamic />
          </v-col>
        </v-row>
        <v-row v-show="prod">
          <v-col md="4">
            <KeywordEntries />
          </v-col>
          <v-col md="4">
            <AnchorTypes />
          </v-col>
          <v-col md="4">
            <WordInAnchors />
          </v-col>
        </v-row>
        <v-row v-show="prod">
          <v-col>
            <LinkTypes />
          </v-col>
        </v-row>
        <v-row v-show="prod">
          <v-col>
            <TopDistribution />
          </v-col>
        </v-row>
        <v-row v-show="prod">
          <v-col>
            <CommonTable />
          </v-col>
        </v-row>
        <v-row v-show="prod">
          <v-col>
            <PercentageLinkTypesToTotal />
          </v-col>
        </v-row>
        <v-row v-show="prod">
          <v-col>
            <PercentageAnchorTypesToTotal />
          </v-col>
        </v-row>
        <v-row v-show="prod">
          <v-col>
            <v-autocomplete
              label="Url"
              v-model="selectedCompetitorUrl"
              clearable
              solo
              hide-details
              @change="updateSelectedCompetitorUrl"
              :items="heatmapUrls"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-show="prod">
          <v-col>
            <CompetitorPositions />
          </v-col>
        </v-row>
        <v-row v-show="prod">
          <v-col>
            <Links prop-type="comp" />
          </v-col>
        </v-row>
        <v-row v-show="prod">
          <v-col md="4">
            <LinkSmallChart
              prop-ref="follow_comp"
              prop-type="comp"
              prop-title="DoFollow / NoFollow"
            />
          </v-col>
          <v-col md="4">
            <LinkSmallChart
              prop-ref="link_types_comp"
              prop-type="comp"
              prop-title="By Link Types"
            />
          </v-col>
          <v-col md="4">
            <LinkSmallChart
              prop-ref="anchor_types_comp"
              prop-type="comp"
              prop-title="By Anchor Types"
            />
          </v-col>
        </v-row>
        <v-row v-show="prod">
          <v-col md="12">
            <AnchorLinkTypes />
          </v-col>
          <v-col md="12">
            <DomainScore />
          </v-col>
          <v-col md="12">
            <UrlRating />
          </v-col>
        </v-row>
        <v-row v-show="prod">
          <v-col>
            <Semantic />
          </v-col>
        </v-row>
        <v-row v-show="prod">
          <v-col>
            <ByWords />
          </v-col>
        </v-row>
        <v-row v-show="prod">
          <v-col>
            <Top10 />
          </v-col>
        </v-row>
        <v-row v-show="prod">
          <v-col>
            <PhraseCompare />
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import eventPipe from "../../events/eventPipe";
import Links from "./Children/Links";
import PercentageLinkTypesToTotal from "./Children/PercentageLinkTypesToTotal";
import Dynamic from "./Children/Dynamic";
import LinkSmallChart from "./Children/LinkSmallChart";
import Positions from "./Children/Positions";
import MainFilter from "./Children/MainFilter";
import TopSmallGoogleIndex from "./Children/TopSmallCharts/GoogleIndex";
import TopSmallFollow from "./Children/TopSmallCharts/Follow";
import TopSmallLinkTypes from "./Children/TopSmallCharts/LinkTypes";
import TopSmallAnchorTypes from "./Children/TopSmallCharts/AnchorTypes";
import CommonTable from "./Children/CommonTable";
import PercentageAnchorTypesToTotal from "./Children/PercentageAnchorTypesToTotal";
import events from "../../events/components/OnePageAnalyzer/events";
import AnchorLinkTypes from "./Children/Heatmap/AnchorLinkTypes";
import { OnePageAnalyzerIndex } from "../../mixins/components/OnePageAnalyzer/Index";
import DomainScore from "./Children/Heatmap/DomainScore";
import UrlRating from "./Children/Heatmap/UrlRating";
import CompetitorPositions from "./Children/CompetitorPositions";
import Semantic from "./Children/Texts/Semantic";
import ByWords from "./Children/Texts/ByWords";
import Top10 from "./Children/Texts/Top10";
import PhraseCompare from "./Children/PhraseCompare";
import KeywordEntries from "./Children/Stacked/KeywordEntries";
import AnchorTypes from "./Children/Stacked/AnchorTypes";
import WordInAnchors from "./Children/Stacked/WordInAnchors";
import LinkTypes from "./Children/Stacked/LinkTypes";
import TopDistribution from "./Children/Stacked/TopDistribution";
import Ltt from "@/components/OnePageAnalyzer/Children/Ltt";
import LazyDomElementRenderingMixin from "../../mixins/LazyDomElementRenderingMixin";
import Project from "../../mixins/Project";

export default {
  name: "Show",
  components: {
    Ltt,
    TopDistribution,
    LinkTypes,
    WordInAnchors,
    AnchorTypes,
    KeywordEntries,
    PhraseCompare,
    Top10,
    ByWords,
    Semantic,
    CompetitorPositions,
    UrlRating,
    DomainScore,
    AnchorLinkTypes,
    PercentageAnchorTypesToTotal,
    CommonTable,
    MainFilter,
    Positions,
    TopSmallGoogleIndex,
    TopSmallFollow,
    TopSmallLinkTypes,
    TopSmallAnchorTypes,
    Links,
    LinkSmallChart,
    Dynamic,
    PercentageLinkTypesToTotal,
  },
  mixins: [OnePageAnalyzerIndex, LazyDomElementRenderingMixin, Project],
  data() {
    return {
      page: 1,
      url_data: {
        title: undefined,
        description: undefined,
        code: undefined,
      },
    };
  },
  computed: {
    activeProject() {
      return this.$store.state.project.active_project;
    },
    prod() {
      return !!this.$store.state.OnePageAnalyzer.selectedUrl;
    },
    selectedCompetitorUrl: {
      set: function (value) {
        this.$store.state.OnePageAnalyzer.selectedCompetitorUrl = value;
      },
      get: function () {
        return this.$store.state.OnePageAnalyzer.selectedCompetitorUrl;
      },
    },
    heatmapUrls() {
      let self = this;

      let urls = [self.selectedUrl];
      let urls_vision = Object.keys(this.vision);
      let items = [];

      urls_vision.forEach(function (url) {
        if (urls.indexOf(url) === -1) {
          urls.push(url);
        }
      });

      for (let i = 0; i < urls.length; i++) {
        let visibility = this.getVisibilityPercentByURL(urls[i]);

        items.push({
          text: `${visibility}% - ${urls[i]}`,
          value: urls[i],
          visibility: visibility,
        });
      }

      items.sort(function (a, b) {
        let visibility_a = a.visibility;
        let visibility_b = b.visibility;
        if (a.value === self.selectedUrl) {
          return -1;
        }
        if (b.value === self.selectedUrl) {
          return 1;
        }
        if (visibility_a === visibility_b) {
          return 0;
        }
        return visibility_a > visibility_b ? -1 : 1;
      });

      return items;
    },
  },
  methods: {
    getCodeColor(statusCode) {
      const statusColors = {
        // Informational responses
        "1xx": "blue",

        // Success responses
        200: "green", // OK
        201: "lime", // Created
        204: "teal", // No Content

        // Redirection messages
        "3xx": "orange",

        // Client error responses
        400: "yellow", // Bad Request
        401: "gold", // Unauthorized
        403: "darkorange", // Forbidden
        404: "red", // Not Found

        // Server error responses
        500: "purple", // Internal Server Error
        502: "darkred", // Bad Gateway
        503: "maroon", // Service Unavailable
      };

      // Match specific codes first
      if (statusColors[statusCode]) {
        return statusColors[statusCode];
      }

      // Default to category-based colors
      const statusCategory = `${statusCode[0]}xx`;
      return statusColors[statusCategory] || "gray"; // Default to gray if no match
    },
    updateSelectedCompetitorUrl() {
      events.$emit("update-selected-competitor-url");
    },
    fetchData() {
      let self = this;

      let url = `${self.$store.state.server_url}/one-page-analyzer/show?url=${self.selectedUrl}`;

      self.$store.state.loading = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.url_data = r.data.data.url_data;
        })
        .finally(() => {
          self.$store.state.loading = false;
        });
    },
  },

  async mounted() {
    await this.$nextTick();

    if (!this.activeProject) {
      this.$store.dispatch("toggle_select_project_menu");
    }

    this.$watch("activeProject", (data) => {
      if (!data) this.$store.dispatch("toggle_select_project_menu");
    });
  },

  created() {
    document.title = `OPA: ${this.$store.state.project.domain} | klih.space`;

    eventPipe.$on("update-active-project", () => {
      document.title = `OPA: ${this.$store.state.project.domain} | klih.space`;
    });

    events.$on("update-url", () => {
      this.fetchData();
      this.fetchVision();
    });

    events.$on("common-table-fetched", () => {
      for (let i = 1; i < this.heatmapUrls.length; i++) {
        let url = this.heatmapUrls[i].value;

        if (
          this.$store.state.OnePageAnalyzer.heatmap.anchor_links.items[url] !==
          undefined
        ) {
          this.selectedCompetitorUrl = url;
          break;
        }
      }

      this.updateSelectedCompetitorUrl();
    });
  },
};
</script>

<style>
.v-data-table-small {
  padding: 0 18px;
}

.v-data-table-small > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table-small > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table-small > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table-small > .v-data-table__wrapper > table > tfoot > tr > th,
.v-data-table-small > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table-small > .v-data-table__wrapper > table > thead > tr > th {
  padding: 0 5px !important;
}
</style>
