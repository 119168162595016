<template>
  <v-container fluid class="managers-gpm" style="padding-bottom: 200px">
    <v-row dense>
      <v-col cols="12">
        <h2>Managers GPM</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row class="mt-6" dense>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-text-field
              v-model="filters.last_activity_days"
              type="number"
              label="Last activity (Days)"
              placeholder="Days"
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.task"
              :items="filtersData.task"
              dense
              hide-details
              item-text="name"
              item-value="id"
              multiple
              outlined
              clearable
              small-chips
              deletable-chips
              label="Task"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.mainSeo"
              :items="seoItems"
              dense
              hide-details
              placeholder="Select user"
              item-text="email"
              item-value="id"
              outlined
              clearable
              :loading="loadings.seo"
              small-chips
              label="Main seo"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <easy-range-input
              label="PTK"
              :model-value="filters.pos_by_key"
              @update:modelValue="filters.pos_by_key = $event"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.current_step"
              label="Current Step"
              :items="filtersData.current_step"
              outlined
              clearable
              hide-details
              dense
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.current_status"
              :items="filtersData.current_status"
              outlined
              hide-details
              dense
              label="Current Status"
              placeholder="Select"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.niche"
              :items="niches"
              multiple
              label="Niche"
              item-text="name"
              item-value="id"
              outlined
              dense
              clearable
              hide-details
              :menu-props="menuProps"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.name }}</span>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ filters.niche.length - 1 }} more)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.team"
              :items="filtersData.team"
              outlined
              hide-details
              dense
              multiple
              label="Team"
              placeholder="Select"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.project"
              :items="projects"
              item-text="name"
              item-value="id"
              label="Project"
              outlined
              dense
              clearable
              hide-details
              :menu-props="menuProps"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.name }}</span>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ filters.project.length - 1 }} more)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.subproject"
              :items="filtersData.subprojects"
              :loading="filtersDataPendingKeys?.subprojects"
              label="Subproject"
              outlined
              dense
              clearable
              hide-details
              :menu-props="menuProps"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.text }}</span>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ filters.subproject.length - 1 }} more)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.executive"
              :items="filtersData.executive"
              multiple
              label="Performer"
              outlined
              dense
              clearable
              hide-details
              chips
              small-chips
              :loading="loadings.filters"
              :menu-props="menuProps"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.text }}</span>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ filters.executive.length - 1 }} more)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <div class="content-checker__range-filter">
              <easy-range-input
                label="Need Top"
                :model-value="filters.need_top"
                :max="255"
                @update:modelValue="filters.need_top = $event"
              />
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <div class="content-checker__range-filter">
              <easy-range-input
                label="Cost"
                :model-value="filters.cost"
                :presets="costPresets"
                @update:modelValue="filters.cost = $event"
              />
            </div>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <div class="fix-button-height">
              <smart-date-filter
                :model-value="filters.date"
                @update:modelValue="filters.date = $event"
              />
            </div>
          </v-col>
          <v-col cols="fill" class="d-flex justify-end">
            <v-btn
              :loading="loadings.table"
              @click="handleGetData"
              color="primary"
              width="160"
              class="text-normal px-6"
              style="height: 40px"
            >
              Get Data
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-checkbox
              v-model="filters.overdue"
              label="Overdue"
              style="margin: 0"
              :true-value="1"
              :false-value="0"
              hide-details
              dense
            ></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox
              v-model="filters.hide_closed"
              label="Hide closed"
              style="margin: 0"
              :true-value="1"
              :false-value="0"
              hide-details
              dense
            ></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox
              v-model="filters.donor_assigned"
              label="Donor Assigned"
              style="margin: 0"
              :true-value="1"
              :false-value="0"
              hide-details
              dense
            ></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox
              v-model="filters.donor_not_assigned"
              label="Donor not assigned"
              :true-value="1"
              :false-value="null"
              class="ma-0"
              hide-details
              dense
            />
          </v-col>
          <v-col></v-col>
          <v-col></v-col>
          <v-col>
            <v-radio-group
              v-model="filters.is_gp"
              hide-details
              dense
              class="ma-0"
            >
              <v-radio label="Crowd" :value="0"></v-radio>
              <v-radio label="Outreach" :value="1"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="pa-4 text-body-2">
            <v-text-field
              v-model="searchModel"
              label="Search in table"
              outlined
              placeholder="Type to search"
              hide-details
              dense
              append-icon="mdi-magnify"
              style="max-width: 320px"
            ></v-text-field>
          </v-card-title>
          <v-divider />
          <v-card-text class="px-0 pt-0 pb-2">
            <v-progress-linear :active="loadings.table" indeterminate />
            <v-data-table
              :headers="getHeaders"
              :items="tableData.items"
              :custom-sort="customSort"
              :sort-by="sortBy"
              :search="searchModel"
              :sort-desc="[false, true, false, false, false]"
              :footer-props="{ itemsPerPageOptions: [50, 100, 300, -1] }"
              :items-per-page="100"
              class="managers-gpm-table show-icons-on-hover"
              multi-sort
            >
              <template
                v-for="h in tableData.headers"
                v-slot:[`header.${h.value}`]="{ header }"
              >
                <v-tooltip bottom :key="h.text">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{ header.text }}
                      <v-chip
                        v-if="
                          h.value === 'donor' &&
                          approvingPlacementBySeoDonors > 0
                        "
                        small
                        color="success"
                      >
                        {{ approvingPlacementBySeoDonors }}
                      </v-chip>
                    </span>
                  </template>
                  <div style="max-width: 150px" v-html="header.tooltip" />
                </v-tooltip>
              </template>
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td-url :data="item" />
                    <!--URL-->
                    <td-anchor :data="item" />
                    <!--Anchor-->
                    <td>
                      {{ item.link_type }}
                    </td>
                    <!-- Link Type -->
                    <td-pos-by-anchor :data="item" />
                    <!--PA-->
                    <td-pos-by-key :data="item" />
                    <!--PTK-->
                    <td-price
                      v-if="headerExistIn(getHeaders, 'price')"
                      :data="item"
                    />
                    <!--Cost-->
                    <td v-if="headerExistIn(getHeaders, 'price_link')">
                      {{ item.price_link && item.price_link.text }}
                    </td>
                    <!--Const per link-->
                    <td-donor :data="item" />
                    <!--Donor Domain-->
                    <td-wm-response-at
                      v-if="headerExistIn(getHeaders, 'wm_response_at')"
                      :data="item"
                    />
                    <!--WR-->
                    <td-tech-task-appear
                      v-if="headerExistIn(getHeaders, 'tech_task_appear')"
                      :data="item"
                    />
                    <!--TPA-->
                    <td-theme-approve-at
                      v-if="headerExistIn(getHeaders, 'theme_approve_at')"
                      :data="item"
                    />
                    <!--TA-->
                    <td-main-keywords
                      v-if="headerExistIn(getHeaders, 'main_keywords')"
                      :data="item"
                    />
                    <!--MK-->
                    <td-content-ready
                      v-if="headerExistIn(getHeaders, 'content_ready_at')"
                      :data="item"
                    />
                    <!--CR-->
                    <td-content-appear-at
                      v-if="headerExistIn(getHeaders, 'content_appear_at')"
                      :data="item"
                    />
                    <!--CNT-->
                    <td-on-site-appear-at
                      v-if="headerExistIn(getHeaders, 'on_site_appear')"
                      :data="item"
                    />
                    <!--PBL-->
                    <td-outreach-manager-approve
                      v-if="
                        headerExistIn(getHeaders, 'outreach_manager_approve')
                      "
                      :data="item"
                    />
                    <!--MNG-->
                    <td-last-activity-at
                      v-if="headerExistIn(getHeaders, 'last_activity_at')"
                      :data="item"
                    />
                    <!--LST-->
                    <td-first-msh-at
                      v-if="headerExistIn(getHeaders, 'first_msg_at')"
                      :data="item"
                    />
                    <!--1st-->
                    <td-ah-traffic :data="item" />
                    <!--AHTraffic-->
                    <td-ah-donor :data="item" />
                    <!--AHDR-->
                    <td v-if="headerExistIn(getHeaders, 'step_name')">
                      {{ item.step_name }}
                    </td>
                    <!--Current Step-->
                    <td v-if="headerExistIn(getHeaders, 'task_closed')">
                      {{ item.task_closed ? "Yes" : "No" }}
                    </td>
                    <!--TC (task_closed)-->
                    <td-created-at
                      v-if="headerExistIn(getHeaders, 'created_at')"
                      :data="item"
                    />
                    <!--CD-->
                    <td v-if="headerExistIn(getHeaders, 'month')">
                      {{ item.month }}
                    </td>
                    <!--M-->
                    <td-need-top :data="item" />
                    <!--NT-->
                    <td>{{ item.priority_url }}</td>
                    <!--UP-->
                    <td>{{ item.keitaro_click }}</td>
                    <!--K-->
                    <td>{{ item.keitaro_lead }}</td>
                    <!--L-->
                    <td>{{ item.priority_domain }}</td>
                    <!--DP-->
                    <td>{{ item.responsible }}</td>
                    <!--Responsible-->
                    <td>{{ item.performer }}</td>
                    <!--Performer-->
                    <td-task-id :is-gp="filters.is_gp" :data="item" />
                    <!--Task Id-->
                    <td>{{ item.current_status }}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  DEFAULT_MENU_PROPS,
  MANAGERS_GPM_FLOW_ITEMS,
  MANAGERS_GPM_TABLE_HEADERS,
} from "../../../utils/defaultData";
import TdAnchor from "../../../components/Gpm/Managers/td/TdAnchor";
import TdPosByAnchor from "../../../components/Gpm/Managers/td/TdPosByAnchor";
import TdDonor from "../../../components/Gpm/Managers/td/TdDonor";
import TdPrice from "../../../components/Gpm/Managers/td/TdPrice";
import TdAhTraffic from "../../../components/Gpm/Managers/td/TdAhTraffic";
import TdAhDonor from "../../../components/Gpm/Managers/td/TdAhDonor";
import TdNeedTop from "../../../components/Gpm/Managers/td/TdNeedTop";
import TdThemeApproveAt from "../../../components/Gpm/Managers/td/TdThemeApproveAt";
import TdTechTaskAppear from "../../../components/Gpm/Managers/td/TdTechTaskAppear";
import TdMainKeywords from "../../../components/Gpm/Managers/td/TdMainKeywords";
import TdContentAppearAt from "../../../components/Gpm/Managers/td/TdContentAppearAt";
import TdOnSiteAppearAt from "../../../components/Gpm/Managers/td/TdOnSiteAppearAt";
import TdOutreachManagerApprove from "../../../components/Gpm/Managers/td/TdOutreachManagerApprove";
import TdLastActivityAt from "../../../components/Gpm/Managers/td/TdLastActivityAt";
import TdFirstMshAt from "../../../components/Gpm/Managers/td/TdFirstMshAt";
import TdCreatedAt from "../../../components/Gpm/Managers/td/TdCreatedAt";
import TdWmResponseAt from "../../../components/Gpm/Managers/td/TdWmResponseAt";
import TdUrl from "../../../components/Gpm/Managers/td/TdUrl";
import TdTaskId from "../../../components/Gpm/Managers/td/TdTaskId";
import Project from "@/mixins/Project";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import SmartDateFilter from "../../UI/SmartDateFilter";
import TdPosByKey from "./td/TdPosByKey";
import EasyRangeInput from "../../UI/EasyRangeInput";
import Location from "../../../mixins/Location";
import TdContentReady from "@/components/Gpm/Managers/td/TdContentReady.vue";
import UploadFiltersDataMixin from "@/mixins/UploadFiltersDataMixin";

export default {
  components: {
    TdContentReady,
    EasyRangeInput,
    TdPosByKey,
    SmartDateFilter,
    TdTaskId,
    TdUrl,
    TdWmResponseAt,
    TdCreatedAt,
    TdFirstMshAt,
    TdLastActivityAt,
    TdOutreachManagerApprove,
    TdOnSiteAppearAt,
    TdContentAppearAt,
    TdMainKeywords,
    TdTechTaskAppear,
    TdThemeApproveAt,
    TdNeedTop,
    TdAhDonor,
    TdAhTraffic,
    TdPrice,
    TdDonor,
    TdPosByAnchor,
    TdAnchor,
  },
  mixins: [Project, FiltersHandling, Location, UploadFiltersDataMixin],
  data: () => ({
    sortBy: [
      "task_closed",
      "priority_domain",
      "need_top",
      "priority_url",
      "created_at",
    ],
    searchModel: null,
    loadings: {
      table: false,
      filters: false,
      seo: false,
    },
    filtersData: {
      subprojects: [],
      projects: [],
      niche: [],
      executive: [],
      current_step: [],
      current_status: [],
      task: [],
    },
    seoItems: [],
    costPresets: [
      ["200-400", [200, 400]],
      ["400-1000", [400, 1000]],
    ],
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "GPM",
        disabled: true,
      },
      {
        text: "Managers dashboard",
        disabled: true,
      },
    ],
    filters: {
      donor_assigned: false,
      donor_not_assigned: false,
      is_gp: 1,
      team: [],
      date: [], // filter
      need_top: [0, 0], // filter
      pos_by_key: [0, 0], // filter
      current_step: null, // filter
      current_status: null, // filter
      cost: [0, 0], // filter
      niche: [], // filter
      last_activity_days: null, // filter
      project: null, // filter
      subproject: null, // filter
      executive: [], // filter
      task: [], // filter
      overdue: false, // filter
      hide_closed: 1, // filter
      executive_force: false, // filter
      mainSeo: null, // filter
    },
    approvingPlacementBySeoDonors: 0,
    guestPostHeaders: [
      "price",
      "price_link",
      "wm_response_at",
      "tech_task_appear",
      "theme_approve_at",
      "main_keywords",
      "content_ready_at",
      "content_appear_at",
      "on_site_appear",
      "outreach_manager_approve",
      "last_activity_at",
      "first_msg_at",
      "step_name",
      "task_closed",
      "created_at",
      "month",
    ],
    tableData: {
      headers: MANAGERS_GPM_TABLE_HEADERS,
      items: [],
    },
    menuProps: DEFAULT_MENU_PROPS,
    tooltips: {
      firstEnter: "Select filters and click 'Get Data' 🏌️‍♂",
      itemsPlaceholder:
        "Can't find anything. <br> Change filters and try again. 🤷‍♂",
    },
    firstEnter: false,
  }),
  async mounted() {
    await this.parseQuery("filters");

    console.log(this.filters);

    this.fetchUsers();

    this.uploadFiltersData(["projects", "subprojects"], {
      project: this.filters.project,
    });

    await this.fetchFilters();

    if (Object.keys(this.$route.query).length > 0) this.fetchTableData();

    this.$watch("filters.project", () => {
      this.filters.subproject = null;

      this.uploadFiltersData(["subprojects"], {
        project: this.filters.project,
      });
    });
  },
  computed: {
    getHeaders() {
      if (this.filters.is_gp === 0) {
        const bannedHeaders = this.guestPostHeaders;
        return this.tableData.headers.filter(
          (header) => !bannedHeaders.includes(header.value)
        );
      }

      return this.tableData.headers;
    },
    niches() {
      return this.$store.state.reuse.niches;
    },
    projects() {
      return this.$store.state.project.allowed_projects;
    },
  },
  methods: {
    headerExistIn(headers, headerValue) {
      return headers.find((v) => v.value === headerValue) !== undefined;
    },
    async fetchUsers() {
      this.loadings.seo = true;
      const projectId = this.$store.state.project.active_project;
      const resp = await this.$store.dispatch("global/getUsers", { projectId });

      if (resp) {
        this.seoItems = resp;
      }

      this.loadings.seo = false;
    },
    async fetchFilters() {
      this.loadings.filters = true;
      const filters = [
        "executive",
        "task",
        "team",
        "current_step",
        "current_status",
      ];
      const payload = {
        type: "/dashboards/gpm/manager-outreach",
        take: filters,
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);
      if (resp) {
        this.filtersData = { ...this.filtersData, ...resp };
      }
      this.loadings.filters = false;
    },
    customSort(items, sortBy, sortDesc) {
      const sortedBy = [
        "need_top",
        "priority_domain",
        "priority_url",
        "created_at",
      ];
      const text = ["donor", "content_ready_at"];
      const numbers = ["price"];
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (text.includes(sortBy[i])) {
            let aVal, bVal;
            if (a[sortBy[i]].text === null) aVal = 0;
            if (b[sortBy[i]].text === null) bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;

            const val1 = a[sortBy[i]].text;
            const val2 = b[sortBy[i]].text;
            if (val1 > val2) return sortDesc[i] ? -1 : 1;
            if (val1 < val2) return sortDesc[i] ? 1 : -1;
            return 0;
          }

          if (sortedBy.includes(sortBy[i])) {
            let aVal, bVal;
            if (isNaN(parseInt(a[sortBy[i]]))) aVal = 0;
            if (isNaN(parseInt(b[sortBy[i]]))) bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;
          }

          if (numbers.includes(sortBy[i])) {
            const num1 = isNaN(parseInt(a[sortBy[i]].text))
              ? 0
              : parseInt(a[sortBy[i]].text);
            const num2 = isNaN(parseInt(b[sortBy[i]].text))
              ? 0
              : parseInt(b[sortBy[i]].text);
            if (num1 === 0 && num2 !== 0) return 1;
            if (num2 === 0 && num1 !== 0) return -1;
            if (num1 > num2) return sortDesc[i] ? -1 : 1;
            if (num1 < num2) return sortDesc[i] ? 1 : -1;
            return 0;
          }

          if (a[sortBy[i]] > b[sortBy[i]]) return sortDesc[i] ? -1 : 1;
          if (a[sortBy[i]] < b[sortBy[i]]) return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
    handleRangeChange(event, query) {
      this[query] = event;
      if (this.isDefaultRange(event)) {
        event = null;
      }
      this._$setQueryParams(query, event);
    },
    isDefaultRange(data) {
      const defaultArr = JSON.stringify([0, 0]);
      return JSON.stringify(data) === defaultArr;
    },
    handleGetData() {
      this.fetchTableData();
    },
    async fetchTableData() {
      try {
        const payload = {
          body: {
            filter: this.filters,
          },
          isMock: localStorage.getItem("is_mock") === "1" ? 1 : 0,
        };

        this.tableData.items = [];

        this.firstEnter = true;
        this.loadings.table = true;

        const resp = await this.$store.dispatch(
          "managersGpm/fetchTableData",
          payload
        );

        if (resp) {
          this.tableData.items = this.transformTableItems(resp.items);
          this.approvingPlacementBySeoDonors = resp.donors_amount;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.table = false;
      }
    },
    transformTableItems(items = []) {
      /*eslint-disable*/
      const usedUrls = {};
      const urlFlowData = MANAGERS_GPM_FLOW_ITEMS;

      // collect duplicated urls
      items.forEach((item) => {
        const existingItem = usedUrls[item.url.id];
        if (existingItem && existingItem.used !== undefined) {
          return (existingItem.used += 1);
        }

        usedUrls[item.url.id] = { used: 1 };
      });

      // init "used" value
      items.map((item) => {
        const used =
          usedUrls[item.url.id].used !== undefined
            ? usedUrls[item.url.id].used
            : 1;

        return (item.url.used = used);
      });

      // find "end" in managers gpm flow
      items.map((item) => {
        urlFlowData.forEach(({ value }, i) => {
          // collect data START
          let currentValue = null;

          try {
            currentValue = item[value].hasOwnProperty("text")
              ? item[value].text
              : item[value];
          } catch {}

          let nextStepItemValue = null;
          let nextStepValue = null;

          try {
            nextStepItemValue = urlFlowData[i + 1].value;
            nextStepValue = item[nextStepItemValue].hasOwnProperty("text")
              ? item[nextStepItemValue].text
              : item[nextStepItemValue];
          } catch {}
          // collect data END

          // init expired date
          if (currentValue && !nextStepValue) {
            const currentDate = new Date();
            const daysDiff = this.$moment(currentDate).diff(
              this.$moment(currentValue),
              "days"
            );

            if (typeof item[value] !== "object") {
              item[value] = { text: item[value], expired: null };
            }

            if (daysDiff > 10) item[value].expired = true;
          }
        });

        return item;
      });

      return items;
    },
  },
};
</script>

<style lang="scss">
.managers-gpm-table {
  overflow: hidden;
  .v-data-table {
    &__wrapper {
      table {
        overflow: hidden !important;
        thead,
        tbody {
          tr {
            td:nth-child(2),
            th:nth-child(2),
            td:nth-child(4),
            th:nth-child(4),
            td:nth-child(6),
            th:nth-child(6),
            td:nth-child(7),
            th:nth-child(7),
            td:nth-child(14),
            th:nth-child(14),
            td:nth-child(16),
            th:nth-child(16),
            td:nth-child(18),
            th:nth-child(18),
            td:nth-child(19),
            th:nth-child(19),
            td:nth-child(22),
            td:nth-child(25),
            th:nth-child(22),
            th:nth-child(25),
            td:nth-child(27),
            th:nth-child(27) {
              border-right: 1px solid rgba(155, 155, 155, 0.3) !important;
            }

            position: relative;
            td,
            th {
              &:first-child,
              &:nth-child(2) {
                position: sticky;
                top: 0;
                left: 0;
                z-index: 2;
                background-color: rgba(255, 255, 255);
              }
              &:nth-child(2) {
                left: 220px;
              }
            }
          }
        }
      }
    }
  }
}

.v-application.theme--dark {
  .managers-gpm-table {
    .v-data-table {
      &__wrapper {
        table {
          thead,
          tbody {
            overflow: hidden;
            tr {
              td,
              th {
                &:first-child,
                &:nth-child(2) {
                  background-color: #1e1e1e;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
