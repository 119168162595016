<template>
  <div id="app">
    <v-app v-if="!isAuthenticated">
      <router-view></router-view>
    </v-app>
    <v-app v-if="isAuthenticated">
      <div
        class="navigation__wrapper my-navigation"
        @mouseenter="isMouseOverNav = true"
        @mouseleave="isMouseOverNav = false"
      >
        <v-navigation-drawer
          v-model="drawer"
          :clipped="$vuetify.breakpoint.lgAndUp"
          app
          mini-variant
          expand-on-hover
          class="app__navigation"
        >
          <v-list v-if="!loadings.permissions" nav class="relative">
            <template v-for="(item, idx) in menu_items">
              <div
                v-if="!item.hidden"
                @click="updateMenu({ base: item.base })"
                :key="item.name + idx"
                class="my-navigation__item"
              >
                <v-expansion-panels
                  v-if="item.children !== undefined"
                  class="my-expansion-panels"
                  tile
                  flat
                >
                  <my-expansion-navigation-panel
                    :item="item"
                    :key="item.name + idx"
                    :mouse-over="isMouseOverNav"
                  />
                </v-expansion-panels>
                <my-expansion-navigation-router-link
                  v-else
                  :child="item"
                  :class="`${idx === 0 && 'disabled'}`"
                  @updateMenu="updateMenu"
                />
              </div>
            </template>
          </v-list>
          <v-list v-else dense nav>
            <div
              v-for="skeleton in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]"
              :key="skeleton"
              class="my-2"
            >
              <div class="skeleton" style="height: 36px; width: 100%" />
            </div>
          </v-list>
          <v-list v-if="false" dense nav>
            <v-list-item
              dense
              v-if="menu_levels.length !== 0"
              @click="menu_back"
            >
              <v-list-item-action>
                <v-icon>mdi-chevron-left</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Back</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <div v-for="(item, idx) in menu_items" :key="item.link + idx">
              <v-list-item
                dense
                @click="updateMenu(item, $event)"
                @click.middle="updateMenu(item, $event, true)"
              >
                <v-list-item-action>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-html="item.title"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="item.child !== undefined">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-list>
        </v-navigation-drawer>
      </div>

      <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        height="52"
        class="app__header"
        :color="getAppBarColor"
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <project-edit :new-design="interface_settings?.new_project_design" />

        <v-progress-linear
          :active="$store.state.loading"
          :indeterminate="$store.state.loading_indeterminate"
          absolute
          bottom
          color="primary"
        ></v-progress-linear>

        <v-spacer />

        <app-page-navigate />

        <v-spacer />

        <v-tooltip v-if="false" bottom content-class="pa-0 shadow-e1-bordered">
          <template #activator="{ on }">
            <div v-on="on" class="mr-4" @click="handleOpenNavigationMenu">
              <span class="keycup">ALT</span> /
              <span class="keycup">CMD </span>
              +
              <span class="keycup">G</span>
            </div>
          </template>
          <v-card class="pa-4">Open navigation menu</v-card>
        </v-tooltip>

        <tools-menu :loading="loadings.permissions" />

        <v-menu
          bottom
          offset-y
          nudge-left="65px"
          rounded="lg"
          content-class="shadow-e2-bordered"
          transition="slide-y-transition"
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-briefcase</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item class="justify-center">
              <b>Add task into</b>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon small class="ml-2" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <div>
                  1. Single click - view details. <br />
                  2. Double click - create new one.
                </div>
              </v-tooltip>
            </v-list-item>
            <go-to-ws-widget />
            <add-task-by-module />
          </v-list>
        </v-menu>

        <Maintainer />

        <Notifications />

        <template>
          <v-menu
            bottom
            rounded="lg"
            transition="slide-y-transition"
            offset-y
            content-class="shadow-e2-bordered"
            :close-on-content-click="false"
            width="320px"
            max-width="320px"
          >
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-avatar size="30">
                  <v-img
                    :src="$store.state.auth.user.photo"
                    title="Rate"
                  ></v-img>
                </v-avatar>
              </v-btn>
            </template>
            <v-card elevation="0" width="340px">
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-row dense>
                      <v-col cols="auto">
                        <v-avatar size="52">
                          <v-img
                            :src="$store.state.auth.user.photo"
                            title="Rate"
                          ></v-img>
                        </v-avatar>
                      </v-col>
                      <v-col
                        cols="fill"
                        class="d-flex justify-center text-body-2"
                        style="flex-direction: column"
                      >
                        <b>{{ $store.state.auth.user.name }}</b>
                        <div>
                          {{ $store.state.auth.user.email }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="d-flex">
                    <v-spacer />
                    <v-btn @click="logout" class="text-normal">
                      <v-icon small left> mdi-logout-variant </v-icon>
                      Logout
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <div v-if="false" class="mx-auto text-center">
                <v-avatar size="40">
                  <v-img
                    :src="$store.state.auth.user.photo"
                    title="Rate"
                  ></v-img>
                </v-avatar>
                <h3>{{ $store.state.auth.user.name }}</h3>
                <p class="text-caption mt-1">
                  {{ $store.state.auth.user.email }}
                </p>

                <v-divider class="my-3"></v-divider>

                <h3>Ratings</h3>
                <div style="font-size: 11px">Adherence to the deadline</div>
                <v-rating
                  small
                  v-model="get_rate().deadline"
                  background-color="orange lighten-3"
                  color="orange"
                  large
                ></v-rating>
                <div style="font-size: 11px">Observance of dynamics</div>
                <v-rating
                  small
                  v-model="get_rate().dynamics"
                  background-color="orange lighten-3"
                  color="orange"
                  large
                ></v-rating>
                <div style="font-size: 11px">
                  Emotional attitude to this task
                </div>
                <v-rating
                  small
                  v-model="get_rate().emotional"
                  background-color="orange lighten-3"
                  color="orange"
                  large
                ></v-rating>
                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text> Edit Account </v-btn>
              </div>
            </v-card>
          </v-menu>
        </template>

        <v-btn
          v-if="[971, 24].includes($store.getters['auth/user'].id)"
          icon
          @click="routeInfoDialog = true"
        >
          <v-icon>mdi-routes</v-icon>
        </v-btn>

        <v-menu
          transition="slide-x-reverse-transition"
          left
          offset-y
          bottom
          :close-on-content-click="false"
          rounded="lg"
          content-class="shadow-e2-bordered"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense nav>
            <v-list-item @click="toggle_dark_mode">
              <v-list-item-icon>
                <v-icon v-if="$vuetify.theme.dark">mdi-weather-sunny</v-icon>
                <v-icon v-else>mdi-weather-night</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $vuetify.theme.dark ? "Light mode" : "Dark mode" }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="dialog_settings = true">
              <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Interface Settings</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-main>
        <v-container
          fluid
          :class="`custom-app-container${
            $vuetify.theme.dark ? ' dark-mode' : ''
          }`"
        >
          <v-dialog
            v-model="dialog_settings"
            max-width="420px"
            scrollable
            content-class="my-shadow--e4"
          >
            <v-card outlined rounded="lg">
              <v-card-title class="text-body-2">
                Interface Settings
              </v-card-title>
              <v-divider />
              <v-card-text class="pt-5">
                <v-row>
                  <v-col cols="9"> Dark mode </v-col>
                  <v-col cols="3" class="d-flex justify-end">
                    <v-switch
                      v-model="interface_settings.dark_mode"
                      hide-details
                      dense
                      class="ma-0"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="9"> Mock data <mock-data-guide /> </v-col>
                  <v-col cols="3" class="d-flex justify-end">
                    <v-switch
                      v-model="mock_data"
                      hide-details
                      dense
                      class="ma-0"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="9">
                    New project select widget design
                    <new-project-select-guide />
                  </v-col>
                  <v-col cols="3" class="d-flex justify-end">
                    <v-switch
                      v-model="interface_settings.new_project_design"
                      hide-details
                      class="ma-0"
                      dense
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4"> App font </v-col>
                  <v-col cols="8" class="d-flex justify-end">
                    <font-picker />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="text-normal px-6"
                  text
                  large
                  @click="dialog_settings = false"
                >
                  Close
                </v-btn>
                <v-btn
                  color="primary"
                  class="text-normal px-6"
                  large
                  @click="save_interface_settings"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <router-view></router-view>
        </v-container>
      </v-main>
    </v-app>

    <project-select-modal />

    <page-search-dialog
      :model-value="searchDialog"
      @update:modelValue="searchDialog = $event"
    />

    <v-dialog
      v-model="routeInfoDialog"
      content-class="my-shadow--e4"
      scrollable
      width="600"
    >
      <v-card flat outlined rounded="lg">
        <v-card-title class="text-body-2">
          Route info
          <v-spacer />
          <v-icon @click="routeInfoDialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-0">
          <v-simple-table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>fullPath</td>
                <td>
                  {{ $route.fullPath }}
                </td>
              </tr>
              <tr>
                <td>path</td>
                <td>
                  {{ $route.path }}
                </td>
              </tr>
              <tr>
                <td>name</td>
                <td>
                  {{ $route.name }}
                </td>
              </tr>
              <tr>
                <td>meta</td>
                <td>
                  <pre>
                    {{ $route.meta }}
                  </pre>
                </td>
              </tr>
              <tr>
                <td>maintainer</td>
                <td>
                  {{ $route.maintainer }}
                </td>
              </tr>
              <tr>
                <td>title</td>
                <td>
                  {{ $route.title }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/*eslint-disable*/
import Maintainer from "@/components/Main/Dialogs/Maintainer";
import ProjectEdit from "./components/Main/Dialogs/ProjectEdit.vue";
import Notifications from "./components/Main/Notifications/Notifications";
import AddTaskByModule from "@/components/Main/Dialogs/AddTaskByModule";
import PageSearchDialog from "@/components/PageSearchDialog";
import hotkeys from "hotkeys-js";
import GoToWsWidget from "@/components/Main/Dialogs/GoToWsWidget";
import ToolsMenu from "@/components/Main/Dialogs/ToolsMenu";
import MyExpansionNavigationPanel from "@/components/UI/MyExpansionNavigationPanel.vue";
import MyExpansionNavigationRouterLink from "@/components/UI/MyExpansionNavigationRouterLink.vue";
import eventPipe from "./events/eventPipe";
import AppPageNavigate from "./components/UI/AppPageNavigate.vue";
import Loader from "@/components/Main/Loader.vue";
import service from "@/plugins/service";
import FontPicker from "./components/App/FontPicker/FontPicker.vue";
import FontMixin from "./mixins/FontMixin";
import MockDataGuide from "./components/App/MockDataGuide/MockDataGuide.vue";
import NewProjectSelectGuide from "./components/App/NewProjectSelectGuide/NewProjectSelectGuide.vue";
import ProjectSelectModal from "./components/UI/ProjectSelectModal/index.vue";

export default {
  name: "App",
  mixins: [FontMixin],
  components: {
    NewProjectSelectGuide,
    MockDataGuide,
    FontPicker,
    Loader,
    ProjectSelectModal,
    AppPageNavigate,
    MyExpansionNavigationRouterLink,
    MyExpansionNavigationPanel,
    ToolsMenu,
    GoToWsWidget,
    PageSearchDialog,
    AddTaskByModule,
    Notifications,
    Maintainer,
    ProjectEdit,
  },
  props: {
    source: String,
  },
  data: () => ({
    loadings: {
      permissions: false,
    },
    routeInfoDialog: false,
    miniVariant: false,
    mock_data: false,
    title: "as",
    menu: false,
    menu_levels: [],
    menu_items: [],
    interface_settings_d: false,
    interface_settings: {
      new_project_design: false,
    },
    dialog_settings: false,
    drawer: true,
    isMouseOverNav: false,
    mini: true,
    searchDialog: false,
  }),
  provide() {
    return {
      message: this.$message.notification,
    };
  },
  async created() {
    this.$store.commit("setStartLocation", this.$route.path);
    this.processRoutesWithPermissions();

    this.$gtag.config({
      user_id: this.$store.state.auth.user.id,
    });
  },
  mounted() {
    this.initializeDefaultFontFromLocalStorage();
    // if (this.isDevApplication) {
    //   this.$message.notification({
    //     title: "Attention",
    //     text: "You are using a dev version of the application. All changes made do not affect the current data in the main application.",
    //     type: "warning",
    //     position: "lt",
    //     duration: 0,
    //   });
    // }

    eventPipe.$on("error", (options) => {
      this.$message.notification(options);
    });

    eventPipe.$on("redirect", (payload) => {
      if (payload.name !== this.$route.name) this.$router.push(payload);
    });

    eventPipe.$on("user-is-authorized", () => {
      this.processRoutesWithPermissions();
    });

    if (
      !this.isAuthenticated &&
      this.$route.path !== "/login" &&
      this.$route.path !== "/oauth/login"
    ) {
      console.error("User are not authorized. Redirect to login page.");
      this.$router.push("/login");
    }

    this.clarity(window, document, "clarity", "script", "cmo3nh5ny0");

    hotkeys("alt+g,cmd+g", () => {
      this.searchDialog = !this.searchDialog;
    });

    let self = this;

    if (localStorage.getItem("is_mock")) {
      this.mock_data = localStorage.getItem("is_mock") === "1";
    }

    if (localStorage.getItem("interface_settings")) {
      try {
        this.interface_settings = JSON.parse(
          localStorage.getItem("interface_settings")
        );
        this.$vuetify.theme.dark = this.interface_settings.dark_mode;
      } catch (e) {
        localStorage.removeItem("interface_settings");
      }
    }

    eventPipe.$on("trackEvent", function (payload) {
      self.$gtag.event("route_change", {
        ...payload,
      });
    });

    eventPipe.$on("updateMenu", (value) => {
      this.updateMenu(value);
    });
  },
  computed: {
    getAppBarColor() {
      if (this.isDevApplication) return "";

      return this.$vuetify.theme.dark ? "#121212" : "#fff";
    },
    isDevApplication() {
      return Number(process.env.VUE_APP_IS_DEV);
    },
    error() {
      return this.$store.state.error;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    menu_select_projects: {
      get: function () {
        return this.$store.state.project.menu_select_projects;
      },
      set: function (value) {
        this.$store.state.project.menu_select_projects = value;
      },
    },
  },
  watch: {
    mock_data(value) {
      localStorage.setItem("is_mock", value ? 1 : 0);
    },
    error(error) {
      const error_notification_options = {
        title: "Something wrong...",
        text: error.message,
        type: "error",
        duration: 3000,
      };
      this.$message.notification(error_notification_options);
      this.$store.commit("set_error", null);
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Default Title";
      },
    },
  },
  methods: {
    async processRoutesWithPermissions() {
      const permissions = await this.fetchUserPermissions();
      this.mutateRoutesWithPermissionsAndInitMenuItems(permissions);
    },
    async mutateRoutesWithPermissionsAndInitMenuItems(permissions) {
      const processedRoutes = await this.$store.dispatch(
        "base/compareRoutesAndPermissions",
        {
          permissions,
        }
      );

      this.menu_items = processedRoutes;
    },
    async fetchUserPermissions() {
      try {
        const url = "/user/get-all-permissions";

        this.loadings.permissions = true;

        const resp = await service.get(url);

        if (resp) {
          const permissions = resp.data.items;

          this.$store.commit("updatePermissions", permissions);
          this.$store.commit(
            "updateFlatPermissions",
            permissions.map((v) => v.route)
          );
          return permissions;
        }
        return [];
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.permissions = false;
      }
    },
    handleOpenNavigationMenu() {
      this.searchDialog = true;
    },
    clarity(c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    },
    get_rate() {
      if (this.$store.state.auth.user.rate === undefined) {
        return {};
      }
      return JSON.parse(this.$store.state.auth.user.rate);
    },
    logout() {
      let self = this;
      self.$store.dispatch("logout", function () {
        self.$router.push("/login");
      });
    },
    toggle_dark_mode() {
      this.interface_settings.dark_mode = !this.interface_settings.dark_mode;
      this.save_interface_settings();
    },
    save_interface_settings() {
      this.dialog_settings = false;
      localStorage.setItem(
        "interface_settings",
        JSON.stringify(this.interface_settings)
      );

      this.$vuetify.theme.dark = this.interface_settings.dark_mode;
    },
    goTo(item, blank) {
      if (blank) {
        const routeData = this.$router.resolve({ name: item.link });
        window.open(routeData.href, "_blank");
        return;
      }
      this.$router.push({ name: item.link });
    },
    updateMenu(item) {
      if (item.child_hidden !== undefined) {
        this.menu_levels = [];
        this.menu_items = item.child_hidden;
      } else if (item.base === true) {
        this.menu_levels = [];
        this.menu_items = this.$store.state.base.menu_items_base;
      }
    },
    menu_back() {
      this.menu_items = this.menu_levels.pop();
    },
  },
};
</script>

<style lang="scss">
.expand-button {
  position: absolute;
  top: 20px;
  right: -10px;
}
.my-expansion-panels {
  &__nested-list {
    .v-list-item {
      min-height: 36px !important;
    }
  }
}
.my-navigation {
  $class: &;

  &__item {
    .v-expansion-panel-header {
      min-height: 36px;
    }
    .v-list-item {
      min-height: 36px;
      &__action {
        margin-top: 6px;
        margin-bottom: 6px;
      }
      &__content {
        padding: 4px 0;
      }
    }
  }
  .v-expansion-panel-header__icon {
    opacity: 0;
  }
  .v-expansion-panel {
    background-color: transparent !important;
  }
  .v-navigation-drawer {
    &--is-mouseover {
      .v-expansion-panel-header__icon {
        opacity: 1 !important;
      }
    }
  }
  .v-list-item {
    cursor: pointer;
    transition: 250ms ease-in-out;
    position: relative;
    &:before {
      position: absolute;
      content: "";
      left: -3px;
      top: 50%;
      transform: translateY(-50%);
      height: calc(100% - 6px);
      width: 3px;
      opacity: 0;
      border-radius: 8px;
      background-color: #09a9ff;
      transition: 250ms ease-in-out;
      z-index: 5;
    }
    &.router-link-active {
      &:not(.disabled) {
        * {
          color: #09a9ff !important;
        }

        &:before {
          opacity: 1;
        }
      }
    }
    &:hover {
      background-color: rgba(152, 152, 152, 0.15);
      .v-icon {
        //font-size: 24px !important;
      }
    }
    &:active {
      background-color: rgba(152, 152, 152, 0.3);
    }
  }
  .v-expansion-panels {
    .v-expansion-panel {
      position: relative;
      &:before {
        position: absolute;
        content: "";
        left: -3px;
        top: 50%;
        transform: translateY(-50%);
        height: calc(100% - 6px);
        width: 3px;
        opacity: 0;
        border-radius: 8px;
        background-color: rgb(155, 155, 155, 0.5);
        transition: 250ms ease-in-out;
        z-index: 3;
      }
      &--active {
        &:before {
          opacity: 1;
        }
      }
    }
    .v-expansion-panel-header {
      background-color: transparent !important;
      border-radius: 6px;
      .v-list-item {
        &:hover {
          background-color: transparent !important;
        }
      }
      &--active {
        min-height: 36px !important;
      }
      &:hover {
        background-color: rgba(152, 152, 152, 0.15);
      }
    }
    .v-expansion-panel-content {
      background-color: transparent !important;
      &__wrap {
        background-color: transparent !important;
        padding: 0;
      }
    }
  }
}
.v-application.theme--dark {
  .my-navigation {
    height: 100%;
    $class: &;
    .v-list-item {
      &:before {
        background-color: #09a9ff;
      }
      &.router-link-active {
        &:not(.disabled) {
          * {
            color: #09a9ff !important;
          }
        }
      }
      &:hover {
        background-color: rgba(152, 152, 152, 0.15);
      }
      &:active {
        background-color: rgba(152, 152, 152, 0.3);
      }
    }
    .v-expansion-panels {
      .v-expansion-panel {
        &:before {
          background-color: rgba(255, 255, 255, 0.8);
        }
      }
      .v-expansion-panel-header {
        background-color: transparent !important;
        .v-list-item {
          &:hover {
            background-color: transparent !important;
          }
        }
        &:hover {
          background-color: rgba(152, 152, 152, 0.15);
        }
      }
      .v-expansion-panel-content {
        background-color: transparent !important;
        &__wrap {
          background-color: transparent !important;
          padding: 0;
        }
      }
    }
  }
}
</style>
