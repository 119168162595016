<template>
  <div>
    <v-dialog
      :retain-focus="false"
      scrollable
      v-model="inputVal"
      max-width="1200px"
      content-class="my-shadow--e2"
    >
      <v-card flat outlined rounded="lg">
        <template>
          <div v-if="upload.alert.shown">
            <v-alert
              v-for="message in upload.alert.messages"
              :key="message"
              outlined
              :type="upload.alert.type"
              prominent
              border="left"
              dismissible
            >
              {{ upload.alert.messages.name }}: {{ message }}
            </v-alert>
          </div>
        </template>

        <v-card-title class="text-body-2">
          <b>Loader Replacement Phrases</b>
          <v-btn class="ml-2" @click="export_data" icon>
            <v-icon> mdi-cloud-download-outline </v-icon>
          </v-btn>
          <v-spacer />
          <v-icon @click="inputVal = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="8">
              <v-card flat outlined rounded="lg">
                <v-card-title class="text-body-2">
                  Import from text
                </v-card-title>
                <v-divider />
                <v-card-text class="pt-4">
                  <v-row>
                    <v-col cols="12">
                      1. Put the columns to import in the order in which you
                      make the data</v-col
                    >
                    <v-col cols="12">
                      <v-text-field
                        v-model="upload.text.headers"
                        outlined
                        hide-details
                        :placeholder="'key;value'"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">2. Insert data (without headers)</v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="upload.text.rows"
                        outlined
                        hide-details
                        :placeholder="'key <tab> value'"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    class="text-normal px-6"
                    color="success"
                    large
                    @click="upload_data(1)"
                    >Upload
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-row>
                <v-col cols="12">
                  <v-card flat outlined rounded="lg">
                    <v-card-title class="text-body-2">
                      <span>Import from file</span>
                      <v-spacer />
                      <a
                        href="/file/AnchorsPlanExample.csv"
                        target="_blank"
                        style="font-size: 15px"
                        class="primary--text"
                      >
                        Example
                        <v-icon small right color="primary"
                          >mdi-download</v-icon
                        ></a
                      >
                    </v-card-title>
                    <v-divider />
                    <v-card-text class="pt-4">
                      <v-row>
                        <v-col cols="12">
                          <v-file-input
                            v-model="upload.file.names"
                            counter
                            dense
                            label="File input"
                            multiple
                            placeholder="Select your files"
                            prepend-icon="mdi-paperclip"
                            accept=".csv"
                            outlined
                            :show-size="1000"
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip v-if="index < 2" dark label small>
                                {{ text }}
                              </v-chip>

                              <span
                                v-else-if="index === 2"
                                class="overline grey--text text--darken-3 mx-2"
                              >
                                +{{ upload.file.names.length - 2 }} File(s)
                              </span>
                            </template>
                          </v-file-input>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        large
                        class="text-normal px-6"
                        color="success"
                        dark
                        @click="upload_data(2)"
                        >Upload
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card flat outlined rounded="lg">
                    <v-card-title class="text-body-2">
                      Allowed columns
                    </v-card-title>
                    <v-divider />
                    <v-card-text class="pt-4">
                      <p><b>R</b> - required</p>
                      <p><b>key [R]</b> - 2001</p>
                      <p><b>value [R]</b> - 2021</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UploadReplacementPhrases",
  metaInfo: {
    title: "UploadReplacementPhrases - SI",
  },
  props: ["value"],
  data() {
    return {
      loadings: {},
      upload: {
        modal: false,
        text: {
          headers: "key;value",
          rows: "",
        },
        file: {
          names: [],
        },
        alert: {
          messages: {
            list: [],
            name: "",
            type: 0,
          },
          shown: false,
          type: "info",
        },
      },
    };
  },
  methods: {
    upload_data(type) {
      let self = this;
      self.update_loading("upload_data", true);

      self.upload.alert.messages = [];
      self.upload.alert.type = "";
      self.upload.alert.shown = false;

      let url = `${self.$store.state.server_url}/text-generate/one/upload/replace`;
      let data = {};
      if (type === 1) {
        data = {
          type: type,
          headers: this.upload.text.headers,
          data: this.upload.text.rows,
          project_id: self.id_project,
        };
      } else {
        data = new FormData();
        data.append("file", self.upload.file.names[0]);
        data.append("type", type);
        data.append("project_id", self.id_project);
      }

      let config = {
        headers: {
          "Content-Type":
            type === 1 ? "application/json" : "multipart/form-data",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .post(url, data, config)
        .then(function (response) {
          self.upload.alert.messages = response.data.messages;
          self.upload.alert.type = response.data.type;
          self.upload.alert.shown = true;
          self.update_loading("upload_data", false);
          self.upload.modal = false;
          self.upload.file.names = [];
        })
        .catch((error) => {
          self.upload.alert.messages = {
            list: [error],
            name: "Axios",
            type: 0,
          };
          self.upload.alert.type = "error";
          self.upload.alert.shown = true;
          self.upload.modal = false;
          self.upload.file.names = [];

          self.update_loading("upload_data", false);
        });
    },
    export_data() {
      let self = this;

      let url = `${self.$store.state.server_url}/export/base/get-hash`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios.post(url, self.filter, config).then((response) => {
        if (response.data.success) {
          self.dialog_export = false;
          location.href =
            `${self.$store.state.server_url}/export/text-generate/replace/${self.id_project}?hash=` +
            response.data.hash;
        }
      });
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
  created() {},
};
</script>

<style scoped></style>
