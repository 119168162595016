import { render, staticRenderFns } from "./StatusManger.vue?vue&type=template&id=4cc2c9d5&scoped=true&"
import script from "./StatusManger.vue?vue&type=script&lang=js&"
export * from "./StatusManger.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cc2c9d5",
  null
  
)

export default component.exports