export const MOCK = {
  TABLE_ITEMS: [
    {
      title: "Waiting for placement details",
      type: "guest post",
    },
  ],
  TABLE_HEADERS: [
    {
      text: "Title",
      value: "title",
    },
    {
      text: "Type",
      value: "type",
    },
  ],
};
