<script>
import {
  BriefLowImpressionsPagesAnalyzeBreadcrumbs,
  Endpoints,
} from "./defaults";
import service from "../../../plugins/service";
import TableSorting from "../../../mixins/TableSorting";
import FiltersHandling from "../../../mixins/components/FiltersHandling";

export default {
  mixins: [TableSorting, FiltersHandling],

  data: () => ({
    breadcrumbs: BriefLowImpressionsPagesAnalyzeBreadcrumbs,
    search: null,
    filters: {},
    datatable: {
      headers: [],
      items: [],
    },
    loadings: {
      datatable: false,
      filters: false,
    },
  }),

  async mounted() {
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      this.loadings.datatable = true;

      try {
        const { data } = await service.post(Endpoints.Datatable, {
          filter: this.filters,
        });

        this.datatable = data;
      } catch (e) {
        console.error("Something went wrong while initializing dashboard.", e);
      } finally {
        this.loadings.datatable = false;
      }
    },
  },
};
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h2>Brief Low Impressions Pages Analyze</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="pa-4">
            <v-text-field
              v-model="search"
              hide-details
              dense
              outlined
              placeholder="Type to search"
              append-icon="mdi-magnify"
              clearable
            />
          </v-card-title>
          <v-card-text class="px-0 pt-0 pb-2">
            <v-data-table
              :search="search"
              ref="dataTables"
              item-key="id"
              :items="datatable.items"
              :headers="datatable.headers"
              :loading="loadings.datatable"
              multi-sort
              :custom-sort="_$smartCustomTableSort"
              :footer-props="{ itemsPerPageOptions: [25, 50, 100, -1] }"
              :items-per-page="25"
            ></v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
