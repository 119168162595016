import moment from "moment/moment";

export const TASK_PRIORITY_TILES = [
  {
    title: "Time capacity",
    icon: "mdi-account-clock-outline",
    color: "inherit",
    isPendingDependToData: true,
    getValue: function (_, filters) {
      const CAPACITY_ON_SINGLE_USER = 40;

      return `${(filters.executive?.length || 1) * CAPACITY_ON_SINGLE_USER} h.`;
    },
  },
  {
    title: "Remaining time",
    icon: "mdi-account-clock-outline",
    color: "inherit",
    totalWorkHoursPerDay: 8, // 8 working hours per day
    daysPerSprint: 14, // 2-week sprint
    workDaysPerSprint: 10, // 10 workdays per sprint (5 per week)
    totalWorkHoursPerSprint: 80, // 2 weeks * 5 working days * 8 hours per day
    sprintDay: 0,
    sprintNumber: 0,
    remainingWorkHours: 0,
    currentDate: null,
    $moment: moment,
    isPendingDependToData: true,
    tooltip: `The remaining available time for the user(s), taking into account the days that have passed since the start of the sprint and the estimates of the unresolved tasks.

40 hours are available for estimation, and another 40 hours are reserved for unforeseen situations, such as bug fixes.`,
    getUsedTime: function (items = []) {
      return items
        .filter((item) => !!item.week)
        .reduce((acc, val) => (acc += val?.estimate || 0), 0);
    },
    getValue: function (items, filters) {
      const usedTime = this.getUsedTime(items);
      const usersAmount = filters?.executive?.length || 1;

      // Start date of the first sprint (November 5th)
      const sprintStart = this.$moment("2024-11-05");
      const today = this.$moment(); // Current date
      this.currentDate = today.format("MMMM Do YYYY"); // Format current date for display

      // Calculate the number of days since the first sprint started
      const daysSinceStart = today.diff(sprintStart, "days");

      // Determine the current sprint number (total number of full 2-week sprints passed)
      this.sprintNumber = Math.floor(daysSinceStart / this.daysPerSprint) + 1;

      // Calculate the current day within the sprint
      let currentDay = daysSinceStart % this.daysPerSprint; // Remainder after dividing by 14
      let workDaysPassed = 0;

      // Loop through the days and count only the weekdays (Monday to Friday)
      for (let i = 0; i <= currentDay; i++) {
        const dayOfWeek = sprintStart.clone().add(i, "days").day();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
          // 0 is Sunday, 6 is Saturday
          workDaysPassed++;
        }
      }

      // Calculate the current sprint day as a number (1 to 10, because there are 10 workdays in the sprint)
      this.sprintDay = workDaysPassed;

      // Calculate the total number of worked hours so far
      // const workedHours = workDaysPassed * this.totalWorkHoursPerDay;

      // Remaining work hours
      this.remainingWorkHours = Math.floor(
        this.totalWorkHoursPerSprint / 2 - usedTime
      );

      const result = this.remainingWorkHours * usersAmount;

      return `${result < 0 ? 0 : result} h.`;
    },
  },
  {
    title: "Estimated time ( Planned )",
    dataKey: "estimate",
    icon: "mdi-clock-outline",
    color: "inherit",
    iconConfig: {
      color: "success",
    },
    tooltip:
      "The sum of the estimated time of tasks that do have a week label.",
    isPendingDependToData: true,
    getValue: function (items) {
      if (!items || !items.length) return "- h.";
      // Return item.estimate time, WHERE item has week value
      return (
        items
          .filter((item) => !!item.week)
          .reduce((acc, val) => (acc += val?.[this.dataKey] || 0), 0) + " h."
      );
    },
  },
  {
    title: "Estimated time ( Unplanned )",
    dataKey: "estimate",
    icon: "mdi-clock-outline",
    color: "inherit",
    iconConfig: {
      color: "warning",
    },
    tooltip:
      "The sum of the estimated time of tasks that do not have a week label.",
    isPendingDependToData: true,
    getValue: function (items) {
      if (!items || !items.length) return "- h.";
      // Return item.estimate time, WHERE item has week value
      return (
        items
          .filter((item) => !item.week)
          .reduce((acc, val) => (acc += val?.[this.dataKey] || 0), 0) + " h."
      );
    },
  },
  {
    title: "Done ( Planned )",
    icon: "mdi-check-circle",
    color: "inherit",
    iconConfig: {
      color: "success",
    },
    isPendingDependToData: true,
    getValue: function (items) {
      if (!items || !items.length) return "- h.";
      // Return item.estimate time, WHERE item has week value

      const total = items.filter((item) => !!item.week);

      const done = total.filter((item) => item.done);

      const result = (done?.length / total?.length) * 100;

      return `${Math.round(result)}%`;
    },
  },
  {
    title: "Done ( Unplanned )",
    icon: "mdi-check-circle",
    color: "inherit",
    iconConfig: {
      color: "warning",
    },
    isPendingDependToData: true,
    getValue: function (items) {
      if (!items || !items.length) return "- h.";
      // Return item.estimate time, WHERE item has week value

      const total = items.filter((item) => !item.week);

      const done = total.filter((item) => item.done);

      const result = (done?.length / total?.length) * 100;

      return `${Math.round(result)}%`;
    },
  },
  {
    title: "Tasks amount",
    icon: "mdi-file-document-multiple-outline",
    color: "inherit",
    isPendingDependToData: true,
    getValue: function (items) {
      return items?.length;
    },
  },
];
