<template>
  <div>
    <v-data-table
      hide-default-footer
      :items-per-page="-1"
      :items="getItems"
      :headers="getHeaders"
      class="relative-tr"
    >
      <template #top>
        <div class="px-4 py-2">
          <v-autocomplete
            v-model="rowsWhitelist"
            :items="getRowItems"
            item-value="id"
            item-text="url"
            hide-details
            dense
            multiple
            clearable
            deletable-chips
            small-chips
            outlined
            label="Rows Whitelist"
            placeholder="Select url"
          ></v-autocomplete>
        </div>
      </template>
      <template #[`item.vision`]="{ value }">
        <div v-if="String(value) === 'OUR'" class="mark centered mark--info" />
        <div
          v-if="String(value) === 'OUR NEW'"
          class="mark centered mark--success"
        />
        {{ value }}
      </template>
      <template #[`item.actions`]="{ item }">
        <v-menu
          bottom
          min-width="200"
          content-class="shadow-e2-bordered"
          rounded="lg"
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-btn :loading="loadings.ignore.includes(item.id)" icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="sendToIgnore(item, IGNORE_TYPES.NICHE)">
              <v-list-item-icon>
                <v-icon>mdi-hand-back-left-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content> Ignore by Niche </v-list-item-content>
            </v-list-item>
            <v-list-item @click="sendToIgnore(item, IGNORE_TYPES.PROJECT)">
              <v-list-item-icon>
                <v-icon>mdi-hand-back-left-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content> Ignore by Project </v-list-item-content>
            </v-list-item>
            <v-list-item @click="sendToIgnore(item, IGNORE_TYPES.URL)">
              <v-list-item-icon>
                <v-icon>mdi-hand-back-left-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content> Ignore by URL </v-list-item-content>
            </v-list-item>
            <v-list-item @click="handleUpdate(item)">
              <v-list-item-icon>
                <v-icon v-if="!loadings.onUpdate.includes(item.id)"
                  >mdi-sync</v-icon
                >
                <v-progress-circular v-else size="22" width="2" indeterminate />
              </v-list-item-icon>
              <v-list-item-content> Update </v-list-item-content>
            </v-list-item>
            <v-list-item @click="handleEdit(item)">
              <v-list-item-icon>
                <v-icon>mdi-pencil-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content> Edit</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-dialog v-model="editDialog" max-width="400">
      <v-card class="shadow-e1-bordered" rounded="lg">
        <v-card-title> Edit </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="edit.content"
            hide-details
            outlined
            label="URL"
            placeholder="Enter new URL"
            auto-grow
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            width="150"
            color="success"
            @click="saveEditedUrl"
            :loading="loadings.onEdit"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <agree-dialog
      :model-value="agreeDialogModel"
      @update:modelValue="agreeDialogModel = $event"
    >
      <template #title> Are you sure?</template>
      <template #default> Are you sure you want to ignore this url? </template>
      <template #actions>
        <v-row dense no-gutters>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn @click="disagree" text color="grey">Cancel</v-btn>
            <v-btn @click="agree" color="error" class="ml-4 b-rb-shaped-4">
              Ignore
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </agree-dialog>
  </div>
</template>

<script>
import service from "../../../plugins/service";
import AgreeDialogMixin from "../../../mixins/AgreeDialogMixin";
import AgreeDialog from "../../UI/AgreeDialog.vue";

export default {
  components: { AgreeDialog },
  props: {
    type: {
      type: String,
      default: null,
    },
    filters: Object,
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    parentUrl: {
      type: Number,
      default: null,
    },
  },
  mixins: [AgreeDialogMixin],
  data: () => ({
    headers: [
      {
        text: "Value",
        value: "value",
      },
      {
        text: "Url",
        value: "url",
      },
      {
        text: "Vision",
        value: "vision",
      },
      {
        text: "Date",
        value: "date",
      },
    ],
    rowsWhitelist: [],
    editDialog: false,
    edit: {
      content: null,
      itemId: null,
    },
    IGNORE_TYPES: {
      URL: "url",
      PROJECT: "project",
      NICHE: "niche",
    },
    loadings: {
      ignore: [],
      onUpdate: [],
      onEdit: false,
    },
  }),
  computed: {
    getRowItems() {
      return this.items;
    },
    getItems() {
      return this.items.filter((v) => this.rowsWhitelist.includes(v.id));
    },
    getHeaders() {
      return [...this.headers, { text: "", value: "actions" }];
    },
  },
  watch: {
    items: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!newValue) return [];
        this.rowsWhitelist = newValue.slice(0, 10).map((v) => v.id);
      },
    },
  },
  methods: {
    async saveEditedUrl() {
      try {
        const url = "/tdh1/action";

        const payload = {
          type: this.type,
          action: "edit",
          text: this.edit.content,
          url_id: this.edit.itemId,
          id_url: this.filters.url,
        };

        this.loadings.onEdit = true;

        const resp = await service.post(url, payload);

        if (resp && resp.data && resp.data.success) {
          this.edit = {
            content: null,
            itemId: null,
          };
          this.editDialog = false;
          this.$message.notification({
            title: "Success",
            text: "Successfully edited.",
            type: "success",
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.onEdit = false;
      }
    },
    handleEdit(tableRowItem) {
      this.editDialog = true;
      this.edit.itemId = tableRowItem.id;
      this.edit.content = tableRowItem.value;
    },
    handleUpdate(tableRowItem) {
      this.sendToUpdate(tableRowItem);
    },
    async sendToUpdate(itemOnUpdate) {
      try {
        const url = "/tdh1/action";

        const payload = {
          type: this.type,
          action: "update",
          url_id: itemOnUpdate.id,
          id_url: this.filters.url,
        };

        this.loadings.onUpdate.push(itemOnUpdate.id);

        const resp = await service.post(url, payload);

        if (resp && resp.data && resp.data.success) {
          this.$message.notification({
            title: "Success",
            text: "Successfully sended to updating row.",
            type: "success",
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.onUpdate = this.loadings.onUpdate.filter(
          (v) => v !== itemOnUpdate.id
        );
      }
    },
    async sendToIgnore(item, type) {
      if (!(await this.open())) return;

      try {
        const url = "/tdh1/ignore-domain";

        const payload = {
          project_url_id: this.parentUrl,
          ignore_url_id: item.id,
          type,
        };

        this.loadings.ignore.push(item.id);

        const resp = await service.post(url, payload);

        this.loadings.ignore = this.loadings.ignore.filter(
          (v) => v !== item.id
        );

        if (resp && resp.data && resp.data.success) {
          this.$message.notification({
            title: "Success",
            text: "Domain has been successfully added to the ignored list.",
            type: "success",
          });
        }
      } catch {
        this.loadings.ignore = this.loadings.ignore.filter(
          (v) => v !== item.id
        );
      }
    },
  },
};
</script>
