<template>
  <v-container fluid class="tdh1">
    <v-row>
      <v-col cols="12">
        <page-header
          title="TDH1 Dashboard"
          :breadcrumbs="[
            {
              text: 'Home',
              to: '/',
            },
            {
              text: 'TDH1 Dashboard',
              disabled: true,
            },
          ]"
        />
      </v-col>
    </v-row>
    <v-form ref="formRef">
      <v-row dense>
        <v-col cols="6" md="4" lg="auto" xl="auto" class="d-flex">
          <v-autocomplete
            v-model="filters.url"
            dense
            :items="filtersData.url"
            outlined
            clearable
            :loading="loadings.filters"
            hide-details="auto"
            :menu-props="menuProps"
            placeholder="Select url"
            label="Url"
            style="min-width: 420px"
            :rules="[(v) => !!v || 'Url is required.']"
          >
            <template #item="{ item }">
              <v-row dense class="align-center">
                <v-col cols="8">
                  {{ item.text }}
                </v-col>
                <v-col class="d-flex justify-end" cols="4">
                  {{ item.value }}
                </v-col>
              </v-row>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="1">
          <v-menu
            bottom
            offset-y
            max-width="290px"
            :close-on-content-click="false"
            content-class="my-shadow--e3"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="filters.date"
                label="Select day"
                append-icon="mdi-calendar"
                readonly
                outlined
                hide-details
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filters.date" scrollable> </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="fill" class="d-flex justify-end" style="gap: 0.25rem">
          <v-btn
            @click="clearCache"
            :loading="loadings.cache"
            class="text-normal"
          >
            Clear cache <v-icon small right>mdi-close</v-icon>
          </v-btn>
          <v-btn
            :loading="loadings.export"
            @click="createExport"
            class="text-normal"
            >Create export
            <v-icon small right>mdi-export-variant</v-icon></v-btn
          >
          <v-btn @click="openInNew(filters.url)" class="text-normal">
            Visit URL
            <v-icon small right>mdi-open-in-new</v-icon>
          </v-btn>
          <v-tooltip v-if="filters.url" bottom>
            <template #activator="{ on }">
              <v-btn @click="goToSW" class="text-normal" v-on="on">
                Site workplace
                <v-icon small right>mdi-application-cog-outline</v-icon>
              </v-btn>
            </template>
            <div>Open Site Workplace with selected url</div>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn @click="goTo" class="text-normal" v-on="on">
                Search console
                <v-icon small right>mdi-console</v-icon>
              </v-btn>
            </template>
            <div>Open Search Console with selected url</div>
          </v-tooltip>
          <v-btn
            width="140px"
            style="height: 40px"
            color="primary"
            class="text-normal"
            @click="fetchContent"
          >
            Get Data</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
    <v-expand-transition>
      <v-row v-if="oldCollectedData.length > 0">
        <v-col cols="12">
          <v-card rounded="lg" flat outlined class="border--error">
            <v-card-title class="text-body-2 error--text">
              <v-icon small left color="error">mdi-alert-outline</v-icon>
              Data Collected more than 2 weeks ago for these URLs
            </v-card-title>
            <v-divider />
            <div class="pa-4">
              <v-text-field
                v-model="searchQuery"
                hide-details
                dense
                outlined
                placeholder="Type to search"
                clearable
                append-icon="mdi-magnify"
              />
            </div>
            <v-card-text class="px-0 pt-0 pb-2">
              <v-data-table
                :search="searchQuery"
                :sort-by="['vision']"
                :sort-desc="[true]"
                dense
                :loading="loadings.table"
                hide-default-footer
                :items-per-page="-1"
                :items="oldCollectedData"
                :headers="oldCollectedHeaders"
              >
                <template #[`item.url`]="{ value }">
                  <a :href="value" target="_blank">{{ value }}</a>
                </template>
                <template #[`item.date`]="{ value }">
                  <div style="white-space: nowrap">
                    {{ String(value) === "1970-01-01" ? "-" : value }}
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions />
          </v-card>
        </v-col>
      </v-row>
    </v-expand-transition>
    <v-row class="mt-12">
      <template v-if="firstLoading && loadings.cards">
        <v-col cols="12" class="d-flex flex-column" style="gap: 1rem">
          <v-card
            v-for="i in [0, 1, 2, 3]"
            :key="`skeleton-${i}`"
            flat
            outlined
            rounded="lg"
          >
            <v-card-title>
              <div style="width: 320px; height: 24px" class="skeleton" />
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-4">
              <v-skeleton-loader type="table" />
            </v-card-text>
          </v-card>
        </v-col>
      </template>
      <v-col
        v-else-if="cards && Object.keys(cards).length"
        cols="12"
        class="d-flex flex-column"
        style="gap: 1rem"
      >
        <v-card
          v-for="key in Object.keys(cards)"
          rounded="lg"
          flat
          outlined
          :key="key"
        >
          <v-progress-linear
            indeterminate
            v-if="loadings.sections.length > 0"
          ></v-progress-linear>
          <template>
            <v-card-title
              class="text-body-2 pa-6 text-capitalize justify-center"
            >
              <b>{{ key }}</b>
            </v-card-title>
            <v-divider />
            <v-card-text
              class="pa-6 d-flex flex-column"
              style="background: var(--card-darken-body-color); gap: 0.5rem"
            >
              <v-card rounded="lg" flat outlined>
                <template v-if="hasCompetitors(cards[key])">
                  <v-card-title class="text-body-2 justify-center">
                    <v-chip label class="mr-2" small>{{ key }}</v-chip>
                    Competitors
                  </v-card-title>
                  <v-divider />
                  <v-card-text class="px-0 pt-0 pb-2">
                    <competitor-table
                      :type="key"
                      :parent-url="filters.url"
                      :filters="filters"
                      v-if="cards[key].competitors.status"
                      :items="cards[key].competitors.items"
                    />
                  </v-card-text>
                  <v-divider />
                </template>
                <v-card-title class="text-body-2 justify-center">
                  <v-chip label class="mr-2" small>{{ key }}</v-chip>
                  Optimization by Semantics
                </v-card-title>
                <v-divider />
                <v-card-text class="px-0 pt-0 pb-2">
                  <div class="pa-4">
                    <v-text-field
                      v-model="cards[key].semantic.search"
                      hide-details
                      dense
                      outlined
                      placeholder="Type to search"
                      clearable
                      append-icon="mdi-magnify"
                    />
                  </div>
                  <v-data-table
                    :search="cards[key].semantic.search"
                    :sort-by="[
                      'pr',
                      'volume',
                      'cl_28d',
                      'imp_28d',
                      'sum_of_use',
                    ]"
                    :sort-desc="[false, true, true, true, true]"
                    dense
                    multi-sort
                    :items-per-page="30"
                    :custom-sort="_$smartCustomTableSort"
                    :footer-props="{ itemsPerPageOptions: [30, 50, -1] }"
                    :items="cards[key].semantic.items"
                    :headers="cards[key].semantic.headers"
                    :class="`card-table cols-${cards[key].semantic.headers.length}`"
                  >
                    <template #[`item.phrase`]="{ item }">
                      <phrase-smart-td :item="item" />
                    </template>

                    <template #[`item.new`]="{ value }">
                      <span
                        :class="`colored-td full-size ${value ? 'green' : ''}`"
                      >
                        {{ value ? 1 : "" }}
                      </span>
                    </template>
                    <template #[`item.cl_28d`]="{ value }">
                      {{ String(value) === "0" ? "" : value }}
                    </template>
                    <template #[`item.imp_28d`]="{ value }">
                      {{ String(value) === "0" ? "" : value }}
                    </template>
                    <template #[`item.sum_of_use`]="{ value }">
                      {{ String(value) === "0" ? "" : value }}
                    </template>
                    <template #[`item.pos`]="{ value }">
                      <span
                        :class="`colored-td full-size ${getPositionsColor(
                          value
                        )}`"
                      >
                        {{ value }}
                      </span>
                    </template>
                    <template
                      v-for="key in getIteratedCols(
                        cards[key].semantic.headers
                      )"
                      #[`item.${key}`]="{ value }"
                    >
                      <div
                        :class="`colored-td full-size ${
                          value === null ? 'red' : ''
                        }`"
                        :key="key"
                      >
                        {{ value ? 1 : null }}
                      </div>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
              <v-card rounded="lg" flat outlined>
                <v-card-title class="text-body-2 justify-center">
                  <v-chip label class="mr-2" small>{{ key }}</v-chip>
                  Optimization by Words From Semantic
                </v-card-title>
                <v-divider />
                <v-card-text class="px-0 pt-0 pb-2">
                  <div class="pa-4">
                    <v-text-field
                      v-model="cards[key].byWord.search"
                      hide-details
                      dense
                      outlined
                      placeholder="Type to search"
                      clearable
                      append-icon="mdi-magnify"
                    />
                  </div>
                  <v-data-table
                    :search="cards[key].byWord.search"
                    :sort-by="[
                      'pr',
                      'volume',
                      'cl_28d',
                      'imp_28d',
                      'sum_of_use',
                    ]"
                    :sort-desc="[false, true, true, true, true]"
                    dense
                    multi-sort
                    :items-per-page="30"
                    :custom-sort="_$smartCustomTableSort"
                    :footer-props="{ itemsPerPageOptions: [30, 50, -1] }"
                    :items="cards[key].byWord.items"
                    :headers="cards[key].byWord.headers"
                    :class="`card-table cols-${cards[key].byWord.headers.length}`"
                  >
                    <template #[`item.phrase`]="{ item }">
                      <phrase-smart-td :item="item" />
                    </template>
                    <template #[`item.new`]="{ value }">
                      <span
                        :class="`colored-td full-size ${value ? 'green' : ''}`"
                      >
                        {{ value ? 1 : "" }}
                      </span>
                    </template>
                    <template #[`item.cl_28d`]="{ value }">
                      {{ String(value) === "0" ? "" : value }}
                    </template>
                    <template #[`item.imp_28d`]="{ value }">
                      {{ String(value) === "0" ? "" : value }}
                    </template>
                    <template #[`item.sum_of_use`]="{ value }">
                      {{ String(value) === "0" ? "" : value }}
                    </template>
                    <template #[`item.pos`]="{ value }">
                      <span
                        :class="`colored-td full-size ${getPositionsColor(
                          value
                        )}`"
                      >
                        {{ value }}
                      </span>
                    </template>
                    <template
                      v-for="key in getIteratedCols(cards[key].byWord.headers)"
                      #[`item.${key}`]="{ value }"
                    >
                      <div
                        :class="`colored-td full-size ${
                          value === null ? 'red' : ''
                        }`"
                        :key="key"
                      >
                        {{ value ? 1 : null }}
                      </div>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
              <v-card rounded="lg" flat outlined>
                <v-card-title class="text-body-2 justify-center">
                  <v-chip label class="mr-2" small>{{ key }}</v-chip>
                  Optimization by Top Competitors
                </v-card-title>
                <v-divider />
                <v-card-text class="px-0 pt-0 pb-2">
                  <div class="pa-4">
                    <v-text-field
                      v-model="cards[key].topByCompetitors.search"
                      hide-details
                      dense
                      outlined
                      placeholder="Type to search"
                      clearable
                      append-icon="mdi-magnify"
                    />
                  </div>
                  <v-data-table
                    :search="cards[key].topByCompetitors.search"
                    :sort-by="getSort(cards[key].topByCompetitors.headers).by"
                    :sort-desc="
                      getSort(cards[key].topByCompetitors.headers).desc
                    "
                    dense
                    multi-sort
                    :items-per-page="30"
                    :custom-sort="_$smartCustomTableSort"
                    :footer-props="{ itemsPerPageOptions: [30, 50, -1] }"
                    :items="cards[key].topByCompetitors.items"
                    :headers="cards[key].topByCompetitors.headers"
                    :class="`card-table cols-${cards[key].topByCompetitors.headers.length}`"
                  >
                    <template #[`item.phrase`]="{ item }">
                      <phrase-smart-td :item="item" />
                    </template>
                    <template #[`item.new`]="{ value }">
                      <span
                        :class="`colored-td full-size ${value ? 'green' : ''}`"
                      >
                        {{ value ? 1 : "" }}
                      </span>
                    </template>
                    <template #[`item.cl_28d`]="{ value }">
                      {{ String(value) === "0" ? "" : value }}
                    </template>
                    <template #[`item.imp_28d`]="{ value }">
                      {{ String(value) === "0" ? "" : value }}
                    </template>
                    <template #[`item.sum_of_use`]="{ value }">
                      {{ String(value) === "0" ? "" : value }}
                    </template>
                    <template #[`item.pos`]="{ value }">
                      <span
                        :class="`colored-td full-size ${getPositionsColor(
                          value
                        )}`"
                      >
                        {{ value }}
                      </span>
                    </template>
                    <template
                      v-for="key in getIteratedCols(
                        cards[key].topByCompetitors.headers
                      )"
                      #[`item.${key}`]="{ value }"
                    >
                      <div
                        :class="`colored-td full-size ${
                          value === null ? 'red' : ''
                        }`"
                        :key="key"
                      >
                        {{ value ? 1 : null }}
                      </div>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
              <v-card rounded="lg" flat outlined>
                <v-card-title class="text-body-2 justify-center">
                  <v-chip label class="mr-2" small>{{ key }}</v-chip>
                  Optimization by Top 10
                </v-card-title>
                <v-divider />
                <v-card-text class="px-0 pt-0 pb-2">
                  <div class="pa-4">
                    <v-text-field
                      v-model="cards[key].byTop10.search"
                      hide-details
                      dense
                      outlined
                      placeholder="Type to search"
                      append-icon="mdi-magnify"
                      clearable
                    />
                  </div>
                  <v-data-table
                    :search="cards[key].byTop10.search"
                    :sort-by="[
                      'pr',
                      'volume',
                      'cl_28d',
                      'imp_28d',
                      'sum_of_use',
                    ]"
                    :sort-desc="[false, true, true, true, true]"
                    dense
                    multi-sort
                    :items-per-page="30"
                    :custom-sort="_$smartCustomTableSort"
                    :footer-props="{ itemsPerPageOptions: [30, 50, -1] }"
                    :items="cards[key].byTop10.items"
                    :headers="cards[key].byTop10.headers"
                    :class="`card-table cols-${cards[key].byTop10.headers.length}`"
                  >
                    <template #[`item.phrase`]="{ item }">
                      <phrase-smart-td :item="item" />
                    </template>
                    <template #[`item.new`]="{ value }">
                      <span
                        :class="`colored-td full-size ${value ? 'green' : ''}`"
                      >
                        {{ value ? 1 : "" }}
                      </span>
                    </template>
                    <template #[`item.cl_28d`]="{ value }">
                      {{ String(value) === "0" ? "" : value }}
                    </template>
                    <template #[`item.imp_28d`]="{ value }">
                      {{ String(value) === "0" ? "" : value }}
                    </template>
                    <template #[`item.sum_of_use`]="{ value }">
                      {{ String(value) === "0" ? "" : value }}
                    </template>
                    <template #[`item.pos`]="{ value }">
                      <span
                        :class="`colored-td full-size ${getPositionsColor(
                          value
                        )}`"
                      >
                        {{ value }}
                      </span>
                    </template>
                    <template
                      v-for="key in getIteratedCols(cards[key].byTop10.headers)"
                      #[`item.${key}`]="{ value }"
                    >
                      <div
                        :class="`colored-td full-size ${
                          value === null ? 'red' : ''
                        }`"
                        :key="key"
                      >
                        {{ value ? 1 : null }}
                      </div>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-card-text>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import service from "@/plugins/service";
import {
  COMP_MOCK,
  DEFAULT_MENU_PROPS,
  TDH1_OLD_COLLECTED_DATA_HEADERS,
  // TDH1_SECTION_INTERFACE,
} from "../../utils/defaultData";
import md5 from "md5";
import FiltersHandling from "../../mixins/components/FiltersHandling";
import PhraseSmartTd from "../../components/TDH1/UI/PhraseSmartTd";
import CompetitorTable from "./UI/CompetitorTable";
import Project from "../../mixins/Project";
import Notification from "../../mixins/Notification";
import ExportTaskService from "@/services/ExportTaskService";
import PageHeader from "../UI/PageHeader.vue";

export default {
  components: { PageHeader, CompetitorTable, PhraseSmartTd },
  mixins: [FiltersHandling, Project, Notification],
  data: () => ({
    firstLoading: true,
    loadings: {
      cards: false,
      table: false,
      export: false,
      cache: false,
      filters: false,
      sections: [],
    },
    tableData: {
      headers: [],
      items: [],
    },
    filters: {
      url: null,
      date: null,
    },
    searchQuery: null,
    oldCollectedData: [],
    showOldCollectedData: false,
    dateFilter: [],
    oldCollectedHeaders: TDH1_OLD_COLLECTED_DATA_HEADERS,
    filtersData: {
      url: [],
    },
    mock: COMP_MOCK,
    menuProps: DEFAULT_MENU_PROPS,
    cards: {
      // title: {
      //   ...TDH1_SECTION_INTERFACE,
      // },
      // description: {
      //   ...TDH1_SECTION_INTERFACE,
      // },
      // h1: {
      //   ...TDH1_SECTION_INTERFACE,
      // },
    },
  }),
  async mounted() {
    if (!this.activeProject) this.$store.dispatch("toggle_select_project_menu");

    await this.parseQuery("filters");

    if (this.$route.query.project_id) {
      this._$setActiveProject(this.$route.query.project_id, false);
    }

    this.$watch("activeProject", (data) => {
      if (data) this.fetchFilters();
    });

    this.fetchFilters();

    const haveParams = Object.keys(this.$route.query).length;

    if (!haveParams || !this.$route.query.url) return;

    if (haveParams) this.fetchContent();
  },
  computed: {
    activeProject() {
      return this.$store.state.project.active_project;
    },
  },
  methods: {
    async clearCache() {
      try {
        const url = `/tdh1/clear-cache/${this.filters.url}`;

        this.loadings.cache = true;

        const { data } = await service.post(url);

        if (data.success) {
          this.$message.notification({
            title: "Done",
            text: "Successfully cleared.",
            type: "success",
          });
        }
      } catch (e) {
        console.error("Error while clearing cache.", e);
      } finally {
        this.loadings.cache = false;
      }
    },
    async createExport() {
      try {
        const exportService = new ExportTaskService({
          json: {},
          type: 12,
          context: this,
        });

        this.loadings.export = true;

        await exportService.createExport();
      } finally {
        this.loadings.export = false;
      }
    },
    openInNew(urlId) {
      try {
        const url = this.filtersData.url.find((v) => v.value === urlId);
        window.open(url.text, "_blank");
      } catch {
        return this.$message.notification({
          title: "Warning",
          text: "Please, select URL.",
          type: "warning",
        });
      }
    },
    hasNewValue(items) {
      const exist = items.find((v) => v.vision === "OUR NEW") !== undefined;
      return exist;
    },
    getSort(headers) {
      const hasNewHeader =
        (headers.find((v) => v.value === "new") || []).length !== 0;
      if (hasNewHeader) {
        return {
          by: ["new", "sum_of_use", "pr", "volume", "cl_28d", "imp_28d"],
          desc: [true, true, false, true, true, true],
        };
      }
      return {
        by: ["entry", "sum_of_use", "pr", "volume", "cl_28d", "imp_28d"],
        desc: [true, true, false, true, true, true],
      };
    },
    goToSW() {
      const url = `/site-workplace/dashboard?url_id=${this.filters.url}`;
      window.open(url, "_blank");
    },
    goTo() {
      if (!this.filters.url) {
        return this.$message.notification({
          title: "Warning",
          text: "Please, select URL.",
          type: "warning",
        });
      }
      const url = `/search-console/url/${md5(this.filters.url)}`;
      window.open(url, "_blank");
    },
    hasCompetitors(data) {
      return (
        data.competitors !== undefined && data.competitors.items !== undefined
      );
    },
    getPositionsColor(value) {
      const v = isNaN(value) ? 0 : Number(value);

      if (!value) return "";

      if (v === 101) return "gray";

      if (v >= 1 && v <= 10) return "green";

      if (v >= 11 && v <= 30) return "yellow";

      if (v >= 31 && v <= 50) return "orange";

      if (v >= 51 && v <= 100) return "red";
    },
    _$smartCustomTableSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (a[sortBy[i]] === null && b[sortBy[i]] === null) continue;

          let aVal, bVal;
          if (
            a[sortBy[i]] === null ||
            a[sortBy[i]] === "" ||
            a[sortBy[i]] === "0" ||
            a[sortBy[i]] === 0
          )
            aVal = 0;
          if (
            b[sortBy[i]] === null ||
            b[sortBy[i]] === "" ||
            b[sortBy[i]] === "0" ||
            b[sortBy[i]] === 0
          )
            bVal = 0;
          if (aVal === 0 && bVal !== 0) return 1;
          if (bVal === 0 && aVal !== 0) return -1;

          if (a[sortBy[i]] > b[sortBy[i]]) return sortDesc[i] ? -1 : 1;
          if (a[sortBy[i]] < b[sortBy[i]]) return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
    async fetchFilters() {
      this.loadings.filters = true;
      const filters = ["url"];
      const payload = {
        type: "/tdh1-dashboard",
        take: filters,
        filter: {
          projectID: this.$store.getters.active_project,
        },
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);
      if (resp) {
        const keys = Object.keys(resp);
        keys.forEach((key) => {
          this.filtersData[key] = resp[key];
        });
      }
      this.loadings.filters = false;
    },
    fetchContent() {
      if (!this.activeProject)
        return this.mWarning({
          title: "Please, select project.",
          text: "To continue using the dashboard, select a project.",
        });

      if (!this.$refs.formRef.validate()) return;

      this.fetchData();

      const types = ["h1", "title", "description"];

      const sections = [
        {
          name: "semantic",
          endpoint: "/tdh1/optimization-by-semantic",
        },
        {
          name: "byWord",
          endpoint: "/tdh1/optimization-by-words-from-semantic",
        },
        {
          name: "topByCompetitors",
          endpoint: "/tdh1/optimization-by-top-competitors-words-by-semantic",
        },
        {
          name: "byTop10",
          endpoint: "/tdh1/optimization-by-top10",
        },
      ];

      this.loadings.cards = true;

      const promises = [];

      types.forEach((type) => {
        sections.forEach((sectionOptions) => {
          promises.push(this.fetchSectionsData(type, sectionOptions));
        });

        promises.push(this.fetchCompetitors(type));
      });

      Promise.allSettled(promises).then(() => {
        this.loadings.cards = false;
        this.firstLoading = false;
      });
    },
    async fetchData() {
      try {
        const url = `/tdh1/urls-relevance`;
        const config = {
          params: {
            ...this.filters,
          },
        };

        this.loadings.table = true;

        const resp = await service.get(url, true, config);

        if (resp && resp.data) {
          this.oldCollectedData = resp.data.items;
          this.showOldCollectedData = resp.data.status;
        }
      } catch (e) {
        console.error("Error while loading data.", e);
      } finally {
        this.loadings.table = false;
      }
    },
    getIteratedCols(items) {
      const output = [];

      items.forEach((header) => {
        if (header.value[0] === "$" || header.value === "entry") {
          output.push(header.value);
        }
      });

      return output;
    },
    async fetchCompetitors(type) {
      try {
        const url = "/tdh1/urls-values";

        const configs = {
          params: {
            ...this.filters,
            type,
          },
        };

        const resp = await service.get(url, true, configs);

        if (resp && resp.data) {
          if (!this.cards[type]) this.$set(this.cards, type, {});
          this.$set(this.cards[type], "competitor", resp.data);
          // this.cards[type].competitors = resp.data;
        }
      } catch {
        //
      }
    },
    async fetchSectionsData(type, options) {
      const id = type + options.name;

      try {
        const url = options.endpoint;
        const config = {
          params: {
            ...this.filters,
            type,
          },
        };

        this.loadings.sections.push(id);

        const resp = await service.get(url, true, config);

        this.loadings.sections = this.loadings.sections.filter((v) => v !== id);

        if (resp && resp.data) {
          if (!this.cards[type]) this.$set(this.cards, type, {});
          this.$set(this.cards[type], options.name, resp.data);
          // this.cards[type][options.name] = resp.data;
        }
      } catch {
        this.loadings.sections = this.loadings.sections.filter((v) => v !== id);
      }
    },
  },
};
</script>

<style lang="scss">
.tdh1 {
  table {
    overflow: hidden;
    td:first-child {
      position: relative;
    }
  }
  .card-table {
    &.cols-19 {
      tbody {
        td:nth-child(1),
        td:nth-child(3),
        td:nth-child(5),
        td:nth-last-child(5) {
          border-right: 1px solid rgba(0, 0, 0, 0.15);
        }
      }
      thead {
        th:nth-child(1),
        th:nth-child(3),
        th:nth-child(5),
        th:nth-last-child(5) {
          border-right: 1px solid rgba(0, 0, 0, 0.15);
        }
      }
    }
    &.cols-18 {
      tbody {
        td:nth-child(1),
        td:nth-child(2),
        td:nth-child(4),
        td:nth-last-child(5) {
          border-right: 1px solid rgba(0, 0, 0, 0.15);
        }
      }
      thead {
        th:nth-child(1),
        th:nth-child(2),
        th:nth-child(4),
        th:nth-last-child(5) {
          border-right: 1px solid rgba(0, 0, 0, 0.15);
        }
      }
    }
    &.cols-17 {
      tbody {
        td:nth-child(1),
        td:nth-child(2),
        td:nth-last-child(5) {
          border-right: 1px solid rgba(0, 0, 0, 0.15);
        }
      }
      thead {
        th:nth-child(1),
        th:nth-child(2),
        th:nth-last-child(5) {
          border-right: 1px solid rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
}
.v-application.theme--dark {
  .tdh1 {
    .card-table {
      &.cols-19 {
        tbody {
          td:nth-child(1),
          td:nth-child(3),
          td:nth-child(5),
          td:nth-last-child(5) {
            border-right: 1px solid rgba(255, 255, 255, 0.15);
          }
        }
        thead {
          th:nth-child(1),
          th:nth-child(3),
          th:nth-child(5),
          th:nth-last-child(5) {
            border-right: 1px solid rgba(255, 255, 255, 0.15);
          }
        }
      }
      &.cols-18 {
        tbody {
          td:nth-child(1),
          td:nth-child(2),
          td:nth-child(4),
          td:nth-last-child(5) {
            border-right: 1px solid rgba(255, 255, 255, 0.15);
          }
        }
        thead {
          th:nth-child(1),
          th:nth-child(2),
          th:nth-child(4),
          th:nth-last-child(5) {
            border-right: 1px solid rgba(255, 255, 255, 0.15);
          }
        }
      }
      &.cols-17 {
        tbody {
          td:nth-child(1),
          td:nth-child(2),
          td:nth-last-child(5) {
            border-right: 1px solid rgba(255, 255, 255, 0.15);
          }
        }
        thead {
          th:nth-child(1),
          th:nth-child(2),
          th:nth-last-child(5) {
            border-right: 1px solid rgba(255, 255, 255, 0.15);
          }
        }
      }
    }
  }
}
</style>
