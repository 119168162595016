<template>
  <tr>
    <td class="text-center" style="width: 35px">
      <div :class="`${getRowClass(row)}`" />
      <v-menu>
        <template #activator="{ on }">
          <v-icon v-on="on">mdi-dots-vertical</v-icon>
        </template>
        <v-list dense>
          <v-list-item @click="handleViewEntity(row)">
            <v-list-item-icon>
              <v-icon>mdi-open-in-new</v-icon>
            </v-list-item-icon>
            <v-list-item-title> View details </v-list-item-title>
          </v-list-item>
          <v-list-item @click="handleEditDonor(row)">
            <v-list-item-icon>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-title> Edit donor </v-list-item-title>
          </v-list-item>
          <v-list-item @click="handleVisitDonor(row)">
            <v-list-item-icon>
              <v-icon>mdi-open-in-new</v-icon>
            </v-list-item-icon>
            <v-list-item-title> Visit donor </v-list-item-title>
          </v-list-item>
          <v-list-item @click="handleUpdateRow(row)">
            <v-list-item-icon>
              <v-icon>mdi-sync</v-icon>
            </v-list-item-icon>
            <v-list-item-title> Update </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </td>
    <td-donor :value="row.donor" :data="row" />
    <td>{{ row.acceptor }}</td>
    <td>{{ row.phrase }}</td>
    <td-server-response :value="row.server_response" />
    <td-on-page :value="row.on_page" />
    <td-no-index :value="row.noindex" />
    <td-no-follow :value="row.nofollow" />
    <td>{{ row.canonical }}</td>
    <td :title="row.last_check_on_page">{{ row.dateadd }}</td>
    <td>{{ row.performer }}</td>
    <td>
      <v-autocomplete
        v-model="row.status_id"
        dense
        outlined
        hide-details
        item-text="name"
        item-value="id"
        style="min-width: 150px"
        :items="statuses"
        @change="handleChangeStatus($event, row)"
        :menu-props="menuProps"
      />
    </td>
    <td style="white-space: nowrap">
      {{ row.email }}
    </td>
    <td>{{ fixDate(row.status_date) }}</td>
    <td-price :value="row.price" />
    <v-dialog
      v-model="editDialog"
      content-class="remove-dialog-shadow"
      max-width="550"
    >
      <v-card>
        <v-card-title>Edit Donor</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="editDonorData.done_url"
            dense
            outlined
            label="Donor"
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="editDialog = false" text color="error">Close</v-btn>
          <v-btn
            @click="handleSaveEditedDonor"
            outlined
            color="success"
            class="ml-2"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </tr>
</template>

<script>
import TdDonor from "@/components/Ltt/LTTDeletedLinks/td/TdDonor";
import TdServerResponse from "@/components/Ltt/LTTDeletedLinks/td/TdServerResponse";
import TdOnPage from "@/components/Ltt/LTTDeletedLinks/td/TdOnPage";
import TdNoIndex from "@/components/Ltt/LTTDeletedLinks/td/TdNoIndex";
import TdNoFollow from "@/components/Ltt/LTTDeletedLinks/td/TdNoFollow";
import TdPrice from "@/components/Ltt/LTTDeletedLinks/td/TdPrice";
import {
  DEFAULT_MENU_PROPS,
  DELETED_LINKS_STATUS_COLORS,
} from "@/utils/defaultData";

export default {
  components: {
    TdPrice,
    TdNoFollow,
    TdNoIndex,
    TdOnPage,
    TdServerResponse,
    TdDonor,
  },
  props: ["modelValue", "statuses"],
  data: () => ({
    menuProps: DEFAULT_MENU_PROPS,
    STATUSES: DELETED_LINKS_STATUS_COLORS,
    editDialog: false,
    editDonorData: {
      done_url: null,
      link_id: null,
    },
  }),
  computed: {
    row: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    fixDate(date) {
      if (!date) return;

      return this.$moment(date).format("YYYY/MM/DD");
    },
    handleViewEntity(data) {
      const path = "/gpm/view-entity/" + data.entity_id;
      window.open(path, "_blank");
    },
    handleChangeStatus(status, item) {
      try {
        const payload = {
          link_id: item.link_id,
          status_id: status,
        };
        this.$store.dispatch("ltt-deleted-links/updateStatus", payload);
      } catch (e) {
        console.error("Error while changing status.", e);
      }
    },
    handleEditDonor(data) {
      this.editDialog = true;
      this.editDonorData.done_url = data.donor;
      this.editDonorData.link_id = data.link_id;
    },
    async handleUpdateRow({ link_id }) {
      const payload = {
        link_id,
        config: {
          params: {
            name: "update_info",
          },
        },
      };

      const resp = await this.$store.dispatch(
        "ltt-deleted-links/updateLink",
        payload
      );

      if (resp) console.log(resp);
    },
    handleVisitDonor(data) {
      window.open(data.donor, "_blank");
    },
    getRowClass({ status_id: status }) {
      const statuses = this.STATUSES;
      let output = "";

      Object.keys(statuses).forEach((key) => {
        if (status === statuses[key].code) {
          output = statuses[key].classes;
        }
      });

      return output;
    },
    async handleSaveEditedDonor() {
      const { done_url, link_id } = this.editDonorData;
      const payload = { done_url, link_id };

      const resp = await this.$store.dispatch(
        "ltt-deleted-links/updateDonorUrl",
        payload
      );

      if (resp === true) {
        this.$message.notification({
          title: "Saving success.",
          text: "Donor successfully updated.",
          duration: 3500,
          type: "success",
        });
        this.row.donor = done_url;
      } else {
        this.$message.notification({
          title: "Saving error.",
          text: "Something wrong.",
          duration: 3500,
          type: "error",
        });
      }

      this.editDonorData = {
        done_url: null,
        link_id: null,
      };
      this.editDialog = false;
    },
  },
};
</script>
