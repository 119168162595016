<template>
  <v-container fluid style="padding-bottom: 200px">
    <v-row dense>
      <v-col cols="12">
        <h2>GPM Dashboard</h2>
      </v-col>
      <v-col cols="12">
        <v-row dense>
          <v-col>
            <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="styled-card--light">
          <v-card-title class="d-flex">
            Filters
            <v-spacer />
            <v-chip
              label
              class="mr-2 flat-chip--primary"
              @click="uploadDomainDialog = true"
            >
              <span class="px-2">
                Upload domains <v-icon class="pl-2" small>mdi-upload</v-icon>
              </span>
            </v-chip>
            <v-menu content-class="remove-dialog-shadow pa-2">
              <template #activator="{ on }">
                <v-chip label class="flat-chip--primary" v-on="on">
                  <span class="px-2">
                    Export
                    <v-icon class="pl-2" small>mdi-cloud-download</v-icon>
                  </span>
                </v-chip>
              </template>
              <v-card
                width="240px"
                class="shadow-e1-bordered py-2 px-0"
                rounded="lg"
              >
                <v-list dense>
                  <v-list-item @click="handleExportPostedLinks">
                    <v-list-item-icon>
                      <v-icon v-if="!loadings.exportPostedLinks">
                        mdi-link
                      </v-icon>
                      <v-progress-circular
                        size="22"
                        width="3"
                        indeterminate
                        v-else
                      />
                    </v-list-item-icon>
                    <v-list-item-content> Posted links </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="handleExportFilteredDomains">
                    <v-list-item-icon>
                      <v-icon>mdi-filter</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      Filtered domains
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="createExport">
                    <v-list-item-icon>
                      <v-icon>mdi-application-export</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content> Export all </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <v-row dense>
                  <v-col cols="6" md="4" lg="2" xl="1">
                    <smart-date-filter
                      :model-value="filters.date"
                      @update:modelValue="filters.date = $event"
                    />
                  </v-col>
                  <v-col cols="6" md="4" lg="2" xl="1">
                    <v-autocomplete
                      v-model="filters.project"
                      hide-details
                      dense
                      outlined
                      clearable
                      label="Project"
                      :loading="loadings.filters"
                      :menu-props="menuProps"
                      item-value="id"
                      item-text="name"
                      multiple
                      :items="_$getProjects"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" md="4" lg="2" xl="1">
                    <v-autocomplete
                      v-model="filters.status"
                      hide-details
                      dense
                      clearable
                      outlined
                      label="Status"
                      :loading="loadings.filters"
                      :menu-props="menuProps"
                      multiple
                      :items="filtersData.status"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" md="4" lg="2" xl="1">
                    <v-autocomplete
                      v-model="filters.responsible"
                      hide-details
                      dense
                      outlined
                      :loading="loadings.filters"
                      label="Responsible"
                      :menu-props="menuProps"
                      :items="filtersData.responsible"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" md="4" lg="2" xl="1">
                    <v-autocomplete
                      v-model="filters.niche"
                      hide-details
                      dense
                      clearable
                      outlined
                      :loading="loadings.filters"
                      label="Niche"
                      :menu-props="menuProps"
                      :items="filtersData.niche"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" md="4" lg="2" xl="1">
                    <v-autocomplete
                      v-model="filters.performer"
                      hide-details
                      dense
                      outlined
                      clearable
                      :loading="loadings.filters"
                      label="Performer"
                      :menu-props="menuProps"
                      :items="filtersData.performer"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="6" md="4" lg="2" xl="1">
                    <v-autocomplete
                      v-model="filters.task"
                      hide-details
                      dense
                      outlined
                      clearable
                      label="Task"
                      :loading="loadings.filters"
                      :menu-props="menuProps"
                      :items="filtersData.task"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" md="4" lg="2" xl="1">
                    <v-checkbox
                      v-model="filters.critical_estimate"
                      label="Critical estimate"
                      hide-details
                      dense
                      class="ma-00"
                    />
                  </v-col>
                  <v-col cols="6" md="4" lg="2" xl="1">
                    <v-autocomplete
                      v-model="filters.report_type"
                      outlined
                      :items="reportTypeItems"
                      hide-details
                      clearable
                      dense
                      label="Report Type"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" md="4" lg="2" xl="1"></v-col>
                  <v-col cols="6" md="4" lg="2" xl="1"></v-col>
                  <v-col cols="6" md="4" lg="2" xl="1">
                    <v-btn
                      block
                      color="primary"
                      :loading="loadings.sections"
                      @click="fetchData"
                      style="height: 40px"
                      >Get Data
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="noResultAfterFetch" cols="12">
        <v-row dense>
          <v-col cols="12" class="d-flex justify-center">
            <v-card class="shadow-e1-bordered" rounded="lg" width="320">
              <v-card-title class="ma-2 justify-center">
                <div style="max-width: 200px">
                  <result-not-found />
                </div>
              </v-card-title>
              <v-card-text class="text-center">
                There is no result from your filters. Change filters and try
                again
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="sections.length > 0" cols="12" class="mb-2">
        <v-text-field
          v-model="sarchBySection"
          dense
          hide-details
          label="Search by section title"
          append-icon="mdi-table"
          style="max-width: 260px"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <template v-if="loadings.sections">
          <v-card
            v-for="i in [0, 2, 3]"
            :key="i"
            class="styled-card--default mt-4"
          >
            <v-card-title class="d-flex">
              <div class="skeleton" style="width: 360px; height: 36px" />
              <v-spacer />

              <div class="skeleton mr-2" style="width: 120px; height: 36px" />
              <div class="skeleton" style="width: 120px; height: 36px" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-skeleton-loader type="table" />
            </v-card-text>
          </v-card>
        </template>
        <template v-else>
          <template v-for="section in sections">
            <div
              v-if="
                !sarchBySection ||
                (sarchBySection &&
                  section.title.includes(sarchBySection.toLowerCase()))
              "
              :key="section.id"
            >
              <v-card class="styled-card--default mb-6">
                <v-card-title class="d-flex">
                  <span>
                    <v-progress-circular
                      v-if="sections.all"
                      class="mr-2"
                      :value="(section.done * 100) / section.all"
                      size="40"
                    >
                      <span style="font-size: 12px">
                        {{ section.done }}/{{ section.all }}
                      </span>
                    </v-progress-circular>
                    {{ section.title }}
                    <v-progress-circular
                      v-if="
                        loadings.viewAnchorPlan.includes(section.id) ||
                        loadings.exportKeywords.includes(section.id)
                      "
                      size="22"
                      width="2"
                      indeterminate
                    />
                  </span>
                  <v-spacer />
                  <v-text-field
                    v-model="section.search"
                    hide-details
                    dense
                    filled
                    clearable
                    placeholder="Search in table"
                    prepend-inner-icon="mdi-magnify"
                    style="max-width: 320px"
                  ></v-text-field>
                  <v-spacer />
                  <span>
                    <v-chip
                      outlined
                      label
                      @click="handleViewAnchorPlan(section.id)"
                      :disabled="loadings.viewAnchorPlan.includes(section.id)"
                    >
                      <span class="px-2">
                        Anchor Plan
                        <v-icon small class="pl-2">mdi-calendar</v-icon>
                      </span>
                    </v-chip>
                    <v-chip
                      outlined
                      label
                      class="ml-2"
                      @click="handleExportMainKeywords(section.id)"
                      :disabled="loadings.exportKeywords.includes(section.id)"
                    >
                      <span class="px-2"
                        >Export Keywords
                        <v-icon small class="pl-2"
                          >mdi-cloud-download</v-icon
                        ></span
                      >
                    </v-chip>
                  </span>
                </v-card-title>
                <v-divider />
                <v-card-text class="pa-0">
                  <v-data-table
                    :search="section.search"
                    :headers="sectionTableHeaders"
                    :items="section.items"
                    :custom-sort="_$smartCustomTableSort"
                  >
                    <template #body="{ items }">
                      <tbody>
                        <tr v-for="item in items" :key="item.domain">
                          <td>
                            <div style="white-space: nowrap">
                              <a
                                :href="`http://${item.domain}`"
                                target="_blank"
                                >{{ item.domain }}</a
                              >
                              <v-btn
                                icon
                                :to="`/gpm/view-entity/${item.id}`"
                                link
                                small
                                target="_blank"
                                class="mx-2"
                              >
                                <v-icon small>mdi-login-variant</v-icon>
                              </v-btn>

                              <span class="overflow-chip">
                                <v-chip
                                  v-for="(tag, idx) in item.tags"
                                  :key="idx"
                                  small
                                  :color="getTagColor(tag)"
                                  @click="handleClickTag(tag, item)"
                                >
                                  {{ tag }}
                                </v-chip>
                              </span>
                            </div>
                          </td>
                          <td class="text-center">
                            {{ item.cost_task }}
                          </td>
                          <td class="text-center">
                            {{ item.cost_casino }}
                          </td>
                          <td class="text-center">
                            <div title="Status">
                              {{ item.status }}{{ item.status_additional }}
                            </div>

                            <div
                              v-if="item.cancel_reason"
                              title="Cancel reason"
                              class="error--text"
                            >
                              {{ item.cancel_reason }}
                            </div>
                          </td>
                          <td class="text-center">
                            {{ item.add_datetime }}
                          </td>
                          <td class="text-center">
                            {{ item.time_in_work_stage }}
                          </td>
                          <td class="text-center">
                            <v-chip
                              small
                              :color="
                                responsibilities_colors[item.responsible].bg
                              "
                              :style="
                                'color: ' +
                                responsibilities_colors[item.responsible].color
                              "
                            >
                              {{ item.responsible }}
                            </v-chip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </div>
          </template>
        </template>
      </v-col>
    </v-row>
    <gpm-dashboard-upload-domain-dialog
      :model-value="uploadDomainDialog"
      @update:modelValue="uploadDomainDialog = $event"
      @done="fetchData"
    />
    <gpm-dashboard-export-filtered-domains
      :model-value="filteredDomainsDialog"
      @update:modelValue="filteredDomainsDialog = $event"
      :filters-data="filtersData"
    />
    <gpm-dashboard-view-anchor-plan
      :model-value="anchorPlanDialogModel"
      @update:modelValue="anchorPlanDialogModel = $event"
      :items="anchorPlanItems"
    />
    <v-dialog
      v-model="detailTasksDialogModel"
      content-class="remove-dialog-shadow"
      width="660px"
    >
      <v-card class="shadow-e1-bordered" rounded="lg">
        <v-card-title> Other tasks </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Task Name</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in detailedTaskItems" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>
                    <a :href="'/gpm/view-entity/' + item.id" target="_blank">{{
                      item.name
                    }}</a>
                  </td>
                  <td>{{ item.status_name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="d-flex">
          <v-spacer />
          <v-btn width="180" @click="detailTasksDialogModel = false"
            >Back</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import {
  DEFAULT_MENU_PROPS,
  GPM_UNIVERSAL_DASHBOARD_TABLE_HEADERS,
} from "@/utils/defaultData";
import GpmDashboardUploadDomainDialog from "@/components/Gpm/UI/GpmDashboardUploadDomainDialog.vue";
import GpmDashboardExportFilteredDomains from "@/components/Gpm/UI/GpmDashboardExportFilteredDomains.vue";
import service from "@/plugins/service";
import Project from "@/mixins/Project";
import GpmDashboardViewAnchorPlan from "@/components/Gpm/UI/GpmDashboardViewAnchorPlan.vue";
import { RESPONSIBILITIES_COLORS } from "@/utils/selectItems";
import TableSorting from "@/mixins/TableSorting";
import dateFormat from "dateformat";
import ResultNotFound from "@/components/UI/Icons/ResultNotFound.vue";

export default {
  components: {
    ResultNotFound,
    GpmDashboardViewAnchorPlan,
    GpmDashboardExportFilteredDomains,
    GpmDashboardUploadDomainDialog,
    SmartDateFilter,
  },
  mixins: [FiltersHandling, Project, TableSorting],
  data: () => ({
    sectionTableHeaders: GPM_UNIVERSAL_DASHBOARD_TABLE_HEADERS,
    anchorPlanDialogModel: false,
    anchorPlanItems: [],
    developerDialogModel: false,
    responsibilities_colors: RESPONSIBILITIES_COLORS,
    detailTasksDialogModel: false,
    reportTypeItems: [
      {
        text: "Main GP",
        value: "main_gp",
      },
      {
        text: "Status GP",
        value: "status_gp",
      },
    ],
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "GPM",
        disabled: true,
      },
      {
        text: "Universal Dashboard",
        disabled: true,
      },
    ],
    uploadDomainDialog: false,
    filtersData: {
      task: [],
      status: [],
      responsible: [],
      niche: [],
      performer: [],
      projects: [],
    },
    sections: [],
    menuProps: DEFAULT_MENU_PROPS,
    noResultAfterFetch: false,
    loadings: {
      sections: false,
      filters: false,
      exportPostedLinks: false,
      viewAnchorPlan: [],
      exportKeywords: [],
    },
    detailedTaskItems: [],
    filteredDomainsDialog: false,
    sarchBySection: null,
    filters: {
      report_type: "main_gp",
      date: [null, null],
      task: null,
      project: [],
      status: [],
      responsible: null,
      niche: null,
      performer: null,
      critical_estimate: null,
    },
  }),
  async created() {
    await this.initializeFiltersWatcher("filters");
    await this.collectParametersFromQuery("filters");

    this.fetchFilters();

    if (!this.$route.query.date) {
      const date = new Date();
      this.filters.date = [
        dateFormat(
          new Date(date.getFullYear(), date.getMonth(), 1),
          "yyyy-mm-dd"
        ),
        dateFormat(
          new Date(date.getFullYear(), date.getMonth() + 1, 0),
          "yyyy-mm-dd"
        ),
      ];
    }

    if (this.hasQueryParams) this.fetchData();
  },
  watch: {
    "filters.project"(projectId) {
      this.fetchTaskItems(projectId);
    },
  },
  methods: {
    fetchTaskItems() {
      this.fetchFilters(["task"]);
    },
    async createExport() {
      try {
        this.loadings.export = true;
        let url = "/export/base/create-task";

        let payload = {
          typeID: 15,
          json: {
            ...this.filters,
          },
        };

        const resp = await service.post(url, payload);
        this.loadings.export = false;

        if (resp && resp.data && resp.data.success) {
          this.$message.notification({
            title: "Done",
            text: "Export successfully created.",
            type: "success",
          });
        }
      } catch {
        this.loadings.export = false;
      }
    },
    handleClickTag(tag = "", item = {}) {
      this.detailedTaskItems = (item.tasks || {})[tag] || [];
      this.detailTasksDialogModel = true;
    },
    getTagColor(name) {
      const tmprName = String(name).toLowerCase();

      if (tmprName === "used") return "warning";
      if (tmprName === "hub") return "info";
      if (tmprName === "other") return "info";
      if (tmprName === "ltt") return "success";
      if (tmprName === "removed") return "error";

      return "";
    },
    async handleExportMainKeywords(projectId) {
      try {
        let url = "/export/base/create-task";

        let data = {
          typeID: 6,
          json: { taskID: projectId },
        };

        this.loadings.exportKeywords.push(projectId);

        const resp = await service.post(url, data);

        this.loadings.exportKeywords = this.loadings.exportKeywords.filter(
          (v) => v !== projectId
        );

        if (resp) {
          this.$message.notification({
            title: "Export",
            text: "Main keywords successfully exported! 👍",
            type: "success",
            duration: 6000,
          });
        }
      } catch {
        this.loadings.exportKeywords = this.loadings.exportKeywords.filter(
          (v) => v !== projectId
        );
      }
    },
    async handleViewAnchorPlan(projectId) {
      try {
        this.loading = true;

        const url = "/gpm/task-view/get-anchor-plan";

        const config = {
          params: {
            anchorPlanTaskID: projectId,
          },
        };

        this.loadings.viewAnchorPlan.push(projectId);

        const resp = await service.get(url, true, config);

        this.loadings.viewAnchorPlan = this.loadings.viewAnchorPlan.filter(
          (v) => v !== projectId
        );

        if (resp && resp.data) {
          this.anchorPlanItems = resp.data.items;
          this.anchorPlanDialogModel = true;
        }
      } catch {
        this.loadings.viewAnchorPlan = this.loadings.viewAnchorPlan.filter(
          (v) => v !== projectId
        );
      }
    },
    async handleExportPostedLinks() {
      try {
        this.export_loading = true;

        const url = "/export/base/get-hash";

        this.loadings.exportPostedLinks = true;

        const response = await service.post(url, this.filters);

        if (response.data.success) {
          const path = `${this.$store.state.server_url}/export/gpm/posted-links/0?hash=${response.data.hash}`;

          window.open(path, "_blank");
        }
      } catch (e) {
        console.error("Error while exporting posted links.", e);
      } finally {
        this.loadings.exportPostedLinks = false;
      }
    },
    handleExportFilteredDomains() {
      this.filteredDomainsDialog = true;
    },
    getCostScoreColor(value) {
      if (!value) return "";

      if (value >= 0 && value <= 50) return "warning";
      if (value > 50 && value <= 200) return "info";
      if (value > 200) return "success";

      return "";
    },
    showAcnhorPlan() {},
    async fetchData() {
      try {
        const url = "/gpm/dashboard/main";

        const configs = {
          params: this.filters,
        };

        this.noResultAfterFetch = false;
        this.loadings.sections = true;

        const resp = await service.get(url, true, configs);

        this.loadings.sections = false;

        if (resp && resp.data) {
          this.sections = resp.data.items;
          if (this.sections.length === 0) this.noResultAfterFetch = true;
        }
      } catch {
        this.loadings.sections = false;
      }
    },
    async fetchFilters(filters) {
      const defaultFilters = [
        "status",
        "task",
        "niche",
        "performer",
        "responsible",
      ];
      const payload = {
        type: "/gpm/dashboard/main",
        take: filters || defaultFilters,
        filter: {
          projectID: this.filters.project,
        },
      };

      this.loadings.filters = true;

      const resp = await this.$store.dispatch("global/getFilters", payload);

      if (resp) this.filtersData = { ...this.filtersData, ...resp };

      this.loadings.filters = false;
    },
  },
};
</script>
