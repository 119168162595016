<template>
  <v-container fluid class="month-report" style="padding-bottom: 200px">
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between">
        <div>
          <h2>Monthly Report</h2>
          <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
        </div>
      </v-col>
    </v-row>
    <v-row dense class="mt-6">
      <v-col cols="6" md="4" lg="2" xl="1">
        <v-autocomplete
          v-model="filters.team"
          hide-details
          dense
          :loading="loadings.filters"
          item-text="name"
          item-value="id"
          :items="filtersData.teams"
          outlined
          clearable
          label="Team"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="1">
        <v-autocomplete
          v-model="filters.executive"
          hide-details
          dense
          item-text="email"
          :loading="loadings.filters"
          item-value="id"
          :items="filtersData.executives"
          outlined
          clearable
          label="Executive"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="1">
        <v-autocomplete
          v-model="filters.period_goals"
          hide-details
          dense
          item-text="title"
          :loading="loadings.filters"
          item-value="id"
          :items="filtersData.period_goals"
          outlined
          multiple
          label="Period Goals"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="1">
        <extended-autocomplete
          :model-value="filters.labels"
          @update:modelValue="filters.labels = $event"
          :items="filtersData.labels"
          outlined
          limit-slot
          item-text="title"
          item-value="id"
          label="Label"
        />
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="1">
        <v-dialog ref="dialog" width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filters.deadline"
              label="Deadline"
              prepend-inner-icon="mdi-calendar"
              hide-details
              dense
              class="mb-1"
              readonly
              clearable
              outlined
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="filters.deadline" scrollable />
        </v-dialog>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="1">
        <smart-date-filter
          label="Dynamic Date"
          outlined
          :model-value="filters.dynamic_date"
          @update:modelValue="filters.dynamic_date = $event"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6" md="4" lg="2" xl="1">
        <smart-date-filter
          label="Report Date"
          outlined
          :model-value="filters.report_date"
          @update:modelValue="filters.report_date = $event"
        />
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="1">
        <smart-date-filter
          label="Date Added"
          outlined
          :model-value="filters.date_added"
          @update:modelValue="filters.date_added = $event"
        />
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="1">
        <v-autocomplete
          v-model="filters.status"
          label="Status"
          :items="statusItems"
          hide-details
          dense
          multiple
          item-text="text"
          item-value="value"
          outlined
          clearable
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="1">
        <v-autocomplete
          v-model="filters.type"
          label="Type"
          :items="['week', 'month']"
          hide-details
          dense
          outlined
          clearable
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="fill" class="d-flex justify-end">
        <v-btn
          @click="fetchData"
          color="primary"
          class="text-normal px-6"
          height="40"
          :loading="loadings.table"
          :disabled="loadings.table"
        >
          Get Data
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="items.length > 0">
      <v-col cols="12" class="pb-0 d-flex">
        <v-text-field
          v-model="search"
          dense
          hide-details
          label="Search by project name"
          append-outer-icon="mdi-magnify"
          style="max-width: 250px"
        ></v-text-field>
        <v-text-field
          v-model="tableSearch"
          dense
          hide-details
          label="Search in table"
          append-outer-icon="mdi-magnify"
          style="max-width: 250px"
          class="ml-4"
        ></v-text-field>
        <v-spacer />

        <a v-if="wsLink" :href="wsLink" target="_blank">
          <v-chip
            class="mr-2"
            label
            outlined
            style="border-style: dashed"
            @click="() => {}"
          >
            WS Link <v-icon small right>mdi-open-in-new</v-icon>
          </v-chip>
        </a>

        <v-menu
          left
          offset-y
          :close-on-content-click="false"
          content-class="my-shadow--e2"
        >
          <template #activator="{ on }">
            <v-chip v-on="on" outlined label class="mr-2">
              <v-icon small left>mdi-cog</v-icon>
              Table settings
              <v-icon small right>mdi-chevron-down</v-icon>
            </v-chip>
          </template>
          <v-card width="280" class="styled-card--default">
            <v-card-title class="d-flex"> Settings </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="8"> Fill cell with color </v-col>
                <v-col cols="4" class="d-flex justify-end">
                  <v-switch
                    v-model="settings.isMarkFixed"
                    hide-details
                    dense
                    class="ma-0"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-menu>

        <v-chip
          outlined
          label
          @click="handleSave"
          :disabled="loadings.save || items.length === 0"
        >
          Save all
          <v-icon v-if="!loadings.save" small right
            >mdi-content-save-all-outline</v-icon
          >
          <v-progress-circular
            v-else
            size="18"
            width="2"
            class="ml-2"
          ></v-progress-circular>
        </v-chip>
      </v-col>
    </v-row>
    <v-row v-if="loadings.table">
      <v-col cols="12">
        <div class="d-flex">
          <div class="d-flex" style="gap: 8px">
            <div class="skeleton" style="height: 32px; width: 250px"></div>
            <div class="skeleton" style="height: 32px; width: 250px"></div>
          </div>
          <v-spacer />
          <div class="d-flex align-end" style="gap: 8px">
            <div class="skeleton" style="height: 30px; width: 130px"></div>
            <div class="skeleton" style="height: 30px; width: 90px"></div>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-card class="styled-card--default">
          <v-card-title>
            <div class="skeleton" style="width: 150px; height: 24px" />
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-skeleton-loader type="table" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!loadings.table && dirty && items?.length === 0">
      <v-col class="d-flex justify-center my-6">
        <nothing-found-by-filters />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(item, idx) in items"
        :key="`${item.name}${idx}col`"
        cols="12"
      >
        <v-card class="styled-card--dark">
          <v-card-title>
            {{ String(item.name) === "0" ? "Without project" : item.name }}
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-2">
            <v-row>
              <v-col
                v-for="(card, idx) in getItems(item.items)"
                :key="`${card.name}-${idx}`"
                cols="12"
              >
                <v-card class="styled-card--default">
                  <v-card-title>
                    {{
                      String(card.name) === "0" ? "Without project" : card.name
                    }}
                  </v-card-title>
                  <v-divider />
                  <v-card-text class="pa-0">
                    <v-data-table
                      item-key="id"
                      :search="tableSearch"
                      :items="card.items"
                      :headers="headers"
                      :sort-by="['status', 'priority', 'deadline']"
                      :sort-desc="[false, true, false]"
                      multi-sort
                      :custom-sort="_$smartCustomTableSort"
                      group-by="label"
                      class="main-table"
                      hide-default-footer
                      :items-per-page="-1"
                    >
                      <template #[`group.header`]="{ group, toggle, isOpen }">
                        <td :colspan="headers.length + 1">
                          <div class="d-flex align-center">
                            <v-btn icon @click="toggle" class="mr-2">
                              <v-icon>
                                {{ isOpen ? "mdi-minus" : "mdi-plus" }}
                              </v-icon>
                            </v-btn>

                            <h4>{{ getFixedGroupHeader(group) }}</h4>
                          </div>
                        </td>
                      </template>
                      <template #[`item.executive`]="{ value }">
                        <user-preview :name="value" hide-initials />
                      </template>
                      <template #[`item.name`]="{ item }">
                        <div
                          class="d-flex align-center"
                          style="gap: 4px; min-width: 320px"
                        >
                          <create-task-menu
                            :filters-data="filtersData"
                            :row-data="item"
                          />
                          <div
                            v-if="
                              item.is_has_incorrectly_created_subtask ||
                              item.is_has_no_final_result_and_plan_subtask
                            "
                            :class="`mark ${
                              settings.isMarkFixed ? '' : 'fixed left'
                            } mark--info`"
                            title="Has incorrectly created subtask | has no final result and plan subtask"
                          />
                          <div
                            v-else-if="String(item.status) === '1'"
                            :class="`mark ${
                              settings.isMarkFixed ? '' : 'fixed left'
                            } mark--success`"
                            title="Status success"
                          />
                          <div
                            v-else-if="hasTag(item, '⊘ in risk!')"
                            :class="`mark ${
                              settings.isMarkFixed ? '' : 'fixed left'
                            } mark--warning`"
                            title="Status ⊘ in risk!"
                          />
                          <div
                            v-else-if="hasTag(item, '! Critical')"
                            :class="`mark ${
                              settings.isMarkFixed ? '' : 'fixed left'
                            } mark--error`"
                            title="Status ! Critical"
                          />
                          <div
                            class="d-flex justify-space-between align-center"
                            style="gap: 8px; width: 100%"
                          >
                            <div>
                              <div v-if="item?.parent">
                                <small class="d-flex align-center">
                                  <a
                                    :href="item.parent?.url"
                                    target="_blank"
                                    title="Parent task"
                                    style="
                                      max-width: 420px;
                                      display: inline-block;
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                      white-space: nowrap;
                                      color: inherit;
                                    "
                                  >
                                    {{ item.parent?.name }}
                                  </a>
                                </small>
                              </div>
                              <div v-if="item?.name_old" title="Old name">
                                <small>
                                  <s> {{ item.name_old }}</s>
                                </small>
                              </div>
                              <div>
                                <a :href="item.task_url" target="_blank">{{
                                  item.name
                                }}</a>
                              </div>
                            </div>
                            <div class="d-flex justify-end">
                              <template v-if="item.name_history?.length > 0">
                                <name-history :data="item">
                                  <v-chip
                                    label
                                    small
                                    color="transparent"
                                    class="px-1"
                                    @click="() => {}"
                                    title="Names history"
                                  >
                                    <v-icon small>mdi-history</v-icon>
                                  </v-chip>
                                </name-history>
                              </template>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template #[`item.statuses`]="{ item }">
                        <row-statuses-list :item="item" />
                      </template>
                      <template #[`item.sprint`]="{ value }">
                        <div class="d-flex flex-wrap py-2" style="gap: 2px">
                          <v-chip
                            v-for="(item, idx) in value"
                            :key="`${item.name}${idx}item`"
                            style="font-size: 12px"
                            label
                            small
                          >
                            {{ item.sprint }}:
                            <b
                              v-for="type in item.data"
                              :key="`${type.name}${idx}type`"
                              style="margin-left: 2px"
                            >
                              {{ type.name }}
                              <sup v-if="Number(type.priority) > 1">
                                {{ type.priority }}
                              </sup>
                            </b>
                          </v-chip>
                        </div>
                      </template>
                      <template #[`item.time`]="{ value }">
                        <span>{{ value === "0.0" ? "0" : value }}</span>
                      </template>
                      <template #[`item.status`]="{ value }">
                        {{ String(value) === "1" ? "Yes" : "No" }}
                      </template>
                      <template
                        #[`item.deadline`]="{
                          item: { overdue, deadline, first_deadline },
                        }"
                      >
                        <table-deadline-tooltip
                          :data="{ overdue, deadline, first_deadline }"
                        />
                      </template>
                      <template #[`item.estimate`]="{ value }">
                        <template v-if="!value">
                          <div
                            class="colored-td red"
                            title="Without estimation"
                          >
                            <span style="font-size: 10px"></span>
                          </div>
                        </template>
                        <template v-else>
                          {{ value ? parseInt(value).toFixed(0) : null }}
                        </template>
                      </template>
                      <template #[`item.dynamic`]="{ item: { dynamic } }">
                        <v-card
                          v-if="
                            dynamic && dynamic.length > 0 && notZeros(dynamic)
                          "
                          class="pa-1 styled-card--default"
                        >
                          <v-sparkline
                            :value="dynamic"
                            line-width="16"
                            :gradient="['#639ee0', '#2b4d93']"
                            type="bar"
                            :width="300"
                          >
                          </v-sparkline>
                        </v-card>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  DEFAULT_MOMENT_DATE_FORMAT,
  MONTHLY_REPORT_TABLE_HEADERS,
} from "@/utils/defaultData";
import service from "@/plugins/service";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import CopyUrlMixin from "@/mixins/CopyUrlMixin";
import Location from "../../mixins/Location";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import copy from "copy-to-clipboard";
import QueryBuilder from "@/mixins/QueryBuilder";
import UserPreview from "@/components/UI/UserPreview.vue";
import CreateTaskMenu from "@/components/MonthlyReport/CreateTaskMenu.vue";
import { defineComponent } from "vue";
import TableDeadlineTooltip from "@/components/MonthlyReport/TableDeadlineTooltip.vue";
import RowStatusesList from "@/components/MonthlyReport/RowStatusesList.vue";
import ExtendedChip from "@/components/UI/ExtendedChip.vue";
import SmartAutocomplete from "@/components/UI/SmartAutocomplete.vue";
import ExtendedAutocomplete from "@/components/UI/ExtendedAutocomplete.vue";
import NothingFoundByFilters from "@/components/UI/Placeholders/NothingFoundByFilters.vue";
import NameHistory from "@/components/MonthlyReport/NameHistory.vue";

export default defineComponent({
  components: {
    NameHistory,
    NothingFoundByFilters,
    ExtendedAutocomplete,
    SmartAutocomplete,
    ExtendedChip,
    RowStatusesList,
    TableDeadlineTooltip,
    CreateTaskMenu,
    UserPreview,
    SmartDateFilter,
  },
  mixins: [FiltersHandling, CopyUrlMixin, Location, QueryBuilder],
  data: () => ({
    lsSettingsId: "monthly-dashboard-settings",
    dirty: false,
    settings: {
      isMarkFixed: false,
    },
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Dashboards",
        disabled: true,
      },
      {
        text: "Monthly Report",
        disabled: true,
      },
    ],
    search: null,
    tableSearch: null,
    loadings: {
      table: false,
      save: false,
      filters: false,
    },
    filters: {
      team: null,
      executive: null,
      deadline: null,
      label: null,
      period_goals: [],
      dynamic_date: [null, null],
      report_date: [null, null],
      date_added: [null, null],
      labels: [],
      type: "month",
    },
    filtersData: {
      team: [],
      executive: [],
      labels: [],
    },
    items: [],
    wsLink: null,
    headers: MONTHLY_REPORT_TABLE_HEADERS,
    statusItems: [
      {
        text: "Done",
        value: 1,
      },
      {
        text: "In Work",
        value: 0,
      },
      {
        text: "TL is PlanResult",
        value: 2,
      },
      {
        text: "SEO is Plan Result",
        value: 3,
      },
      {
        text: "Not approved TL",
        value: 4,
      },
      {
        text: "Not approved SEO",
        value: 5,
      },
    ],
  }),
  created() {
    this.fetchFilters();
    this.collectSettingsFromLs();

    const haveParams = this._$collectParams(
      ["period_goals", "labels", "status", "report_date"],
      "filters"
    );

    if (!this.$route.query.dynamic_data) {
      this.filters.dynamic_date = [
        this.$moment().startOf("month").format(DEFAULT_MOMENT_DATE_FORMAT),
        this.$moment().format(DEFAULT_MOMENT_DATE_FORMAT),
      ];
    }

    if (haveParams) this.fetchData();
  },
  async mounted() {
    await this.initializeFiltersWatcher("filters");
    this.collectParametersFromQuery("filters");
  },
  watch: {
    settings: {
      deep: true,
      handler(value) {
        window.localStorage.setItem(this.lsSettingsId, JSON.stringify(value));
      },
    },
  },
  methods: {
    getItems(items) {
      if (!this.search) return items;

      return items.filter((v) =>
        String(v.name).toLowerCase().includes(String(this.search).toLowerCase())
      );
    },
    collectSettingsFromLs() {
      try {
        const lStorageSettings = window.localStorage.getItem(this.lsSettingsId);

        if (lStorageSettings && lStorageSettings !== "undefined") {
          this.settings = JSON.parse(lStorageSettings);
        }
      } catch {
        console.error(
          "cant parse settings from local storage in series-task-form"
        );
      }
    },
    notZeros(arr) {
      return !arr?.every((element) => element === 0);
    },
    async handleSave() {
      try {
        const url = "/worksection/report/monthly/save";

        let tmprOnSave = [];

        this.items.forEach((section) => {
          section.items.forEach((item) => {
            tmprOnSave.push({
              id: item.id,
              deadline: item.deadline || null,
              name: item.name,
            });
          });
        });

        const payload = tmprOnSave;

        this.loadings.save = true;

        const resp = await service.post(url, payload);

        if (resp && resp.data.success) {
          this.$message.notification({
            title: "Saved",
            text: "Tasks sucessfully saved.",
            type: "success",
          });
        }

        this.loadings.save = false;
      } catch (e) {
        this.loadings.save = false;
        console.error(e);
      }
    },
    handleCopyUrl() {
      const path =
        window.location.origin +
        window.location.pathname +
        this._$buildQuery(this.filters);
      copy(path);
      this.$message.notification({
        title: "Url coppied",
        text: `Here coppied url. Open in new page: <a href="${path}" target="_blank">Open coppied url</a>`,
        type: "success",
        duration: 10000,
      });
    },
    _$smartCustomTableSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (a[sortBy[i]] === null && b[sortBy[i]] === null) continue;

          const date = sortBy[i] === "deadline";

          const string =
            isNaN(parseInt(a[sortBy[i]])) && isNaN(parseInt(b[sortBy[i]]));

          if (date) {
            const a1 = Date.parse(a[sortBy[i]]) || 0;
            const b1 = Date.parse(b[sortBy[i]]) || 0;

            if (a1 > b1) return sortDesc[i] ? -1 : 1;
            if (a1 < b1) return sortDesc[i] ? 1 : -1;
            continue;
          }

          if (string) {
            let aVal, bVal;
            if (a[sortBy[i]] === null || a[sortBy[i]] === "") aVal = 0;
            if (b[sortBy[i]] === null || b[sortBy[i]] === "") bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;

            if (a[sortBy[i]] > b[sortBy[i]]) return sortDesc[i] ? -1 : 1;
            if (a[sortBy[i]] < b[sortBy[i]]) return sortDesc[i] ? 1 : -1;
            continue;
          }

          let aVal, bVal;
          if (isNaN(parseInt(a[sortBy[i]]))) aVal = 0;
          if (isNaN(parseInt(b[sortBy[i]]))) bVal = 0;
          if (aVal === 0 && bVal !== 0) return 1;
          if (bVal === 0 && aVal !== 0) return -1;

          if (parseInt(a[sortBy[i]]) > parseInt(b[sortBy[i]]))
            return sortDesc[i] ? -1 : 1;
          if (parseInt(a[sortBy[i]]) < parseInt(b[sortBy[i]]))
            return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
    hasTag(items, tag) {
      return (
        String(items.status) !== "1" &&
        String(items.stage).trim() === String(tag).trim()
      );
    },
    goTo(path) {
      window.open(path, "_blank");
    },
    async fetchFilters() {
      this.loadings.filters = true;
      const url = "/worksection/get/monthly/filter";

      const resp = await service.post(url, {});

      if (resp) this.filtersData = resp.data;

      this.loadings.filters = false;
    },
    async fetchData() {
      this.dirty = true;
      try {
        const url = "/worksection/report/monthly";

        const payload = {
          filter: this.filters,
        };

        this.loadings.table = true;

        const resp = await service.post(url, payload);

        this.loadings.table = false;

        if (resp) {
          this.items = resp.data.items;
          this.wsLink = resp.data.link;
        }
      } catch (e) {
        this.loadings.table = false;
        console.error(e);
      }
    },
    getFixedGroupHeader(data) {
      return String(data).replace(/[0-9]./g, "");
    },
  },
});
</script>
