<template>
  <v-card flat outlined rounded="lg">
    <v-card-title class="pa-4 text-body-2">
      <b>{{ data.title }}</b>
    </v-card-title>
    <v-divider />
    <v-card-text class="px-4" style="background: var(--gray-bg-low-emphasis)">
      <!-- User items layer ( 1 ) -->
      <div class="d-flex flex-column" style="gap: 1rem">
        <v-card
          v-for="(user, idx) in data?.items || []"
          :key="`user-${idx}`"
          flat
          outlined
          rounded="lg"
        >
          <v-card-title class="pa-4 text-body-2" style="gap: 0.5rem">
            <v-tooltip bottom open-delay="300">
              <template #activator="{ on }">
                <v-chip
                  v-on="on"
                  label
                  class="px-2 flat-chip flat-chip--primary"
                >
                  <v-icon small>mdi-account</v-icon>
                </v-chip>
              </template>
              <template #default> User </template>
            </v-tooltip>
            {{ user?.title }}
          </v-card-title>
          <v-card-text>
            <!-- Metric items layer ( 1 ) -->

            <div class="d-flex flex-column" style="gap: 1rem">
              <v-card
                v-for="(metric, idx) in user?.items || []"
                :key="`metric-${idx}`"
                flat
                outlined
                rounded="lg"
              >
                <v-card-title class="pa-4 text-body-2" style="gap: 0.5rem">
                  <v-tooltip bottom open-delay="300">
                    <template #activator="{ on }">
                      <v-chip
                        v-on="on"
                        label
                        class="px-2 flat-chip flat-chip--warning"
                      >
                        <v-icon small>mdi-chart-box-outline</v-icon>
                      </v-chip>
                    </template>
                    <template #default> Metric </template>
                  </v-tooltip>
                  {{ metric?.title }}
                  <v-chip outlined label @click="toggleExpand(metric)">
                    <template v-if="metric?._expanded">
                      Hide <v-icon small right>mdi-chevron-up</v-icon>
                    </template>
                    <template v-else>
                      Show all
                      <my-badge
                        v-if="metric?.items.length"
                        class="white--text primary ml-2"
                      >
                        {{ metric?.items.length }}
                      </my-badge>
                      <v-icon small right>mdi-chevron-down</v-icon>
                    </template>
                  </v-chip>
                </v-card-title>
                <v-card-text class="px-0 py-2">
                  <metric-data-table
                    :data="{
                      items: getItems(metric?.items || [], metric),
                      headers: data?.headers,
                    }"
                  />
                </v-card-text>
              </v-card>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import MetricDataTable from "./MetricDataTable.vue";
import MyBadge from "../../UI/MyBadge.vue";

export default {
  components: { MyBadge, MetricDataTable },

  props: ["data"],

  methods: {
    toggleExpand(ctx) {
      if (ctx?._expanded) {
        this.$set(ctx, "_expanded", false);
      } else {
        this.$set(ctx, "_expanded", true);
      }
      this.$forceUpdate();
    },
    getItems(items, ctx) {
      const isExpanded = ctx?._expanded;

      return items.filter((item) => {
        if (isExpanded) return true;

        return item._required;
      });
    },
  },
};
</script>
