<template>
  <div>
    <v-container fluid v-if="isset_active_project">
      <v-row>
        <v-col cols="12">
          <page-header
            title="Anchors Plan Dashboard"
            :breadcrumbs="[
              {
                text: 'Home',
                to: '/',
              },
              {
                text: 'Anchors plan dashboard',
                disabled: true,
              },
            ]"
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-card flat rounded="lg" outlined v-if="isset_active_project">
            <v-card-title class="pa-3 d-flex" style="gap: 0.5rem">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Type to search"
                hide-details
                outlined
                dense
                style="max-width: 320px"
              ></v-text-field>
              <v-menu
                transition="slide-x-transition"
                content-class="my-shadow--e2"
                :close-on-content-click="false"
                nudge-bottom="40"
                max-width="420"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="text-normal"
                    v-bind="attrs"
                    v-on="on"
                    height="40"
                  >
                    Filters <v-icon right small>mdi-filter</v-icon>
                  </v-btn>
                </template>
                <v-card flat outlined rounded="lg">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          @change="changeUrl"
                          v-model="filter.project"
                          :items="getProjects"
                          item-text="name"
                          item-value="id"
                          dense
                          hide-details
                          label="Project"
                          placeholder="Type to search"
                          outlined
                          clearable
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          @change="changeUrl"
                          v-model="filter.type"
                          :items="types_items"
                          item-text="name"
                          dense
                          item-value="id"
                          hide-details
                          label="Type"
                          placeholder="Type to search"
                          outlined
                          clearable
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          @change="changeUrl"
                          v-model="filter.performer"
                          :items="performers"
                          item-text="name"
                          dense
                          item-value="id"
                          hide-details
                          label="Performer"
                          placeholder="Type to search"
                          outlined
                          clearable
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          @change="changeUrl"
                          v-model="filter.level"
                          :items="levels"
                          item-text="name"
                          item-value="id"
                          hide-details
                          dense
                          label="Level"
                          placeholder="Type to search"
                          outlined
                          clearable
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <smart-date-filter
                          :model-value="filter.date_range"
                          @update:modelValue="filter.date_range = $event"
                          :dense="false"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="filter.project_categories"
                          :items="filtersData.project_categories"
                          :loading="filtersDataPendingKeys?.project_categories"
                          multiple
                          hide-details
                          dense
                          outlined
                          label="Project categories"
                          placeholder="Type to search"
                        />
                      </v-col>
                    </v-row>
                    <div class="text-center">
                      <v-btn
                        @click="fetch_data()"
                        block
                        large
                        class="text-normal mt-4"
                        color="primary"
                      >
                        Apply
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-card-title>
            <v-card-text class="px-0">
              <v-data-table
                :search="search"
                :headers="table.headers"
                :items="table.items"
                :style="{
                  '--apd-surface': $vuetify.theme.dark ? '#1e1e1e' : 'white',
                }"
                dense
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                      <td
                        class="text-left"
                        style="background-color: var(--apd-surface)"
                      >
                        {{ item.project }}
                      </td>
                      <td
                        class="text-left"
                        style="background-color: var(--apd-surface)"
                      >
                        {{ item.type }}
                      </td>
                      <td
                        class="text-left"
                        style="background-color: var(--apd-surface)"
                      >
                        {{ item.metric }}
                      </td>
                      <td
                        class="text-center"
                        v-for="(date, idx) in table.dates"
                        :key="idx"
                      >
                        <span v-if="item[date.full].date > 0">
                          <span
                            v-if="
                              item[date.full].diff > 0 ||
                              item[date.full].diff < 0
                            "
                          >
                            <v-badge
                              :color="
                                getDiffColor(item[date.full].diff, item.metric)
                              "
                              :content="getDiffContent(item[date.full].diff)"
                              overlap
                              bordered
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip label small v-bind="attrs" v-on="on">
                                    {{ item[date.full].date }}
                                  </v-chip>
                                </template>
                                <span>{{ item[date.full].date }}</span>
                              </v-tooltip>
                            </v-badge>
                          </span>
                          <span v-else>
                            <v-chip label small>
                              {{ item[date.full].date }}
                            </v-chip>
                          </span>
                        </span>
                      </td>
                      <td class="text-left">
                        {{ item.performer }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
      dark
      v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn
            color="primary"
            dark
            @click="$store.state.project.menu_select_projects = true"
            >Select project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import axios from "axios";
import PageHeader from "../../UI/PageHeader.vue";
import SmartDateFilter from "../../UI/SmartDateFilter.vue";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import UploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";

let dateFormat = require("dateformat");
let date = new Date();

export default {
  name: "AnchorPlanDashboardIndex",
  components: { SmartDateFilter, PageHeader },
  mixins: [FiltersHandling, UploadFiltersDataMixin],
  metaInfo: {
    title: "Anchor Plan Dashboard - LTT - SI",
  },
  data() {
    return {
      search: "",
      loadings: {},
      date_modal: false,
      table: {
        items: [],
        headers: [],
        dates: [],
      },
      types_items: [],
      levels: [
        { id: 1, name: "1 level" },
        { id: 2, name: "2 level" },
      ],
      performers: [],
      filtersData: {
        project_categories: [],
      },
      filter: {
        project_categories: [],
        date_range: [
          dateFormat(new Date().setMonth(date.getMonth() - 1), "yyyy-mm-dd"),
          dateFormat(date, "yyyy-mm-dd"),
        ],
        type: null,
        project: null,
        level: 1,
        performer: null,
      },
    };
  },
  async mounted() {
    await this.parseQuery("filter");

    this.uploadFiltersData(["project_categories"]);

    this.fetch_types_list();
    this.fetch_data();
  },
  methods: {
    getDiffColor(diff, name) {
      if (name === "problematic") {
        if (diff > 0) {
          return "red";
        } else return "#d7d700";
      } else {
        if (diff > 0) {
          return "#d7d700";
        }
      }
      return "grey";
    },
    getDiffContent(diff) {
      if (diff > 0) {
        return "+" + diff;
      } else return diff;
    },
    fetch_types_list() {
      let self = this;

      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("types_list", true);

        let url = `${self.$store.state.server_url}/ltt/links-type`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        axios
          .get(url, config)
          .then(function (response) {
            self.types_items = response.data;
            self.update_loading("types_list", false);
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    changeUrl() {
      this.$router.push({
        name: "anchors-plan.dashboard",
        query: {
          type: this.filter.type,
          project: this.filter.project,
          level: this.filter.level,
          performer: this.filter.performer,
        },
      });
    },
    fetch_data() {
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        self.update_loading("fetch_data", true);

        let url = `${self.$store.state.server_url}/anchor-plan/placement-links/dashboard`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          filter: this.filter,
        };

        //  location.reload()

        self.table.items = [];

        axios
          .post(url, data, config)
          .then(function (response) {
            let table = response.data.table;
            self.table.items = table.items;
            self.table.headers = table.headers;
            self.table.dates = table.dates;
            self.performers = response.data.performers;
            self.update_loading("fetch_data", false);
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
    getProjects() {
      return this.$store.state.project.allowed_projects;
    },
  },
};
</script>

<style scoped>
table > tbody > tr > td:nth-child(1),
table > thead > tr > th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  min-width: 130px;
  z-index: 2;
}

table > tbody > tr > td:nth-child(2),
table > thead > tr > th:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 130px;
  min-width: 90px;
  z-index: 2;
}

table > tbody > tr > td:nth-child(3),
table > thead > tr > th:nth-child(3) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 220px;
  min-width: 90px;
  z-index: 2;
}
</style>
