import { set } from "../../utils/functions";

export default {
  data: () => ({
    registeredFilters: {},
    isTypesInitialized: false,
    FH_TYPES: {
      BOOLEAN: "boolean",
      ARRAY: "array",
      NUMBER: "number",
      STRING: "string",
      ANY: "any",
    },
  }),
  computed: {
    hasQueryParams() {
      try {
        const params = Object.fromEntries(
          new URLSearchParams(window.location.search)
        );
        const keys = Object.keys(params);
        return keys.length > 0;
      } catch (e) {
        console.error("Error while detecting if has query params", e);
      }
    },
  },
  methods: {
    getActiveFilters(filters) {
      if (!filters || typeof filters !== "object") return [];

      let result = [];

      Object.entries(filters).forEach(([key, value]) => {
        if (!this.__isEmptyValue(value)) result.push({ key, value });
      });

      return result;
    },
    getTypeOfValue(value) {
      const json = JSON.stringify(value);

      const emptyArray = JSON.stringify([]);
      const emptyRange = JSON.stringify([null, null]);
      const emptyRangeOfZeros = JSON.stringify([0, 0]);
      const { BOOLEAN, NUMBER, STRING, ARRAY } = this.FH_TYPES;

      if (value === null || value === undefined) return "any";

      if (json === emptyArray) return ARRAY;

      if (json === emptyRange) return ARRAY;

      if (json === emptyRangeOfZeros) return ARRAY;

      if (Array.isArray(value)) return ARRAY;

      if (value === "true" || value === "false") return BOOLEAN;

      if (!isNaN(value) && value !== "") return NUMBER;

      if (value || value === "") return STRING;

      return null;
    },
    initializeFiltersWatcher(path, options = {}) {
      return new Promise((resolve, reject) => {
        try {
          Object.keys(this[path]).forEach((filterKey) => {
            const initialValue = this[path][filterKey];

            let forceType;

            if (options && options.forceTypes && options.forceTypes[filterKey])
              forceType = options.forceTypes[filterKey];

            this.registeredFilters[filterKey] =
              forceType || this.getTypeOfValue(initialValue);
          });

          this.$watch(
            path,
            (value) => {
              this._$handleFiltersChange(value);
            },
            { deep: true, immediate: options?.immediate || false }
          );

          this.isTypesInitialized = true;

          resolve(path);
        } catch (e) {
          console.error("Error while initializing filters watcher.", e);
          reject();
        }
      });
    },
    collectParametersFromQuery(path) {
      try {
        if (!this.isTypesInitialized)
          throw new Error(
            'Error while collecting parameters from query. Object of registered filters are empty. Run "collectParametersFromQuery" only after "initializeFiltersWatcher"'
          );

        const params = Object.fromEntries(
          new URLSearchParams(window.location.search)
        );

        const keys = Object.keys(params);

        keys.forEach((key) => {
          const isArray = this.registeredFilters[key] === this.FH_TYPES.ARRAY;

          const value = this.__getValue(params[key], isArray);
          set(this, path + "." + key, value);
        });
      } catch (e) {
        console.error(e);
      }
    },
    /**
     * This method initializing filters types and collecting filters from query using initialized types for more valid data output
     * @param {string} path - name of Vue data parameter where filters will be initialized [Example: 'filters' -> this.filters]
     * @return {Promise<void>}
     */
    async parseQuery(path) {
      await this.initializeFiltersWatcher(path);
      this.collectParametersFromQuery(path);
    },
    _$handleFiltersChange(filters) {
      Object.keys(filters).forEach((key) => {
        this.__universalFilterHandler(filters[key], key);
      });
    },
    _$handleFilterChange(value, type) {
      const isArray = Array.isArray(value);
      if (isArray) {
        const isEmptyValue = this.__isEmptyRange(value);

        if (isEmptyValue) {
          value = null;
        } else {
          value = value.map((item) => {
            if (item === null) {
              item = "null";
            }
            return String(item);
          });
        }
      }

      this.__setQueryParams(type, value);
    },
    _$handleRangeChange(value, name, prefix = null, countZeros = true) {
      if (prefix) {
        this[prefix][name] = value;
      } else {
        this[name] = value;
      }
      if (this.__isEmptyRange(value, countZeros)) {
        value = null;
      }
      this.__setQueryParams(name, value);
    },
    _$isDefaultRange(data, countZeros) {
      const value = JSON.stringify(data);
      const isZeros = countZeros && JSON.stringify([0, 0]);
      const isNulls = JSON.stringify([null, null]);
      return value === isZeros || value === isNulls;
    },
    _$collectParamsTo(ctx, path, keysWithArrayType = []) {
      const params = Object.fromEntries(
        new URLSearchParams(window.location.search)
      );

      const keys = Object.keys(params);
      keys.forEach((key) => {
        const isArray = keysWithArrayType.includes(key);
        const value = this.__getValue(params[key], isArray);
        set(ctx, path + "." + key, value);
      });

      return keys.length > 0;
    },
    __universalFilterHandler(value, type) {
      if (this.__isEmptyValue(value)) return this.__setQueryParams(type, null);

      this.__setQueryParams(type, value);
    },
    __getValue(value, isArray) {
      // Nothing
      if (value === undefined || value === null) return null;

      // Array
      if (value.includes(",") || isArray) {
        const tmprarr = value.split(",");
        const isArrayOfNumbers = this.__isArrayOfNumbers(tmprarr);

        if (isArrayOfNumbers) return tmprarr.map((v) => Number(v));
        return tmprarr;
      }

      // Boolean
      if (value === "true" || value === "false") {
        return value === "true";
      }

      // Number
      if (!isNaN(Number(value))) {
        return Number(value);
      }

      // String
      return value;
    },
    __isArrayOfNumbers(items = []) {
      let tmprarr = items;

      if (typeof items === "string") tmprarr = items.split(",");

      return tmprarr.every((v) => !isNaN(v));
    },
    __isEmptyValue(value) {
      return (
        (Array.isArray(value) && value.length === 0) ||
        value === "" ||
        value === undefined ||
        value === null ||
        this.__isEmptyRange(value, true) ||
        (typeof value === "boolean" && !value)
      );
    },
    __isEmptyRange(data, countZeros) {
      const value = JSON.stringify(data);

      const isZeros = countZeros && JSON.stringify([0, 0]);
      const isNulls = JSON.stringify([null, null]);

      return value === isZeros || value === isNulls;
    },
    __setQueryParams(queryName, newValue) {
      const params = Object.fromEntries(
        new URLSearchParams(window.location.search.substr(1))
      );

      if (this.__isEmptyValue(newValue)) {
        delete params[queryName];
      } else {
        params[queryName] = newValue;
      }

      const isNoParams = Object.keys(params).length === 0;

      const queryString = isNoParams
        ? window.location.pathname
        : "?" + new URLSearchParams(params).toString();

      history.pushState(null, null, queryString);
    },
  },
};
