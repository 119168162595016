import axios from "axios";

export default {
    state: {
        loading: {countries: false, languages: false, niches: false, languages_dct: false},
        countries: typeof localStorage.getItem('reuse.countries.list') === 'string' && localStorage.getItem('reuse.countries.list') !== 'undefined' ? JSON.parse(localStorage.getItem('reuse.countries.list')) : [],
        languages: typeof localStorage.getItem('reuse.languages.list') === 'string' && localStorage.getItem('reuse.languages.list') !== 'undefined' ? JSON.parse(localStorage.getItem('reuse.languages.list')) : [],
        languages_dct: typeof localStorage.getItem('reuse.languages_dct.list') === 'string' && localStorage.getItem('reuse.languages_dct.list') !== 'undefined' ? JSON.parse(localStorage.getItem('reuse.languages_dct.list')) : [],
        niches: typeof localStorage.getItem('reuse.niches.list') === 'string' && localStorage.getItem('reuse.niches.list') !== 'undefined' ? JSON.parse(localStorage.getItem('reuse.niches.list')) : [],
        link_types: [],
        anchor_types: [],
    },
    actions: {
        fetch_anchor_types_list(ctx) {
            ctx.commit('updateReuseLoading', {
                type: 'anchor_types',
                value: true
            })

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': ctx.rootState.auth.token
                }
            }

            axios
                .get(`${ctx.rootState.server_url}/static/get-anchor-types`, config)
                .then((r) => {
                    ctx.commit('updateReuseList', {
                        type: 'anchor_types',
                        value: r.data.items
                    })
                })
                .finally(() => {
                    ctx.commit('updateReuseLoading', {
                        type: 'anchor_types',
                        value: false
                    })
                })
        },
        fetch_link_types_list(ctx, options) {
            ctx.commit('updateReuseLoading', {
                type: 'link_types',
                value: true
            })

            if ((options !== undefined && options.params === undefined) || options === undefined) {
                options = {
                    params: {}
                }
            }

            let config = {
                params: options.params,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': ctx.rootState.auth.token
                }
            }

            axios
                .get(`${ctx.rootState.server_url}/static/get-link-types`, config)
                .then((r) => {
                    ctx.commit('updateReuseList', {
                        type: 'link_types',
                        value: r.data.items
                    })
                })
                .finally(() => {
                    ctx.commit('updateReuseLoading', {
                        type: 'link_types',
                        value: false
                    })
                })
        },
        fetch_countries_list(ctx) {
            ctx.commit('updateReuseLoading', {
                type: 'countries',
                value: true
            })

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': ctx.rootState.auth.token
                }
            }

            axios
                .get(`${ctx.rootState.server_url}/static/get-countries`, config)
                .then((r) => {
                    let countries = r
                        .data
                        .map((v) => {
                            return {
                                id: v.id,
                                name: v.name,
                                iso_alpha_2: v.iso_alpha_2
                            }
                        })

                    ctx.commit('updateReuseList', {
                        type: 'countries',
                        value: countries
                    })
                })
                .finally(() => {
                    ctx.commit('updateReuseLoading', {
                        type: 'countries',
                        value: false
                    })
                })
        },
        fetch_languages_list(ctx) {
            ctx.commit('updateReuseLoading', {
                type: 'languages',
                value: true
            })

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': ctx.rootState.auth.token
                }
            }

            axios
                .get(`${ctx.rootState.server_url}/static/get-languages`, config)
                .then((r) => {
                    let languages = r
                        .data
                        .map((v) => {
                            return {
                                id: v.id,
                                name: v.country
                            }
                        })

                    ctx.commit('updateReuseList', {
                        type: 'languages',
                        value: languages
                    })
                })
                .finally(() => {
                    ctx.commit('updateReuseLoading', {
                        type: 'languages',
                        value: false
                    })
                })
        },
        fetch_languages_dct_list(ctx) {
            ctx.commit('updateReuseLoading', {
                type: 'languages_dct',
                value: true
            })

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': ctx.rootState.auth.token
                }
            }

            axios
                .get(`${ctx.rootState.server_url}/static/get-languages-dct`, config)
                .then((r) => {
                    let languages_dct = r
                        .data
                        .map((v) => {
                            return {
                                id: v.id,
                                name: v.name
                            }
                        })

                    ctx.commit('updateReuseList', {
                        type: 'languages_dct',
                        value: languages_dct
                    })
                })
                .finally(() => {
                    ctx.commit('updateReuseLoading', {
                        type: 'languages_dct',
                        value: false
                    })
                })
        },
        fetch_niches_list(ctx) {
            let niches = [
                {id: 1, name: 'essay'},
                {id: 2, name: 'casino'},
                {id: 3, name: 'porn'},
                {id: 4, name: 'replica'},
                {id: 5, name: 'review'},
                {id: 6, name: 'loan'},
                {id: 7, name: 'dating'},
                {id: 8, name: 'fortuneteller'},
                {id: 9, name: 'sample'},
                {id: 10, name: 'automotive'},
                {id: 11, name: 'amazon'},
                {id: 12, name: 'other'}
            ]

            ctx.commit('updateReuseList', {
                type: 'niches',
                value: niches
            })
        }
    },
    mutations: {
        updateReuseList(state, data) {
            localStorage.setItem(`reuse.${data.type}.list`, JSON.stringify(data.value))
            state[data.type] = data.value
        },
        updateReuseLoading(state, data) {
            state.loading[data.type] = data.value
        }
    }
}
