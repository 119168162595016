<template>
  <v-dialog v-model="dialog" width="620">
    <v-card class="styled-card--default">
      <v-card-title>
        Upload base keys
        <v-icon small right>mdi-upload</v-icon>
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div>
          <small>Keys</small>
        </div>
        <v-textarea
          v-model="keys"
          outlined
          no-resize
          :placeholder="'phrase<tab>volume<tab>kd\nphrase<tab>volume<tab>kd\nphrase<tab>volume<tab>kd'"
          hide-details
          rows="7"
        />
        <h4>.csv (phrase, volume, kd)</h4>
        <v-file-input
          v-model="importFile"
          dense
          outlined
          accept=".csv,.xls,.xlsx"
          prepend-icon="mdi-paperclip"
          label="Upload CSV/Excel"
          hide-details
        ></v-file-input>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-chip
          label
          class="flat-chip flat-chip--primary px-6"
          @click="uploadKeys"
          large
          :disabled="loading"
        >
          Upload
          <v-progress-circular
            v-if="loading"
            width="2"
            size="12"
            indeterminate
          />
        </v-chip>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../mixins/Dialog";
import Project from "../../mixins/Project";
import { parse } from "csv-parse";
import * as XLSX from "xlsx";
import axios from "axios";

export default {
  mixins: [Dialog, Project],
  data: () => ({
    keys: "",
    importFile: null,
    loading: false,
  }),
  methods: {
    async uploadKeys() {
      this.loading = true;

      try {
        let allRows = [];

        // Parse textarea input
        if (this.keys.trim()) {
          const parsedManualRows = this.parseTextarea();
          this.validateRows(parsedManualRows);
          allRows = allRows.concat(parsedManualRows);
        }

        // Parse file input
        if (this.importFile) {
          const parsedFileRows = await this.parseFile(this.importFile);
          const validatedFileRows = this.validateRows(parsedFileRows);
          allRows = allRows.concat(validatedFileRows);
        }

        if (allRows.length === 0) {
          throw new Error("No valid categories to import.");
        }

        // Send rows to API
        await this.sendRowsToAPI(allRows);

        this.$message.notification({
          title: "Success",
          text: "Keywords imported successfully",
          type: "success",
        });
      } catch (error) {
        console.error(error);
        alert("Error importing categories: " + error.message);
      } finally {
        this.loading = false;
        this.importFile = null;
      }
    },
    parseFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        if (file.name.endsWith(".csv")) {
          reader.onload = (e) => {
            const text = e.target.result;

            parse(
              text,
              {
                delimiter: [";", "\t", ","],
                trim: true,
                skip_empty_lines: true,
              },
              (err, output) => {
                if (err) {
                  reject(err);
                } else {
                  resolve(output);
                }
              }
            );
          };
        } else if (file.name.endsWith(".xls") || file.name.endsWith(".xlsx")) {
          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const rows = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
            resolve(rows);
          };
        } else {
          reject(new Error("Unsupported file format."));
        }

        reader.onerror = () => reject(new Error("Error reading file."));
        reader.readAsText(file);
      });
    },
    parseTextarea() {
      return this.keys
        .trim()
        .split("\n")
        .map((line) => line.split("\t").map((col) => col.trim()));
    },
    validateRows(rows) {
      if (!Array.isArray(rows) || rows.length === 0) {
        throw new Error("The file or input is empty or invalid.");
      }

      const validRows = rows.filter(
        (row) =>
          Array.isArray(row) &&
          row.length === 3 &&
          row.every((col) => col !== "")
      );

      if (validRows.length !== rows.length) {
        throw new Error(
          "Some rows are invalid. Ensure each row has exactly 3 columns with no empty values."
        );
      }

      return validRows;
    },
    async sendRowsToAPI(rows) {
      const taskId = this.$route.params.id;
      const url = `${this.$store.state.server_url}/semantic-tool/task/upload-keys/${taskId}`;
      const config = {
        headers: {
          Authorization: this.$store.state.auth.token,
        },
      };

      const chunkSize = 500;
      for (let i = 0; i < rows.length; i += chunkSize) {
        const chunk = rows.slice(i, i + chunkSize);
        const data = chunk.map(([phrase, volume, kd]) => ({
          phrase,
          volume,
          kd,
        }));

        try {
          await axios.post(
            url,
            {
              rows: data,
            },
            config
          );
        } catch (error) {
          console.error(`Failed to upload chunk starting at row ${i}:`, error);
          throw new Error(
            `Error uploading data chunk starting at row ${i + 1}`
          );
        }
      }
    },
  },
};
</script>
