<template>
  <v-form ref="formRef">
    <v-row>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="px-4 py-2 text-body-2">
            <b>Titles</b>
            <my-badge
              v-if="modelValue?.titles?.length"
              class="primary white--text ml-1"
            >
              {{ modelValue.titles.length }}
            </my-badge>
            <v-spacer />
            <v-btn
              class="text-normal"
              @click="handleAddItemToForm(titleTemplate, 'titles')"
            >
              Add Title
              <v-icon small right>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-4">
            <v-row v-if="!modelValue?.titles?.length">
              <v-col cols="12"> Press "Add Title" to add new Title </v-col>
            </v-row>
            <template v-else>
              <transition-group class="row" tag="div" name="slide-y">
                <v-col
                  v-for="item in modelValue?.titles"
                  :key="`title-${item.id}`"
                  cols="12"
                >
                  <v-row dense>
                    <v-col cols="fill">
                      <v-autocomplete
                        v-model="item.from"
                        :items="[]"
                        hide-details
                        dense
                        outlined
                        label="From"
                        placeholder="Select Status From"
                      />
                    </v-col>
                    <v-col cols="fill">
                      <v-autocomplete
                        v-model="item.to"
                        :items="[]"
                        hide-details
                        dense
                        outlined
                        label="To"
                        placeholder="Select Status From"
                      />
                    </v-col>
                    <v-col cols="fill">
                      <v-text-field
                        v-model="item.title"
                        hide-details
                        dense
                        outlined
                        label="Title"
                        placeholder="Write a title"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        icon
                        color="error"
                        @click="handleRemoveById('titles', item.id)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </transition-group>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="px-4 py-2 text-body-2">
            <b>Rechecks</b>
            <my-badge
              v-if="modelValue?.rechecks?.length"
              class="primary white--text ml-1"
            >
              {{ modelValue.rechecks.length }}
            </my-badge>
            <v-spacer />
            <v-btn
              class="text-normal"
              @click="handleAddItemToForm(recheckTemplate, 'rechecks')"
            >
              Add Recheck
              <v-icon small right>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-4">
            <v-row v-if="!modelValue?.rechecks?.length">
              <v-col cols="12"> Press "Add Recheck" to add new Recheck </v-col>
            </v-row>
            <template v-else>
              <transition-group tag="div" class="row" name="slide-y">
                <v-col
                  v-for="item in modelValue?.rechecks"
                  :key="`title-${item.id}`"
                  cols="12"
                >
                  <v-row dense>
                    <v-col cols="fill">
                      <v-autocomplete
                        v-model="item.status"
                        :items="[]"
                        hide-details
                        dense
                        outlined
                        label="Status"
                        placeholder="Select status of recheck"
                      />
                    </v-col>
                    <v-col cols="fill">
                      <v-autocomplete
                        v-model="item.performer"
                        :items="[]"
                        hide-details
                        dense
                        outlined
                        label="Performer"
                        placeholder="Select recheck performer"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        icon
                        color="error"
                        @click="handleRemoveById('rechecks', item.id)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </transition-group>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="px-4 py-2 text-body-2">
            <b>Approves</b>
            <my-badge
              v-if="modelValue?.approves?.length"
              class="primary white--text ml-1"
            >
              {{ modelValue.approves.length }}
            </my-badge>
            <v-spacer />
            <v-btn
              class="text-normal"
              @click="handleAddItemToForm(approveTemplate, 'approves')"
            >
              Add Approve
              <v-icon small right>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-4">
            <v-row v-if="!modelValue?.approves?.length">
              <v-col cols="12"> Press "Add Approve" to add new Approve </v-col>
            </v-row>
            <template v-else>
              <transition-group tag="div" class="row" name="slide-y">
                <v-col
                  v-for="item in modelValue?.approves"
                  :key="`title-${item.id}`"
                  cols="12"
                >
                  <v-row dense>
                    <v-col cols="fill">
                      <v-autocomplete
                        v-model="item.status"
                        :items="[]"
                        hide-details
                        dense
                        outlined
                        label="Status"
                        placeholder="Select status of recheck"
                      />
                    </v-col>
                    <v-col cols="fill">
                      <v-autocomplete
                        v-model="item.performer"
                        :items="[]"
                        hide-details
                        dense
                        outlined
                        label="Performer"
                        placeholder="Select recheck performer"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        icon
                        color="error"
                        @click="handleRemoveById('approves', item.id)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </transition-group>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="px-4 py-2 text-body-2">
            <b>Fields</b>
            <my-badge
              v-if="modelValue?.fields?.length"
              class="primary white--text ml-1"
            >
              {{ modelValue.fields.length }}
            </my-badge>
            <v-spacer />
            <v-btn
              class="text-normal"
              @click="handleAddItemToForm(approveTemplate, 'fields')"
            >
              Add Field
              <v-icon small right>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-4">
            <v-row v-if="!modelValue?.fields?.length">
              <v-col cols="12"> Press "Add Fields" to add new Field </v-col>
            </v-row>
            <template v-else>
              <transition-group tag="div" class="row" name="slide-y">
                <v-col
                  v-for="item in modelValue?.fields"
                  :key="`title-${item.id}`"
                  cols="12"
                >
                  <v-row dense>
                    <v-col cols="fill">
                      <v-row dense>
                        <v-col cols="4">
                          <v-autocomplete
                            v-model="item.field"
                            :items="[]"
                            hide-details
                            dense
                            outlined
                            label="Field"
                            placeholder="Select type of Field"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-autocomplete
                            v-model="item.rules"
                            :items="[]"
                            multiple
                            hide-details
                            dense
                            outlined
                            label="Rules"
                            placeholder="Select fields rules"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="item.sortBy"
                            hide-details
                            dense
                            outlined
                            label="Sort by"
                            placeholder="Enter number"
                            type="number"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-autocomplete
                            v-model="item.position"
                            :items="[]"
                            multiple
                            hide-details
                            dense
                            outlined
                            label="Position"
                            placeholder="Select fields position"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-autocomplete
                            v-model="item.performer"
                            :items="[]"
                            multiple
                            hide-details
                            dense
                            outlined
                            label="Performer"
                            placeholder="Select fields performer"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="auto"
                      class="d-flex justify-center align-center"
                    >
                      <v-btn
                        icon
                        color="error"
                        @click="handleRemoveById('fields', item.id)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </transition-group>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { uid } from "../../../../utils/functions";
import MyBadge from "../../../UI/MyBadge.vue";

export default {
  components: { MyBadge },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    titleTemplate: {
      from: null,
      to: null,
      title: null,
    },
    recheckTemplate: {
      status: null,
      performer: null,
    },
    approveTemplate: {
      status: null,
      performer: null,
    },
    fieldTemplate: {
      field: null,
      rules: [],
      sortBy: null,
      position: null,
      performer: null,
    },
  }),

  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    handleRemoveById(target, id) {
      try {
        this.modelValue[target] = this.modelValue[target]?.filter(
          (item) => item.id !== id
        );
      } catch (e) {
        console.error("Cant remove item by id.", e);
      }
    },
    handleAddItemToForm(template, key) {
      if (this.modelValue?.[key] === undefined)
        this.$set(this.modelValue, key, []);

      if (!Array.isArray(this.modelValue[key]))
        return console.error(
          `Cant add new ${key} because form.${key} are not Array.`
        );

      this.modelValue[key].push({ ...template, id: uid() });
    },
  },
};
</script>
