<template>
  <div class="text-center" @click="handleShow">
    <div
      :class="`colored-td colored-td--inline colored-td--clickable ${getBgColor}`"
    >
      <template v-if="forceValue">
        {{ forceValue }}
      </template>
      <template v-else>
        {{ data?.[valueKey] !== undefined ? data?.[valueKey] : data }}
      </template>
    </div>
  </div>
</template>

<script>
const COLORS = {
  default: "transparent",
};

/*eslint-disable*/
export default {
  props: {
    data: {},
    content: {},
    name: {},
    forceValue: {
      type: [String, Number],
      default: null,
    },
    valueKey: {
      type: String,
      default: "percent",
    },
  },
  data: () => ({
    listOfDetailsCols: {
      content_score: { name: "Content Score" },
      total_words: { name: "Total Words" },
      total_sentences: { name: "Total Sentences" },
      average_sentence_length: { name: "Average sentence length" },
      semantic_vocabulary: { name: "Semantic Vocabulary" },
      vocabulary: { name: "Vocabulary" },
      water_index: { name: "Water Index" },
      zipfs_law: { name: "Naturalness" },
      classical_nausea_index: { name: "Classical Nausea Index" },
      dale_chall_score: { name: "Dale–Chall Readability" },
      spache: { name: "Spache Readability" },
      flesch: { name: "Flesch Readability" },
      automated_index: { name: "Automated readability index Readabilit" },
    },
    colors: {
      total_words_editor: (value) => {
        const data = value?.percernt;

        if (data === undefined || data === null) return "";

        if (data < 60) return "red";
        if (data >= 60 && data <= 70) return "orange";
        if (data > 70) return COLORS.default;

        return COLORS.default;
      },
      cb3_overused: (value, ctx) => {
        const data = ctx?.cb3?.overused_percent;

        if (data === undefined || data === null) return "";

        if (data > 30) return "red";
        if (data >= 15 && data <= 30) return "orange";
        if (data < 15) return COLORS.default;

        return COLORS.default;
      },
      cb3_required: (value, ctx) => {
        const data = ctx?.cb3?.required_percent;

        if (data === undefined || data === null) return "";

        if (data < 60) return "red";
        if (data >= 60 && data <= 75) return "orange";
        if (data > 75) return COLORS.default;

        return COLORS.default;
      },
      cb3_required_not_used: (value, ctx) => {
        const data = ctx?.cb3?.required_not_used_percent;

        if (data === undefined || data === null) return "";

        if (data > 35) return "red";
        if (data >= 25 && data <= 35) return "orange";
        if (data < 25) return COLORS.default;

        return COLORS.default;
      },
      cb3_required_not_in_target: (value, ctx) => {
        const data = ctx?.cb3?.required_not_in_target_percent;

        if (data === undefined || data === null) return "";

        if (data > 35) return "red";
        if (data >= 25 && data <= 35) return "orange";
        if (data < 25) return COLORS.default;

        return COLORS.default;
      },
      cb3_recommended: (value, ctx) => {
        const data = ctx?.cb3?.recommended_percent;

        if (data === undefined || data === null) return "";

        if (data < 50) return "red";
        if (data >= 50 && data <= 65) return "orange";
        if (data > 65) return COLORS.default;

        return COLORS.default;
      },
      cb3_recommended_not_used: (value, ctx) => {
        const data = ctx?.cb3?.recommended_not_used_percent;

        if (data === undefined || data === null) return "";

        if (data > 45) return "red";
        if (data >= 35 && data <= 45) return "orange";
        if (data < 35) return COLORS.default;

        return COLORS.default;
      },
      cb3_recommended_not_in_target: (value, ctx) => {
        const data = ctx?.cb3?.recommended_not_in_target_percent;

        if (data === undefined || data === null) return "";

        if (data > 45) return "red";
        if (data >= 35 && data <= 45) return "orange";
        if (data < 35) return COLORS.default;

        return COLORS.default;
      },
      cb3_used: (value, ctx) => {
        const data = ctx?.cb3?.used_percent;

        if (data === undefined || data === null) return "";

        if (data < 60) return "red";
        if (data >= 60 && data <= 75) return "orange";
        if (data > 75) return COLORS.default;

        return COLORS.default;
      },
      cb3_ai_score: (value, ctx) => {
        const data = value;

        if (data === undefined || data === null) return "";

        if (data < 50) return "red";
        if (data >= 50 && data <= 75) return "orange";
        if (data > 75) return COLORS.default;

        return COLORS.default;
      },
      flesch_editor: (value, ctx) => {
        const data = value?.percent;

        if (data === undefined || data === null) return "";

        if (data > -15 || data > 20) return "red";
        if (data < -10 && data >= -15) return "orange";
        if (data >= -10 && data < 20) return COLORS.default;

        return COLORS.default;
      },
      water_index_editor: (value, ctx) => {
        const data = value?.percent;

        if (data === undefined || data === null) return "";

        if (data > 15) return "red";
        if (data >= 6 && data <= 15) return "orange";
        if (data < 6) return COLORS.default;

        return COLORS.default;
      },
      content_score: (value, ctx) => {
        const data = value?.overall;

        if (data === undefined || data === null) return "";

        if (data <= 20) return "red";
        if (data > 20 && data <= 50) return "orange";
        if (data > 50 && data <= 80) return "yellow";
        if (data > 80) return "green";

        return "transparent";
      },
    },
  }),
  computed: {
    getBgColor() {
      try {
        const isPositive = ["water_index"].includes(this.name);
        const colored = [
          "total_words_editor",
          "cb3_overused",
          "cb3_required",
          "cb3_required_not_used",
          "cb3_required_not_in_target",
          "cb3_recommended",
          "cb3_recommended_not_used",
          "cb3_recommended_not_in_target",
          "cb3_used",
          "cb3_ai_score",
          "flesch_editor",
          "water_index_editor",
          "content_score",
        ];

        const isFlex = ["flesch", "average_sentence_length"].includes(
          this.name
        );

        const value = this.data?.percent;

        if (isFlex) {
          if (value >= 6 && value <= 15) return "yellow";
          if (value >= 16) return "red";
          if (value <= -6 && value >= -15) return "yellow";
          if (value <= -16) return "red";

          return "";
        }

        if (isPositive) {
          if (value >= 6 && value <= 15) return "yellow";
          if (value >= 16) return "red";

          return "";
        }

        if (colored.includes(this.name))
          return this.colors[this.name](this.data, this.content);

        if (value <= -30 && value >= -10) return "yellow";
        if (value <= -30) return "red";

        return "";
      } catch {
        return "";
      }
    },
  },
  methods: {
    handleShow() {
      try {
        const data = {};
        const deviation = {};

        Object.keys(this.listOfDetailsCols).forEach((key) => {
          data[key] = this.content?.[key].value;
          deviation[key] = this.content?.[key]?.percent;
        });

        const amountItems = [
          {
            url: "",
            amount: this.data?.percent,
            ...deviation,
          },
          {
            url: this.content.url,
            amount: this.data.items[0],
            ...data,
          },
        ];

        this.data.items.forEach((item, idx) => {
          if (idx === 0) return;

          const currentUrl = this.content.urls[idx - 1];

          const data = {};

          Object.keys(this.listOfDetailsCols).forEach((key) => {
            data[key] = this.content?.[key].items[idx];
          });

          amountItems.push({
            url: currentUrl,
            amount: this.data.items[idx],
            ...data,
          });
        });

        this.$emit("show", { amountItems });
      } catch {
        return "";
      }
    },
  },
};
</script>
