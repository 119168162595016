<template>
  <v-container fluid style="padding-bottom: 200px" class="lb-4dx-dashboard">
    <v-row>
      <v-col cols="12">
        <h2>4DX LB Main Dashboard</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
      <v-col cols="12">
        <v-form ref="formRef">
          <v-row dense>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <smart-autocomplete
                :model-value="filters.sections"
                label="Select sections"
                @update:modelValue="filters.sections = $event"
                :items="filtersData.sections"
                :rules="[(v) => !!v?.length || 'Select section']"
              />
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.executive"
                :items="filtersData.executive"
                label="Executive"
                multiple
                hide-details
                clearable
                dense
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.link_type"
                label="Link Type"
                :items="filtersData.link_type"
                hide-details
                multiple
                dense
                clearable
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.project_status"
                :items="filtersData.project_status"
                label="Project Status"
                hide-details
                clearable
                multiple
                dense
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.niche"
                :items="filtersData.niche"
                label="Niche"
                hide-details
                clearable
                dense
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.by_seo_team_project"
                :items="filtersData.seo_team_projects"
                label="By SEO Team's projects"
                hide-details
                clearable
                multiple
                dense
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.main_seo"
                :items="filtersData.main_seo"
                label="Main SEO"
                hide-details
                multiple
                clearable
                dense
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.project"
                :items="filtersData.project"
                label="Project"
                hide-details
                clearable
                multiple
                dense
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="fill" class="d-flex justify-end">
              <v-btn
                width="180"
                class="text-normal"
                color="primary"
                @click="handleFetchSections"
                :loading="loadings.sections.length > 0"
                >Get Data</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col v-if="loadings.all" cols="12" class="d-flex justify-center">
        <div>
          <v-card width="400" class="shadow-e1-bordered" rounded="lg">
            <v-card-title class="d-flex justify-center">
              <v-progress-circular indeterminate size="34" width="3" />
            </v-card-title>
            <v-card-text class="text-center">
              Loading Sections. Sections will appear when all sections have
              finished loading.
            </v-card-text>
          </v-card>
        </div>
      </v-col>
      <v-col v-if="isAllowedRender" cols="12">
        <v-row>
          <template v-for="(group, idx) in Object.values(sections)">
            <v-col
              v-if="group.items && group.items.length > 0"
              cols="12"
              :key="group.name + idx"
            >
              <v-row>
                <v-col cols="12">
                  <h2>{{ group.name }}</h2>
                </v-col>
                <v-col
                  v-for="(section, idx) in getSortedByPriority(group.items)"
                  cols="12"
                  :key="idx"
                  ref="sectionRefs"
                >
                  <main4dx-section-factory :data="section" />
                </v-col>
              </v-row>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Main4dxSectionFactory from "../Dashboard4DX/components/Main4dxSectionFactory.vue";
import service from "../../plugins/service";
import FiltersHandling from "../../mixins/components/FiltersHandling";
import { deepClone } from "../../utils/functions";
import SmartAutocomplete from "../UI/SmartAutocomplete.vue";
import LazyDomElementRenderingMixin from "../../mixins/LazyDomElementRenderingMixin";

export default {
  components: { SmartAutocomplete, Main4dxSectionFactory },
  mixins: [FiltersHandling, LazyDomElementRenderingMixin],
  provide() {
    return {
      filters: () => this.filters,
    };
  },
  data: () => ({
    filters: {
      sections: [],
      view_dimension: ["summary"],
      executive: [],
      link_type: [],
      niche: 2,
      by_seo_team_project: [],
      main_seo: [],
      project: [],
      project_status: [1],
    },
    sections: {
      other: {
        name: "Other",
        items: [],
      },
    },
    loadings: {
      section: false,
      sections: [],
      all: false,
    },
    isAllowedRender: false,
    filtersData: {
      executive: [],
      seo_team_projects: [],
      main_seo: [],
      project: [],
      niche: [],
      view_dimension: [],
      sections: [],
      link_type: [],
      project_status: [],
    },
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "4DX",
        disabled: true,
      },
      {
        text: "LB Main Dashboard",
        disabled: true,
      },
    ],
  }),
  async mounted() {
    await this.parseQuery("filters");

    const haveParams = !!Object.keys(this.$route.query).length;

    if (haveParams) this.handleFetchSections();

    this.fetchFilters();
  },
  methods: {
    getSortedByPriority(items) {
      const tmpr = deepClone(items);

      return tmpr.sort((a, b) => {
        if (a.priority < b.priority) return 1;
        if (a.priority > b.priority) return -1;
        return 0;
      });
    },
    async fetchFilters() {
      try {
        const filters = [
          "executive",
          "project",
          "niche",
          "view_dimension",
          "seo_team_projects",
          "sections",
          "link_type",
          "project_status",
          "main_seo",
        ];
        const payload = {
          type: "/fdx/lb/index",
          take: filters,
        };
        this.loadings.filters = true;
        const resp = await this.$store.dispatch("global/getFilters", payload);
        this.loadings.filters = false;

        if (resp) {
          this.filtersData = { ...this.filtersData, ...resp };
        }
      } catch (e) {
        console.error(e);
        this.loadings.filters = false;
      }
    },
    clearSections() {
      this.sections = {
        other: {
          name: "Other",
          items: [],
        },
      };
    },
    handleFetchSections() {
      if (!this.$refs.formRef.validate()) return;

      this.clearSections();

      if (!this.filters.sections.length > 0) {
        this.$message.notification({
          title: "Validation error",
          text: "Select sections to load.",
          type: "error",
        });
        return;
      }

      this.isAllowedRender = false;
      this.loadings.all = true;

      const promises = [];

      if (this.filters.sections.length > 0) {
        this.filters.sections.forEach((saectionName) => {
          promises.push(this.fetchSection(saectionName));
        });
      }

      Promise.allSettled(promises).then(() => {
        this.isAllowedRender = true;
        this.loadings.all = false;

        this.$nextTick(() => {
          this.initializeLazyRender(this.$refs.sectionRefs);
        });
      });
    },
    sortSectionToGroups(sectionData) {
      if (!sectionData.group) this.sections.other.items.push(sectionData);

      if (this.sections[sectionData.group] === undefined) {
        this.sections[sectionData.group] = {
          name: sectionData.group,
          items: [],
        };
      }

      const idx = this.sections[sectionData.group].items.findIndex(
        (v) => v.title === sectionData.title
      );

      if (idx !== -1) {
        return (this.sections[sectionData.group].items[idx] = sectionData);
      }
      this.sections[sectionData.group].items.push(sectionData);
    },
    async fetchSection(sectionType) {
      try {
        const url = "/fdx/lb/index?type=" + sectionType;

        this.loadings.sections.push(sectionType);

        const configs = {
          params: this.filters,
        };

        const resp = await service.get(url, true, configs);

        if (resp) {
          if (Array.isArray(resp.data)) {
            resp.data.forEach((sectionData) => {
              this.sortSectionToGroups(sectionData);
            });
            return;
          }

          this.sortSectionToGroups(resp.data);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.sections = this.loadings.sections.filter(
          (v) => v !== sectionType
        );
      }
    },
  },
};
</script>

<style lang="scss">
.lb-4dx-dashboard {
  .table-with-dividers {
    tbody {
      tr.divider {
        background-color: rgba(155, 155, 155, 0.15);
        font-weight: 600;
        &:hover {
          background-color: rgba(155, 155, 155, 0.3) !important;
        }
      }
      tr.divider-border {
        td {
          border-bottom: 2px solid rgba(155, 155, 155, 0.5) !important;
        }
      }
    }
  }
}
</style>
