<template>
  <div>
    <v-dialog
      :retain-focus="false"
      v-model="inputVal"
      max-width="1200"
      content-class="remove-dialog-shadow"
      scrollable
    >
      <v-card flat outlined rounded="lg">
        <template>
          <div v-if="upload.alert.shown">
            <v-alert
              v-for="message in upload.alert.messages.list"
              :key="message"
              outlined
              :type="upload.alert.type"
              prominent
              border="left"
              dismissible
            >
              {{ upload.alert.messages.name }}: {{ message }}
            </v-alert>
          </div>
        </template>

        <v-card-title class="text-body-2">
          Loader Anchor Plan
          <v-spacer />
          <v-icon @click="inputVal = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="8">
              <v-card flat outlined rounded="lg">
                <v-card-title class="text-body-2">
                  Import from text
                </v-card-title>
                <v-divider />
                <v-card-text class="pt-5">
                  <v-row dense>
                    <v-col cols="12">
                      1. Put the columns to import in the order in which you
                      make the data</v-col
                    >
                    <v-col cols="12">
                      <v-text-field
                        v-model="upload.text.headers"
                        outlined
                        dense
                        :placeholder="'acceptor_url;anchor;date_start;date_finish;link_type;link_task'"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">2. Insert data (without headers)</v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="upload.text.rows"
                        outlined
                        :placeholder="'acceptor_url <tab> anchor <tab> date_start <tab> date_finish <tab> link_type <tab> link_task\nacceptor_url <tab> anchor <tab> date_start <tab> date_finish <tab> link_type <tab> link_task'"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions class="justify-center">
                  <v-btn
                    class="text-normal px-6"
                    color="success"
                    large
                    @click="upload_data(1)"
                    >Upload
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card flat outlined rounded="lg">
                <v-card-title class="text-body-2">
                  Import from file
                  <v-spacer />
                  <v-chip
                    label
                    outlined
                    href="/file/AnchorsPlanExample.csv"
                    target="_blank"
                  >
                    Example
                    <v-icon small right>mdi-download</v-icon>
                  </v-chip>
                </v-card-title>
                <v-divider />
                <v-card-text class="pt-5">
                  <v-row dense>
                    <v-col cols="12">
                      <v-file-input
                        v-model="upload.file.names"
                        color="deep-purple accent-4"
                        counter
                        dense
                        label="File input"
                        multiple
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        accept=".csv"
                        outlined
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="overline grey--text text--darken-3 mx-2"
                          >
                            +{{ upload.file.names.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions class="justify-center">
                  <v-btn
                    class="text-normal px-6"
                    color="success"
                    large
                    @click="upload_data(2)"
                    >Upload
                  </v-btn>
                </v-card-actions>
              </v-card>

              <v-card class="mt-4" flat outlined rounded="lg">
                <v-card-title class="text-body-2">
                  Allowed columns
                </v-card-title>
                <v-divider />
                <v-card-text class="pt-5">
                  <div><b>R</b> - required , <b>E</b> - emptiness</div>
                  <div><b>acceptor url [R]</b> - https://site.com/page</div>
                  <div><b>anchor [R]</b> - write my essay</div>
                  <div><b>date_start [R]</b> - 2014-04-05 *</div>
                  <div><b>date_finish [R]</b> - 2014-04-09 *</div>
                  <div><b>link_type [R]</b> - profile</div>
                  <div><b>donor_domain [E]</b> - example.com</div>
                  <div><b>task_name [E]</b> - test</div>
                  <div><b>donor_example [E]</b> - https://site.com/page</div>
                  <div>
                    <b>link_task [R]</b> - https://ra.worksection.com/priority/
                  </div>
                  <div><b>department [E]</b> - ha</div>
                  <div><b>gpm_id [E]</b> - 123</div>
                  <div><b>dofollow [E]</b> - 1/0</div>
                  <div><b>performer [E]</b> - vadym.lavrenchuk@boosta.co</div>
                  <div>
                    <b>*</b> mm/dd/yyyy, mm/dd/yy, yyyy/mm/dd, dd-mm-yyyy,
                    yy-mm-dd, yyyy-mm-dd
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UploadAnchorPlan",
  metaInfo: {
    title: "Upload Anchor Plan - LTT - SI",
  },
  props: ["value", "level", "task_id"],
  data() {
    return {
      loadings: {},
      upload: {
        modal: false,
        text: {
          headers:
            "acceptor_url;anchor;date_start;date_finish;link_type;link_task",
          rows: "",
        },
        file: {
          names: [],
        },
        alert: {
          messages: {
            list: [],
            name: "",
            type: 0,
          },
          shown: false,
          type: "info",
        },
      },
      date_modal: false,
      status: [],
    };
  },
  methods: {
    upload_data(type) {
      let self = this;
      self.update_loading("upload_data", true);

      self.upload.alert.messages = [];
      self.upload.alert.type = "";
      self.upload.alert.shown = false;

      let url = `${self.$store.state.server_url}/anchor-plan/upload-tasks/${self.id_project}/${self.level}`;
      let data = {};
      if (type === 1) {
        data = {
          type: type,
          headers: this.upload.text.headers,
          data: this.upload.text.rows,
          task_id: self.task_id,
        };
      } else {
        data = new FormData();
        data.append("file", self.upload.file.names[0]);
        data.append("type", type);
        data.append("task_id", self.task_id);
      }

      let config = {
        headers: {
          "Content-Type":
            type === 1 ? "application/json" : "multipart/form-data",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .post(url, data, config)
        .then(function (response) {
          self.upload.alert.messages = response.data.messages;
          self.upload.alert.type = response.data.type;
          self.upload.alert.shown = true;
          self.update_loading("upload_data", false);
          self.upload.modal = false;
          self.upload.file.names = [];
        })
        .catch((error) => {
          self.upload.alert.messages = {
            list: [error],
            name: "Axios",
            type: 0,
          };
          self.upload.alert.type = "error";
          self.upload.alert.shown = true;
          self.upload.modal = false;
          self.upload.file.names = [];

          self.update_loading("upload_data", false);
        });
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
  created() {},
};
</script>

<style scoped></style>
