<template>
  <div>
    <v-container fluid v-if="isset_active_project">
      <v-row>
        <v-col>
          <page-header
            title="Anchor plan"
            :breadcrumbs="[
              { text: 'Home', to: '/' },
              { text: 'Anchor plan', disabled: true },
              { text: 'Tasks', disabled: true },
            ]"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="12">
          <v-card flat outlined rounded="lg">
            <v-card-title style="gap: 0.25rem">
              <v-btn @click="upload.modal = true" class="text-normal">
                Upload
                <v-icon small right>mdi-upload</v-icon>
              </v-btn>
              <v-menu
                transition="slide-x-transition"
                :close-on-content-click="false"
                max-width="380"
                nudge-bottom="40"
                content-class="remove-dialog-shadow"
              >
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" class="text-normal" v-on="on">
                    <v-icon small left>mdi-filter</v-icon>
                    Filters
                    <my-badge
                      v-if="getActiveFilters(filter)?.length - 1"
                      class="success white--text ml-1"
                    >
                      {{ getActiveFilters(filter).length - 1 }}
                    </my-badge>
                    <v-icon small right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-card flat outlined rounded="lg">
                  <v-card-title class="text-body-2">
                    <b>Filters</b>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <smart-date-filter
                          :model-value="filter.date_range"
                          @update:modelValue="handleDateChange"
                      /></v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="filter.status"
                          :items="status"
                          item-text="name"
                          item-value="id"
                          label="Status"
                          chips
                          hide-details
                          dense
                          outlined
                          multiple
                          deletable-chips
                          clearable
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="filter.department"
                          :items="departments"
                          item-text="name"
                          item-value="id"
                          label="Department"
                          chips
                          dense
                          outlined
                          hide-details
                          multiple
                          deletable-chips
                          clearable
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions class="justify-center">
                    <v-btn
                      class="px-6 text-normal"
                      large
                      @click="fetch_data"
                      color="primary"
                    >
                      Get Data
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-card-title>
            <v-divider />
            <v-card-text class="px-0 pb-2">
              <TableAnchorPlanIndex
                :type="1"
                :loading="loadings.table"
                :status="status"
                :items="table.items"
                :performers="performers"
                @fetch_data="
                  () => {
                    fetch_data();
                  }
                "
              ></TableAnchorPlanIndex>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
      dark
      v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn
            color="primary"
            class="text-normal"
            dark
            @click="$store.dispatch('toggle_select_project_menu')"
            >Select project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <upload-anchor-plan
      v-if="upload.modal === true"
      v-model="upload.modal"
      level="1"
    ></upload-anchor-plan>
  </div>
</template>

<script>
import UploadAnchorPlan from "@/components/AnchorPlan/Child/UploadAnchorPlan";
import SmartDateFilter from "@/components/UI/SmartDateFilter";
import service from "@/plugins/service";
import Notification from "@/mixins/Notification";
import FiltersHandling from "../../mixins/components/FiltersHandling";
import PageHeader from "../UI/PageHeader.vue";
import TableAnchorPlanIndex from "./Child/TableAnchorPlanIndex.vue";
import MyBadge from "../UI/MyBadge.vue";

export default {
  name: "AnchorPlanIndex",
  components: {
    MyBadge,
    PageHeader,
    SmartDateFilter,
    TableAnchorPlanIndex,
    UploadAnchorPlan,
  },
  mixins: [Notification, FiltersHandling],
  metaInfo: {
    title: "Anchor Plan Tasks - LTT - SI",
  },
  data() {
    return {
      loadings: {
        table: false,
      },
      upload: {
        modal: false,
      },
      performers: [],
      date_modal: false,
      status: [],
      departments: [
        { name: "la", id: "la" },
        { name: "ha", id: "ha" },
        { name: "fl", id: "fl" },
      ],
      table: {
        items: [],
      },
      filter: {
        date_range: [],
        status: [1, 2, 3, 4, 6],
        department: [],
      },
    };
  },
  methods: {
    handleDateChange(event) {
      this.filter.date_range = event;
      this._$handleFilterChange(event, "date_range");
    },
    async fetch_data() {
      try {
        if (!this.id_project) {
          this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });
          return this.$store.dispatch("toggle_select_project_menu");
        }

        const url = `/anchor-plan/tier/tasks/${this.id_project}/1`;
        const payload = {
          filter: this.filter,
        };

        this.loadings.table = true;

        const response = await service.post(url, payload);

        this.table.items = response.data.tasks;
      } catch (e) {
        console.error("Error while loading main data.", e);
      } finally {
        this.loadings.table = false;
      }
    },
    async fetch_status() {
      try {
        const url = "/anchor-plan/tasks/list/status";

        const response = await service.post(url, {});

        this.status = response.data;
      } catch (e) {
        console.error("Error while loading status.", e);
      } finally {
        //
      }
    },
    async fetch_performers() {
      try {
        //
        const url = "/anchor-plan/tasks/list/performers";

        const response = await service.post(url, {});

        this.performers = response.data;
        this.performers.push({ id: null, name: "not selected" });
      } catch (e) {
        console.error("Error while loading performers data.", e);
      } finally {
        //
      }
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
  async mounted() {
    await this.parseQuery("filter");

    this.fetch_status();
    this.fetch_performers();

    this.fetch_data();

    this.$watch("id_project", (newValue) => {
      if (newValue) this.fetch_data();
    });
  },
};
</script>

<style scoped></style>
