<template>
  <div>
    <template v-if="!loading">
      <v-tooltip v-if="data.waiting_time !== undefined" bottom>
        <template #activator="{ on }">
          <v-chip style="border-style: dashed" label outlined v-on="on">
            <v-icon small left>mdi-clock-outline</v-icon>
            <b>{{ data.waiting_time }}</b>
          </v-chip>
        </template>
        <div>Waiting time</div>
      </v-tooltip>
      <v-tooltip v-if="data.queue_total !== undefined" bottom>
        <template #activator="{ on }">
          <v-chip
            style="border-style: dashed"
            label
            outlined
            class="mx-2"
            v-on="on"
          >
            <v-icon small left>mdi-human-queue</v-icon>
            <b>{{ data.queue_total }}</b>
          </v-chip>
        </template>
        <div>Queue total</div>
      </v-tooltip>
      <v-tooltip v-if="data.for_last_hour !== undefined" bottom>
        <template #activator="{ on }">
          <v-chip style="border-style: dashed" label outlined v-on="on">
            <v-icon small left>mdi-clock-end</v-icon>
            <b>{{ data.for_last_hour }}</b>
          </v-chip>
        </template>
        <div>For last hour</div>
      </v-tooltip>
      <a
        :href="`https://new.spiderlink.pro/dashboards/link-database-management/processing?domain=${data.domain_id}`"
        target="_blank"
        title="Link database management"
      >
        <v-chip label outlined class="ml-2" @click="() => {}">
          LDM <v-icon small right>mdi-open-in-new</v-icon>
        </v-chip>
      </a>
    </template>
    <template v-else>
      <div class="d-flex">
        <div class="skeleton loading" style="height: 32px; width: 56px"></div>
        <div
          class="skeleton loading mx-2"
          style="height: 32px; width: 56px"
        ></div>
        <div class="skeleton loading" style="height: 32px; width: 56px"></div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
