<template>
  <div style="padding-bottom: 200px">
    <v-container fluid v-if="isset_active_project">
      <v-row>
        <v-col cols="12">
          <h2>Conversion 3</h2>
          <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
        </v-col>
        <v-col cols="12">
          <v-card flat outlined rounded="lg" v-if="isset_active_project">
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                hide-details
                :disabled="table.items && table.items.length === 0"
                outlined
                style="max-width: 320px"
                dense
                class="align-end"
              ></v-text-field>
              <v-spacer />
              <div class="space-x d-flex align-center">
                <v-chip-group
                  v-model="settings"
                  active-class="primary--text"
                  column
                >
                  <v-chip
                    title="In the process of calculating the colour for the gradient in the table, consider the zeros"
                    filter
                    label
                    class="rounded-lg"
                    :disabled="
                      Array.isArray(table.items) && table.items.length === 0
                    "
                    outlined
                  >
                    Count zeros
                    <v-icon right>mdi-calculator-variant-outline</v-icon>
                  </v-chip>
                </v-chip-group>
                <v-menu
                  transition="slide-x-transition"
                  :close-on-content-click="false"
                  min-width="320"
                  max-width="320"
                  content-class="remove-dialog-shadow pa-1"
                >
                  <template v-slot:activator="{ on }">
                    <v-chip label class="rouned-lg" color="primary" v-on="on">
                      <v-icon small left>mdi-filter</v-icon> Filters
                      <v-icon small right>mdi-chevron-down</v-icon>
                    </v-chip>
                  </template>
                  <v-card rounded="lg" class="shadow-e1-bordered">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <smart-date-filter
                            :model-value="filter.date_range"
                            @update:modelValue="filter.date_range = $event"
                            filled
                          />
                        </v-col>
                        <v-col>
                          <v-autocomplete
                            v-model="filter.subproject_id"
                            :items="subprojects"
                            hide-details
                            label="Subprojects"
                            filled
                            placeholder="Type to search"
                            dense
                            item-text="name"
                            item-value="id"
                            clearable
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="filter.url"
                            :items="urls"
                            chips
                            hide-details
                            placeholder="Type to search"
                            small-chips
                            :disabled="urls && urls.length === 0"
                            label="Urls"
                            filled
                            dense
                            clearable
                            deletable-chips
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="filter.table"
                            :items="tables_name"
                            chips
                            small-chips
                            placeholder="Type to search"
                            label="Table"
                            filled
                            hide-details
                            :disabled="tables_name && tables_name.length === 0"
                            dense
                            clearable
                            deletable-chips
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="filter.brand"
                            :items="brands"
                            chips
                            small-chips
                            label="Brand"
                            filled
                            dense
                            placeholder="Type to search"
                            :disabled="brands && brands.length === 0"
                            hide-details
                            multiple
                            clearable
                            deletable-chips
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="filter.position"
                            :items="positions"
                            chips
                            small-chips
                            placeholder="Type to search"
                            label="Position"
                            filled
                            :disabled="positions && positions.length === 0"
                            hide-details
                            dense
                            clearable
                            deletable-chips
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                            @click="fetch_data"
                            :loading="loadings.data"
                            block
                            color="primary"
                            large
                          >
                            Get data
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-data-table
                v-if="!loadings.data && table.items.length > 0"
                :headers="table.headers"
                :items="table.items"
                :search="search"
                :sort-by="[
                  'clicks_gsc',
                  'table_id',
                  'position',
                  'clicks',
                  'cl_gsc_cl',
                  'all_clicks',
                ]"
                :sort-desc="[true, false, false, true, true, true]"
                :footer-props="{ itemsPerPageOptions: [50, -1] }"
                :items-per-page="50"
                multi-sort
                class="relative-td"
                dense
              >
                <template #[`item.url`]="{ value, item }">
                  <span>{{ value }}</span>
                  <span class="ml-1">
                    <a target="_blank" :href="item.url_full">
                      <v-icon small color="primary">mdi-open-in-new</v-icon>
                    </a>
                  </span>
                </template>
                <template
                  v-for="col in [
                    'clicks',
                    'cl_gsc_cl',
                    'reg_clicks',
                    'ndc_reg',
                    'ndc_clicks',
                  ]"
                  #[`item.${col}`]="{ item, items, value }"
                >
                  <div
                    :style="`background-color:${bggc_getBgColor(
                      value,
                      col,
                      table.items,
                      isCountZeros
                    )};width: 100%; height:100%;left:0;top:0;position:absolute;`"
                    class="px-4 text-center d-flex justify-center align-center"
                    :key="col"
                  >
                    <template v-if="col === 'clicks'">
                      <b>{{ value }}</b>
                      <sup
                        v-if="item.dates > 0"
                        title="number of days how much this brand is worth in this position in the selected filter"
                      >
                        {{ item.dates }}
                      </sup>
                    </template>
                    <template v-else>
                      {{ value }}
                    </template>
                  </div>
                </template>
                <!--                <template v-if="false">-->
                <!--                  <template v-slot:body="{ items }">-->
                <!--                    <tbody>-->
                <!--                      <tr v-for="item in items" :key="item.domain">-->
                <!--                        <td style="white-space: nowrap">-->
                <!--                          <span>{{ item.url }}</span>-->
                <!--                          <span-->
                <!--                            ><a target="_blank" :href="item.url_full"-->
                <!--                              ><v-icon small>mdi-open-in-new</v-icon></a-->
                <!--                            ></span-->
                <!--                          >-->
                <!--                        </td>-->
                <!--                        <td class="text-right">-->
                <!--                          {{ item.clicks_gsc }}-->
                <!--                        </td>-->
                <!--                        <td class="text-right">-->
                <!--                          {{ item.table_name }}-->
                <!--                        </td>-->
                <!--                        <td class="text-right">-->
                <!--                          {{ item.brand }}-->
                <!--                        </td>-->
                <!--                        <td class="text-right">-->
                <!--                          {{ item.position }}-->
                <!--                        </td>-->
                <!--                        <td-->
                <!--                          class="text-right"-->
                <!--                          :style="'background-color:' + item.clicks_color"-->
                <!--                        >-->
                <!--                          {{ item.clicks }}-->
                <!--                          <sup-->
                <!--                            v-if="item.dates > 0"-->
                <!--                            title="number of days how much this brand is worth in this position in the selected filter"-->
                <!--                            >{{ item.dates }}</sup-->
                <!--                          >-->
                <!--                        </td>-->
                <!--                        <td-->
                <!--                          class="text-right"-->
                <!--                          :style="'background-color:' + item.cl_gsc_cl_color"-->
                <!--                        >-->
                <!--                          {{ item.cl_gsc_cl }}-->
                <!--                        </td>-->
                <!--                        <td class="text-right">{{ item.all_clicks }}%</td>-->
                <!--                        <td class="text-right">{{ item.sum_brand_cl }}%</td>-->
                <!--                        <td class="text-right">-->
                <!--                          {{ item.earnings }}-->
                <!--                        </td>-->
                <!--                        <td class="text-right">-->
                <!--                          {{ item.deposits }}-->
                <!--                        </td>-->
                <!--                        <td class="text-right">-->
                <!--                          {{ item.reg }}-->
                <!--                        </td>-->
                <!--                        <td class="text-right">-->
                <!--                          {{ item.ndc }}-->
                <!--                        </td>-->
                <!--                        <td class="text-right">-->
                <!--                          {{ item.all_ndc }}-->
                <!--                        </td>-->
                <!--                        <td class="text-right">{{ item.ndc_all_ndc }}%</td>-->
                <!--                      </tr>-->
                <!--                    </tbody>-->
                <!--                  </template>-->
                <!--                </template>-->
              </v-data-table>
              <div
                v-else-if="
                  !loadings.data && table.items.length === 0 && isFetchedOnce
                "
              >
                <div class="py-4 text-center" style="opacity: 0.8">
                  No data for this filters...
                </div>
              </div>
              <div
                v-else-if="
                  !loadings.data && table.items.length === 0 && !isFetchedOnce
                "
              >
                <div class="py-4 text-center" style="opacity: 0.8">
                  Select filters and press <b>"Get data"</b>
                </div>
              </div>
              <div v-else class="d-flex justify-center align-center">
                <v-skeleton-loader type="table" width="100%" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-alert
      v-else
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
      dark
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn
            color="primary"
            dark
            @click="$store.state.project.menu_select_projects = true"
            >Select project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import axios from "axios";
import SmartDateFilter from "../../UI/SmartDateFilter.vue";
import eventPipe from "../../../events/eventPipe";
import getTableColBgGradientColorMixin from "../../../mixins/getTableColBgGradientColorMixin";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import Project from "@/mixins/Project";

let dateFormat = require("dateformat");

let beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000);
let beforeOneWeek2 = new Date(beforeOneWeek);
let day = beforeOneWeek.getDay();
let diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
let lastMonday = new Date(beforeOneWeek.setDate(diffToMonday - 50));
let lastSunday = new Date(beforeOneWeek2.setDate(diffToMonday + 6));

export default {
  name: "Conversion3Index",
  mixins: [getTableColBgGradientColorMixin, FiltersHandling, Project],
  data() {
    return {
      settings: [],
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Analytics",
          disabled: true,
        },
        {
          text: "Conversion 3",
          disabled: true,
        },
      ],
      search: "",
      isCountZeros: false,
      isFetchedOnce: false,
      loadings: {
        fetch_data: false,
        data: false,
      },
      date_modal: false,
      filter: {
        date_range: [
          dateFormat(lastMonday, "yyyy-mm-dd"),
          dateFormat(lastSunday, "yyyy-mm-dd"),
        ],
        project_id: undefined,
        url: undefined,
        table: undefined,
        brand: undefined,
        position: undefined,
        subproject_id: undefined,
      },
      urls: [],
      tables_name: [],
      brands: [],
      positions: [],
      table: {
        headers: [
          { text: "Page", value: "url", width: "260", class: "text-left" },
          { text: "clicks_gsc", value: "clicks_gsc", class: "text-right" },
          { text: "table", value: "table_name", class: "text-right" },
          { text: "brand", value: "brand", class: "text-right" },
          { text: "position", value: "position", class: "text-right" },
          { text: "sum_clicks", value: "clicks", class: "text-center" },
          { text: "Cl GSC/C", value: "cl_gsc_cl", class: "text-center" },
          { text: "reg_clicks", value: "reg_clicks", class: "text-center" },
          { text: "ndc_reg", value: "ndc_reg", class: "text-center" },
          { text: "ndc_clicks", value: "ndc_clicks", class: "text-center" },
          { text: "% all clicks", value: "all_clicks", class: "text-right" },
          {
            text: "% Sum Brand Cl",
            value: "sum_brand_cl",
            class: "text-right",
          },
          { text: "Pub revenue", value: "earnings", class: "text-right" },
          { text: "Deposits", value: "deposits", class: "text-right" },
          { text: "reg", value: "reg", class: "text-right" },
          { text: "ndc", value: "ndc", class: "text-right" },
          { text: "all NDC", value: "all_ndc", class: "text-right" },
          { text: "ndc/all NDC", value: "ndc_all_ndc", class: "text-right" },
        ],
        items: [],
      },
    };
  },

  async mounted() {
    this.parseQuery("filter");

    if (this.filter.project_id)
      this._$setActiveProject(this.filter.project_id, false);
    if (this.id_project) this.filter.project_id = Number(this.id_project);

    eventPipe.$on("update-active-project", () => {
      this.fetch_data();
    });
  },

  components: { SmartDateFilter },
  methods: {
    fetch_data() {
      if (this.id_project !== null && this.id_project !== undefined) {
        let url = `${this.$store.state.server_url}/analytics/conversion/report3/${this.id_project}`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = { filter: this.filter };

        this.loadings.data = true;

        this.isFetchedOnce = true;

        axios
          .post(url, data, config)
          .then((response) => {
            this.table.items = response.data.data;
            this.urls = response.data.urls;
            this.tables_name = response.data.tables_name;
            this.brands = response.data.brands;
            this.positions = response.data.positions;
          })
          .catch((error) => {
            alert(error);
          })
          .finally(() => {
            this.loadings.data = false;
          });
      }
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
    update_setting() {
      this.$store.dispatch("update_ls_serp_setting", this.ls_settings);
    },
  },
  watch: {
    settings(value) {
      this.isCountZeros = value === 0;
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
    subprojects() {
      let project = this.$store.state.project.allowed_projects.filter(
        (v) => v.id === parseInt(this.$store.state.project.active_project)
      );
      if (project && project[0]) return project[0]["subprojects"];
      else return [];
    },
  },
};
</script>

<style scoped></style>
