<template>
  <v-card flat outlined rounded="lg" class="pa-4 text-body-2" width="480">
    <v-row>
      <v-col cols="12" class="pb-0"> Selected params </v-col>
      <v-col cols="12" class="d-flex flex-wrap" style="gap: 0.25rem">
        <template v-if="ctx?.selectedParams?.length">
          <v-chip
            v-for="(paramId, idx) in ctx?.selectedParams || []"
            :key="`param-${idx}`"
          >
            {{ getParamDetails(paramId)?.name }}
          </v-chip>
        </template>
        <template v-else>
          <span style="opacity: 0.5"> Nothing is selected... </span>
        </template>
      </v-col>
      <template v-if="ctx?.niche">
        <v-col cols="12" class="pb-0"> Niche </v-col>
        <v-col cols="12" class="d-flex flex-wrap" style="gap: 0.25rem">
          <v-chip>
            {{ niches.find((n) => n.id === ctx?.niche)?.name }}
          </v-chip>
        </v-col>
      </template>
      <template v-if="ctx?.region?.length">
        <v-col cols="12" class="pb-0"> Regions </v-col>
        <v-col cols="12" class="d-flex flex-wrap" style="gap: 0.25rem">
          <v-chip
            v-for="(paramId, idx) in ctx?.region || []"
            :key="`param-${idx}`"
          >
            {{ getRegionDetails(paramId)?.name }}
          </v-chip>
        </v-col>
      </template>
    </v-row>
  </v-card>
</template>

<script>
import { NicheItems } from "./defaults";

export default {
  props: {
    ctx: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    niches: () => NicheItems,
    swCountries() {
      return this.$store.getters["paramsCollecting/getSwCountries"];
    },
    allowedParams() {
      return this.$store.getters["paramsCollecting/getAllowedParams"];
    },
  },

  methods: {
    getRegionDetails(regionId) {
      return this.swCountries.find((item) => item.id === regionId);
    },
    getParamDetails(paramId) {
      // Рекурсивная функция для поиска в массиве children
      function findParam(children) {
        for (const child of children) {
          if (child.id === paramId) {
            return child; // Если нашли объект с совпадающим id
          }
          if (child.children) {
            const result = findParam(child.children); // Поиск во вложенных children
            if (result) return result; // Если нашли, вернуть результат
          }
        }
        return null; // Если ничего не найдено
      }

      for (const section of this.allowedParams) {
        if (section.children) {
          const result = findParam(section.children);
          if (result) return result;
        }
      }

      return null; // Если параметр не найден во всех секциях
    },
  },
};
</script>
