<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <page-header
          title="Fields manager"
          :breadcrumbs="[
            { text: 'Home', to: '/' },
            { text: 'Site workplace', disabled: true },
            { text: 'Fields manager', disabled: true },
          ]"
        />
      </v-col>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="text-body-2 py-2">
            <b>Fields</b>
            <v-spacer />
            <v-btn
              class="text-normal"
              color="primary"
              @click="handleCreateField"
            >
              Create new field <v-icon small right>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="px-0 pt-0 pb-2">
            <v-data-table
              :items="tableData.items"
              :headers="[...tableData.headers, { text: '', value: '_actions' }]"
            >
              <template #[`item._actions`]="{ item }">
                <div class="d-flex justify-end">
                  <v-btn class="text-normal" @click="handleEditField(item)">
                    Edit <v-icon small right>mdi-pencil</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <create-field-dialog v-model="isCreateFieldDialogVisible" />
    <edit-field-dialog v-model="isEditFieldDialogVisible" />
  </v-container>
</template>

<script>
import PageHeader from "@/components/UI/PageHeader.vue";
import { MOCK } from "./defaults";
import CreateFieldDialog from "@/components/SiteWorkplace/FieldsManager/components/CreateFieldDialog.vue";
import EditFieldDialog from "@/components/SiteWorkplace/FieldsManager/components/EditFieldDialog.vue";

export default {
  components: { EditFieldDialog, CreateFieldDialog, PageHeader },

  data: () => ({
    isEditFieldDialogVisible: false,
    isCreateFieldDialogVisible: false,
    editFieldData: null,
    mockEditData: {},
    tableData: {
      items: MOCK.TABLE_ITEMS,
      headers: MOCK.TABLE_HEADERS,
    },
  }),

  methods: {
    handleEditField(fieldData) {
      this.isEditFieldDialogVisible = true;
      this.editStatusData = this.mockEditData || fieldData;
    },
    handleCreateField() {
      this.isCreateFieldDialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
