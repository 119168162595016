import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import colors from "vuetify/es5/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    dark: false,
    themes: {
      light: {
        info: colors.blue.base,
        primary: colors.blue.darken2,
        primaryDarken: "#0042aa",
      },
      dark: {
        primaryDarken: "#286cd2",
        primary: colors.blue.darken3,
        accent: colors.grey.darken3,
        info: colors.blue.darken2,
        warning: colors.amber.darken4,
        error: colors.deepOrange.darken3,
        success: colors.green.darken1,
      },
    },
  },
});
