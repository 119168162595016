<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex align-end">
        <page-header
          title="Text generate"
          :breadcrumbs="[
            { text: 'Home', to: '/' },
            { text: 'Donor base', disabled: true },
            { text: 'Text generate', disabled: true },
          ]"
        />
        <v-spacer />
        <v-btn
          color="secondary"
          class="text-normal"
          @click="upload.modal = true"
        >
          Upload replacement phrases <v-icon small right>mdi-upload</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="8">
            <v-card flat outlined rounded="lg">
              <v-card-title class="text-body-2">
                Content for text generation
              </v-card-title>
              <v-divider />
              <v-card-text class="pt-4">
                <v-textarea
                  v-model="filters.inputData"
                  outlined
                  hide-details
                  dense
                  placeholder="Url <tab> Anchor <tab> Length [<tab> Url Link]"
                >
                </v-textarea>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card flat outlined rounded="lg">
              <v-card-title class="text-body-2">
                Generation settings
              </v-card-title>
              <v-divider />
              <v-card-text class="d-flex flex-column" style="gap: 1rem">
                <v-autocomplete
                  v-model="filters.formatting_features"
                  :items="filtersData.formatting_features"
                  hide-details
                  outlined
                  dense
                  multiple
                  label="Formatting features"
                  :loading="filtersDataPending"
                ></v-autocomplete>
                <v-autocomplete
                  v-model="filters.languages"
                  :items="filtersData.languages"
                  hide-details
                  outlined
                  label="Language"
                  dense
                  :loading="filtersDataPending"
                ></v-autocomplete>
                <v-autocomplete
                  v-model="filters.ai"
                  :items="filtersData.ai"
                  hide-details
                  outlined
                  dense
                  label="Language model (AI)"
                  :loading="filtersDataPending"
                ></v-autocomplete>
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  large
                  class="text-normal px-6"
                  color="primary"
                  :loading="loadings.text"
                  @click="getTexts"
                >
                  Generation
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col
            v-if="loadings.text"
            class="d-flex flex-column"
            style="gap: 1rem"
          >
            <div
              v-for="i in [0, 1, 2]"
              class="skeleton skeleton--placeholder"
              style="width: 100%; height: 320px"
              :key="i"
            />
          </v-col>
          <template v-else>
            <v-col cols="12" class="pb-0">
              <div class="text-body-2">
                <b> Texts ({{ texts?.length }}) </b>
              </div>
            </v-col>
            <v-col v-for="(item, idx) in texts" :key="`html-${idx}`" cols="12">
              <v-card
                outlined
                rounded="lg"
                color="rgba(155,155,155,0.1)"
                class="pa-8"
              >
                <pre style="white-space: pre-wrap">
                {{ item.html }}
              </pre
                >
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" md="12">
            <v-card flat outlined rounded="lg">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                  outlined
                  dense
                  class="align-end"
                ></v-text-field>
              </v-card-title>
              <v-divider />
              <v-card-text class="pa-0 pt-5 pb-2">
                <v-data-table
                  :headers="table.headers"
                  :items="table.items"
                  dense
                >
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <upload-replacement-phrases
      v-if="upload.modal === true"
      v-model="upload.modal"
    ></upload-replacement-phrases>
  </v-container>
</template>

<script>
import UploadReplacementPhrases from "@/components/TextGenerate/UploadReplacementPhrases";
import UploadFiltersDataMixin from "../../mixins/UploadFiltersDataMixin";
import PageHeader from "../UI/PageHeader.vue";
import AwaitResolveMixin from "../../mixins/utils/AwaitResolveMixin";
import service from "../../plugins/service";

export default {
  name: "Index",
  components: { PageHeader, UploadReplacementPhrases },

  mixins: [UploadFiltersDataMixin, AwaitResolveMixin],

  data() {
    return {
      search: "",
      loadings: {
        getHistoryData: false,
        text: false,
      },
      upload: { modal: false },
      filtersData: {
        languages: [],
        ai: [],
        formatting_features: [],
      },
      filters: {
        ai: "",
        inputData: "",
        formatting_features: [],
        languages: 41,
      },
      table: {
        headers: [
          { text: "id", title: "id", value: "id", class: "text-center" },
          {
            text: "phrase",
            title: "phrase",
            value: "phrase",
            class: "text-center",
          },
          { text: "url", title: "url", value: "url", class: "text-left" },
          {
            text: "length",
            title: "length",
            value: "length",
            class: "text-left",
          },
          {
            text: "created_at",
            title: "created_at",
            value: "created_at",
            class: "text-center",
          },
          { text: "used", title: "used", value: "used", class: "text-left" },
          { text: "date", title: "date", value: "date", class: "text-center" },
        ],
        items: [],
      },
      texts: [],
    };
  },

  watch: {
    computedProjectId: {
      handler: function (id) {
        console.log("new id", id);
        if (id) this.getHistoryData();
      },
    },
  },

  async mounted() {
    this.uploadFiltersData(["languages", "ai", "formatting_features"]);

    if (this.computedProjectId) this.getHistoryData();

    if (!this.computedProjectId) {
      return this.$store.dispatch("toggle_select_project_menu");
    }
  },

  methods: {
    async getHistoryData() {
      if (!this.computedProjectId)
        return this.$store.dispatch("toggle_select_project_menu");

      try {
        this.loadings.getHistoryData = true;

        const url = "/text-generate/history";

        const response = await service.post(url, {});

        this.table.items = response.data;
      } catch (e) {
        console.error("Error while loading history data table.", e);
      } finally {
        this.loadings.getHistoryData = false;
      }
    },
    async getTexts() {
      if (!this.computedProjectId)
        return this.$store.dispatch("toggle_select_project_menu");

      try {
        this.loadings.text = true;
        const url = "/text-generate/one/file/json";

        const response = await service.post(url, { filter: this.filters });

        this.texts = response.data.data;
      } catch (error) {
        console.error("Error while loading text.", error);
      } finally {
        this.loadings.text = false;
      }
    },
    getTextWithH1Html(item) {
      return "<h1>" + item.title + "</h1>" + item.text;
    },
    getTextForMainPage(item) {
      return item.textDiv;
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
  },
  computed: {
    computedProjectId() {
      return (
        this.$store.state.project.active_project ||
        this.$store.getters["active_project"]
      );
    },
  },
};
</script>

<style scoped></style>
