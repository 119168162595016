import service from "../../../plugins/service";

export const SemanticToolApiService = () => {
  const deleteTask = (id) => {
    return service.delete(`/semantic-tool/task/delete/${id}`);
  };

  const deleteBadKeywords = (id) => {
    return service.delete(`/semantic-tool/task/delete-bad-keywords/${id}`);
  };

  return {
    deleteTask,
    deleteBadKeywords,
  };
};
