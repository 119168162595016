<template>
  <v-card flat outlined rounded="lg">
    <v-card-title class="pa-4 text-body-2">
      <b>{{ data.title }}</b>
    </v-card-title>
    <v-divider />
    <v-card-text class="px-4" style="background: var(--gray-bg-low-emphasis)">
      <!-- Status items layer ( 1 ) -->
      <div class="d-flex flex-column" style="gap: 1rem">
        <v-card
          v-for="(status, idx) in data?.items || []"
          :key="`user-${idx}`"
          flat
          outlined
          rounded="lg"
        >
          <v-card-title class="pa-4 text-body-2" style="gap: 0.5rem">
            <v-tooltip bottom open-delay="300">
              <template #activator="{ on }">
                <v-chip
                  v-on="on"
                  label
                  class="px-2 flat-chip flat-chip--primary"
                >
                  <v-icon small style="color: inherit">mdi-circle</v-icon>
                </v-chip>
              </template>
              <template #default> Status </template>
            </v-tooltip>
            {{ status?.title }}
          </v-card-title>
          <v-card-text>
            <!-- Problem category items layer ( 2 ) -->

            <div class="d-flex flex-column" style="gap: 1rem">
              <v-card
                v-for="(category, idx) in status?.items || []"
                :key="`metric-${idx}`"
                flat
                outlined
                rounded="lg"
              >
                <v-card-title class="pa-4 text-body-2" style="gap: 0.5rem">
                  <v-tooltip bottom open-delay="300">
                    <template #activator="{ on }">
                      <v-chip
                        v-on="on"
                        label
                        class="px-2 flat-chip flat-chip--warning"
                      >
                        <v-icon small>mdi-folder</v-icon>
                      </v-chip>
                    </template>
                    <template #default> problem category </template>
                  </v-tooltip>
                  {{ category?.title }}
                  <v-chip outlined label @click="toggleExpand(category)">
                    <template v-if="category?._expanded">
                      Hide <v-icon small right>mdi-chevron-up</v-icon>
                    </template>
                    <template v-else>
                      Show all
                      <my-badge
                        v-if="category?.items.length"
                        class="white--text primary ml-2"
                      >
                        {{ category?.items.length }}
                      </my-badge>

                      <v-icon small right>mdi-chevron-down</v-icon>
                    </template>
                  </v-chip>
                </v-card-title>
                <v-card-text class="px-0 py-2">
                  <outreach-problems-dynamic-data-table
                    :custom-sparkline-color="getSparklineColor"
                    :dense="false"
                    :data="{
                      items: getItems(category?.items || [], category),
                      headers: data?.headers,
                    }"
                  />
                </v-card-text>
              </v-card>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import OutreachProblemsDynamicDataTable from "./OutreachProblemsDynamicDataTable.vue";
import MyBadge from "../../UI/MyBadge.vue";

export default {
  components: { MyBadge, OutreachProblemsDynamicDataTable },

  props: ["data"],

  methods: {
    getSparklineColor(data) {
      if (data.length === 0) {
        return "orange";
      }
      if (data[0] > data[data.length - 1]) {
        return "green";
      }
      return "red";
    },
    toggleExpand(ctx) {
      if (ctx?._expanded) {
        this.$set(ctx, "_expanded", false);
      } else {
        this.$set(ctx, "_expanded", true);
      }
      this.$forceUpdate();
    },
    getItems(items, ctx) {
      const isExpanded = ctx?._expanded;

      return items.filter((item) => {
        if (isExpanded) return true;

        return item._required;
      });
    },
  },
};
</script>
