<template>
  <div v-if="variant === 'default'" class="table-search" v-bind="$attrs">
    <input
      type="text"
      :value="localValue"
      @input="({ target }) => (value = target.value)"
      v-bind="$attrs"
    />
    <div class="table-search__icon">
      <v-icon v-if="!loading" small>mdi-magnify</v-icon>
      <v-progress-circular v-else indeterminate width="1" size="10" />
    </div>
  </div>
  <v-text-field
    v-else-if="variant === 'vuetify'"
    :value="localValue"
    @change="value = $event"
    v-bind="$attrs"
    :loading="loading"
  />
</template>

<script>
export default {
  props: {
    modelValue: String,
    variant: {
      type: String,
      default: "default",
    },
  },
  data: () => ({
    changeTimeout: null,
    changeTimeoutDelay: 750,
    loading: false,
    localValue: null,
  }),
  created() {
    this.localValue = this.modelValue;
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.loading = true;
        this.localValue = value;
        clearTimeout(this.changeTimeout);
        this.changeTimeout = setTimeout(() => {
          this.$emit("update:modelValue", value);
          this.loading = false;
        }, this.changeTimeoutDelay);
      },
    },
  },
};
</script>

<style lang="scss">
.table-search {
  position: relative;
  input {
    height: 32px;
    width: 100%;
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid rgba(155, 155, 155, 0.5);
    outline: none;
    background-color: #fff;
    font-size: 14px;
    &:focus {
      outline: none;
    }
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 100%;
    position: absolute !important;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.v-application.theme--dark {
  .table-search {
    input {
      background-color: #1e1e1e;
      border: 1px solid rgba(203, 203, 203, 0.15);
      color: #fff;
    }
  }
}
</style>
