export const MOCK = {
  TABLE_ITEMS: [
    {
      type: 'guest post',
      name: 'Waiting for placement details',
      main_status: 'Согалсование возможности размещения',
      responsible_priority: 50,
    }
  ],
  TABLE_HEADERS: [
    {
      text: 'Type',
      value: 'type',
    },
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Main status',
      value: 'main_status',
    },
    {
      text: 'Responsible priority',
      value: 'responsible_priority'
    },
  ]
}
