import service from "../../plugins/service";

class PositionsApiService {
  endpoints = {
    v2: "/serp/v2/positions",
    trends: "/serp/v2/positions/trends",
    positionsChart: "/serp/v2/positions/chart",
    positionsTable: "/serp/v2/positions/table",
    pageTypes: "/serp/v2/positions/page-types",
    growthFell: "/serp/v2/positions/growth",
  };

  async getTrends(payload) {
    try {
      const { data } = await service.post(this.endpoints.trends, payload);

      return data;
    } catch (e) {
      console.error("Error while loading positions trends data.", e);
    }
  }

  async getPositionsChart(payload) {
    try {
      const { data } = await service.post(
        this.endpoints.positionsChart,
        payload
      );

      return data;
    } catch (e) {
      console.error("Error while loading positions chart data.", e);
    }
  }

  async getPositionsTable(payload) {
    try {
      const { data } = await service.post(
        this.endpoints.positionsTable,
        payload
      );

      return data;
    } catch (e) {
      console.error("Error while loading positions table data.", e);
    }
  }

  async getPageTypes(payload) {
    try {
      const { data } = await service.post(this.endpoints.pageTypes, payload);

      return data;
    } catch (e) {
      console.error("Error while loading positions table data.", e);
    }
  }

  async getGrowthFell(payload) {
    try {
      const { data } = await service.post(this.endpoints.growthFell, payload);

      return data;
    } catch (e) {
      console.error("Error while loading positions table data.", e);
    }
  }

  async getV2Dashboard(payload) {
    try {
      const { data } = await service.post(this.endpoints.v2, payload);
      return data;
    } catch (e) {
      console.error("Error while loading data for positions v2 dashboard", e);
    }
  }
}

export default PositionsApiService;
