<template>
  <v-container
    fluid
    :class="`content-current-result ${hkd_altPressed ? 'magnify' : ''}`"
    style="padding-bottom: 200px"
  >
    <v-row>
      <v-col cols="12">
        <page-header
          title="Content Current Result"
          :breadcrumbs="[
            { text: 'Home', to: '/' },
            { text: 'Content Current Result', disabled: true },
          ]"
        />
      </v-col>
    </v-row>
    <v-row dense class="mt-6">
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.project"
          hide-details
          dense
          :items="filtersData.projects"
          clearable
          outlined
          :loading="filtersDataPendingKeys?.projects"
          label="Project"
          :menu-props="menuProps"
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0">{{ item.text }}</span>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ filters.project.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.subproject"
          hide-details
          dense
          :items="filtersData.subprojects"
          clearable
          outlined
          :loading="filtersDataPendingKeys?.subprojects"
          label="Subproject"
          :menu-props="menuProps"
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0">{{ item.text }}</span>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ filters.subproject.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.team"
          hide-details
          multiple
          clearable
          dense
          outlined
          placeholder="Type to search"
          :items="filtersData.team"
          :loading="loadings.filters"
          label="Team"
          :menu-props="menuProps"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.niche"
          hide-details
          dense
          multiple
          clearable
          outlined
          :items="filtersData.n"
          :loading="loadings.filters"
          label="Niches"
          :menu-props="menuProps"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <smart-date-filter
          :model-value="filters.date"
          @update:modelValue="filters.date = $event"
        ></smart-date-filter>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.project_categories"
          :items="filtersData.project_categories"
          multiple
          hide-details
          dense
          outlined
          label="Project categories"
          placeholder="Type to search"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="fill" class="d-flex justify-end">
        <v-btn
          @click="fetchData"
          color="primary"
          class="text-normal px-6"
          height="40"
          :loading="loadings.table"
        >
          Get Data
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card rounded="lg" outlined flat>
          <v-card-text>
            <v-row>
              <v-col>
                <div class="d-flex">
                  In Progress
                  <v-spacer />

                  {{ getInProgressAmount }} of
                  {{ getTotalForInProgress }}
                  <v-spacer />
                  <b>
                    {{
                      roundValue(
                        (getInProgressAmount * 100) / getTotalForInProgress
                      )
                    }}%
                  </b>
                </div>
                <v-progress-linear
                  rounded
                  :value="
                    roundValue(
                      (getInProgressAmount * 100) / getTotalForInProgress
                    )
                  "
                  height="6"
                />
              </v-col>
              <v-col>
                <div class="d-flex">
                  Done
                  <v-spacer />
                  {{ getDoneAmount }} of
                  {{ getAllAmount }}
                  <v-spacer />
                  <b>
                    {{ roundValue((getDoneAmount / getAllAmount) * 100) }}%
                  </b>
                </div>
                <v-progress-linear
                  rounded
                  :value="(getDoneAmount / getAllAmount) * 100"
                  height="6"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-text class="px-0">
            <div class="px-4 pb-3 d-flex">
              <v-text-field
                v-model="search"
                hide-details
                dense
                outlined
                append-icon="mdi-magnify"
                clearable
                label="Type to search"
                style="max-width: 340px"
              ></v-text-field>
              <v-spacer />

              <div class="d-flex" style="gap: 0.25rem">
                <v-tooltip v-if="isHighlightFulfilled" bottom>
                  <template #activator="{ on }">
                    <v-sheet
                      class="d-flex align-center px-2"
                      color="transparent"
                      v-on="on"
                      >Highlight done
                      <v-icon small right>mdi-check</v-icon></v-sheet
                    >
                  </template>
                  <div>
                    Highlight in <u>green</u> if the result >=
                    <u>best result</u>
                  </div>
                </v-tooltip>
                <v-tooltip
                  v-if="!isCalculateForLastDayFromFilter"
                  bottom
                  max-width="260"
                >
                  <template #activator="{ on }">
                    <v-sheet
                      v-on="on"
                      class="d-flex align-center px-2"
                      color="transparent"
                      >From start to today
                      <v-icon small right
                        >mdi-calendar-export-outline</v-icon
                      ></v-sheet
                    >
                  </template>
                  <div>
                    The formula for calculating <u>best</u> and
                    <u>average</u> takes into account the difference between the
                    <u>first date selected in the filter</u> and
                    <u>today</u>
                  </div>
                </v-tooltip>
                <v-tooltip
                  v-if="isCalculateForLastDayFromFilter"
                  bottom
                  max-width="260"
                >
                  <template #activator="{ on }">
                    <v-sheet
                      v-on="on"
                      class="d-flex align-center px-2"
                      color="transparent"
                    >
                      From start to end
                      <v-icon small right
                        >mdi-calendar-expand-horizontal-outline</v-icon
                      ></v-sheet
                    >
                  </template>
                  <div>
                    The formula for calculating <u>best</u> and
                    <u>average</u> takes into account the difference between the
                    <u>first date selected in the filter</u> and
                    <u>the last date selected in the filter</u>
                  </div>
                </v-tooltip>

                <v-menu
                  bottom
                  content-class="shadow-e1-bordered"
                  rounded
                  nudge-bottom="40"
                  :close-on-content-click="false"
                >
                  <template #activator="{ on }">
                    <v-btn class="text-normal" v-on="on">
                      Preferences
                      <v-icon small right>mdi-cog-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-card class="pa-0">
                    <v-list dense>
                      <v-list-item @click="isHighlightFulfilled = false">
                        <v-list-item-icon>
                          <v-icon v-if="!isHighlightFulfilled" color="primary"
                            >mdi-check</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-title>
                          Hide >= best result
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="isHighlightFulfilled = true">
                        <v-list-item-icon>
                          <v-icon v-if="isHighlightFulfilled" color="primary"
                            >mdi-check</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-title>
                          Highlight >= best result
                        </v-list-item-title>
                      </v-list-item>

                      <v-divider />

                      <v-list-item
                        @click="isCalculateForLastDayFromFilter = false"
                      >
                        <v-list-item-icon>
                          <v-icon
                            v-if="!isCalculateForLastDayFromFilter"
                            color="primary"
                            >mdi-check</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-title>
                          Calculate using first date from <br />
                          selected date filter, and date of today
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="isCalculateForLastDayFromFilter = true"
                      >
                        <v-list-item-icon>
                          <v-icon
                            v-if="isCalculateForLastDayFromFilter"
                            color="primary"
                            >mdi-check</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-title>
                          Calculate using start and <br />
                          end dates from selected date filter
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </div>
            </div>
            <v-data-table
              :search="search"
              :headers="tableData.headers"
              :items="tableData.items"
              :loading="loadings.table"
              :sort-by="['priority']"
              :sort-desc="[true]"
              class="content-current-result__table relative-tr table-overflow-hidden"
              :items-per-page="-1"
              hide-default-footer
              dense
              multi-sort
              :custom-sort="_$smartCustomTableSort"
            >
              <template #[`item.done`]="{ item }">
                <div
                  :class="`colored-td colored-td--inline ${getDoneColor(item)}`"
                >
                  <v-tooltip bottom content-class="shadow-e1-bordered pa-0">
                    <template #activator="{ on }">
                      <div v-on="on">
                        {{ item.done === null ? "-" : item.done }}
                      </div>
                    </template>
                    <v-card class="pa-1">
                      <table style="border-spacing: 10px">
                        <tr>
                          <td>Best</td>
                          <td>
                            {{ processResultOfWork("done", "best", item.plan) }}
                          </td>
                        </tr>
                        <tr>
                          <td>Average</td>
                          <td>
                            {{
                              processResultOfWork("done", "average", item.plan)
                            }}
                          </td>
                        </tr>
                      </table>
                    </v-card>
                  </v-tooltip>
                </div>
              </template>
              <template #[`item.in_progress`]="{ item }">
                <div
                  :class="`colored-td colored-td--inline ${getInProgressColor(
                    item
                  )}`"
                >
                  <v-tooltip bottom content-class="shadow-e1-bordered pa-0">
                    <template #activator="{ on }">
                      <div v-on="on">
                        {{ item.in_progress === null ? "-" : item.in_progress }}
                      </div>
                    </template>
                    <v-card class="pa-1">
                      <table style="border-spacing: 10px">
                        <tr>
                          <td>Best</td>
                          <td>
                            {{
                              processResultOfWork(
                                "in_progress",
                                "best",
                                item.plan
                              )
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Average</td>
                          <td>
                            {{
                              processResultOfWork(
                                "in_progress",
                                "average",
                                item.plan
                              )
                            }}
                          </td>
                        </tr>
                      </table>
                    </v-card>
                  </v-tooltip>
                </div>
              </template>
              <template #[`item.publication_proccess`]="{ item }">
                <div
                  :class="`colored-td colored-td--inline ${getPublicationProcessColor(
                    item
                  )}`"
                >
                  <v-tooltip bottom content-class="shadow-e1-bordered pa-0">
                    <template #activator="{ on }">
                      <div v-on="on">
                        {{
                          item.publication_proccess === null
                            ? "-"
                            : item.publication_proccess
                        }}
                      </div>
                    </template>
                    <v-card class="pa-1">
                      <table style="border-spacing: 10px">
                        <tr>
                          <td>Best</td>
                          <td>
                            {{
                              processResultOfWork(
                                "publication_proccess",
                                "best",
                                item.plan
                              )
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Average</td>
                          <td>
                            {{
                              processResultOfWork(
                                "publication_proccess",
                                "average",
                                item.plan
                              )
                            }}
                          </td>
                        </tr>
                      </table>
                    </v-card>
                  </v-tooltip>
                </div>
              </template>
              <template #[`item.waiting_content`]="{ item }">
                <div
                  :class="`colored-td colored-td--inline ${getWaitingForContentColor(
                    item
                  )}`"
                >
                  <v-tooltip bottom content-class="shadow-e1-bordered pa-0">
                    <template #activator="{ on }">
                      <div v-on="on">
                        {{
                          item.waiting_content === null
                            ? "-"
                            : item.waiting_content
                        }}
                      </div>
                    </template>
                    <v-card class="pa-1">
                      <table style="border-spacing: 10px">
                        <tr>
                          <td>Best</td>
                          <td>
                            {{
                              processResultOfWork(
                                "waiting_content",
                                "best",
                                item.plan
                              )
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Average</td>
                          <td>
                            {{
                              processResultOfWork(
                                "waiting_content",
                                "average",
                                item.plan
                              )
                            }}
                          </td>
                        </tr>
                      </table>
                    </v-card>
                  </v-tooltip>
                </div>
              </template>
              <template #[`item.topic_approve`]="{ item }">
                <div
                  :class="`colored-td colored-td--inline ${getTopicApproveColor(
                    item
                  )}`"
                >
                  <v-tooltip bottom content-class="shadow-e1-bordered pa-0">
                    <template #activator="{ on }">
                      <div v-on="on">
                        {{
                          item.topic_approve === null ? "-" : item.topic_approve
                        }}
                      </div>
                    </template>
                    <v-card class="pa-1">
                      <table style="border-spacing: 10px">
                        <tr>
                          <td>Best</td>
                          <td>
                            {{
                              processResultOfWork(
                                "topic_approve",
                                "best",
                                item.plan
                              )
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Average</td>
                          <td>
                            {{
                              processResultOfWork(
                                "topic_approve",
                                "average",
                                item.plan
                              )
                            }}
                          </td>
                        </tr>
                      </table>
                    </v-card>
                  </v-tooltip>
                </div>
              </template>
              <template #[`item.plan`]="{ value }">
                {{ String(value) === "0" ? "" : value }}
              </template>
              <template
                v-for="col in ['aprove_seo', 'send_letter', 'reserved']"
                #[`item.${col}`]="{ value }"
              >
                {{ String(value) === "0" ? "" : value }}
              </template>
              <template #[`item.canceled`]="{ item }">
                {{ String(item.canceled) === "0" ? "" : item.canceled
                }}<sup v-if="item.canceled.percent">
                  {{ item.canceled.percent }}
                </sup>
              </template>
              <template #[`item.active_donors`]="{ value }">
                <div :class="`colored-td ${value.incomplete ? 'red' : ''}`">
                  {{ String(value) === "0" ? "" : value }}
                </div>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import service from "@/plugins/service";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import { DEFAULT_MENU_PROPS } from "../../utils/defaultData";
import HandleKeydown from "@/mixins/HandleKeydown";
import SmartDateFilter from "@/components/UI/SmartDateFilter";
import Location from "../../mixins/Location";
import { deepClone } from "../../utils/functions";
import moment from "moment";
import Project from "@/mixins/Project";
import UploadFiltersDataMixin from "@/mixins/UploadFiltersDataMixin";
import PageHeader from "@/components/UI/PageHeader.vue";

export default {
  components: { PageHeader, SmartDateFilter },
  mixins: [
    FiltersHandling,
    HandleKeydown,
    Location,
    Project,
    UploadFiltersDataMixin,
  ],
  data: () => ({
    otherCols: [
      "plan",
      "active_done",
      "aprove_seo",
      "in_progress",
      "done",
      "send_letter",
      "canceled",
      "reserved",
    ],
    loadings: {
      table: false,
      filters: false,
    },
    search: null,
    tableData: {
      headers: [],
      items: [],
    },
    menuProps: DEFAULT_MENU_PROPS,
    filtersData: {
      project_categories: [],
      projects: [],
      subprojects: [],
      n: [],
      p: [],
      dep: [],
      seo: [],
      resp: [],
    },
    overdueCols: ["topic_approve", "waiting_content", "publication_proccess"],
    hkd_altPressed: false,
    filters: {
      project: null,
      project_categories: [],
      subproject: null,
      niche: [],
      team: [],
      date: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
    },
    isHighlightFulfilled: false,
    isCalculateForLastDayFromFilter: false,
  }),
  async mounted() {
    await this.parseQuery("filters");

    this.uploadFiltersData(["projects", "subprojects", "project_categories"], {
      project: this.filters.project,
    });

    this.fetchFilters();

    this.$watch("filters.project", (newValue) => {
      this.filters.subproject = null;
      this.uploadFiltersData(["subprojects"], { project: newValue });
    });

    if (Object.keys(this.$route.query).length) this.fetchData();
  },
  computed: {
    getDoneAmount() {
      const tmpr = deepClone(this.tableData.items);

      let doneAmount = 0;

      tmpr.forEach((item) => {
        doneAmount += item.done;
      });

      return doneAmount;
    },
    getInProgressAmount() {
      const tmpr = deepClone(this.tableData.items);

      let inProgressAmount = 0;

      tmpr.forEach((item) => {
        inProgressAmount += item.in_progress;
      });

      return inProgressAmount;
    },
    getTotalForInProgress() {
      const tmpr = deepClone(this.tableData.items);

      let allAmount = 0;

      tmpr.forEach((item) => {
        allAmount += (item?.plan || 0) - (item?.done || 0);
      });

      return allAmount;
    },
    getAllAmount() {
      const tmpr = deepClone(this.tableData.items);

      let allAmount = 0;

      tmpr.forEach((item) => {
        allAmount += (item.plan && item.plan) || 0;
      });

      return allAmount;
    },
    computeDateDaysDiff() {
      // diff between today and first date in date-filter [Date, Date]
      if (this.isCalculateForLastDayFromFilter)
        return (
          this.$moment(this.filters.date[1]).diff(
            this.filters.date[0],
            "days"
          ) + 1
        );

      return this.$moment(new Date()).diff(this.filters.date[0], "days") + 1;
    },
  },
  methods: {
    roundValue(number) {
      try {
        const result = Math.ceil(number);
        if (isNaN(result)) return 0;

        return result;
      } catch (e) {
        console.error("Error while rounding value.", e);
      }
    },
    processResultOfWork(type, formula, plan) {
      const daysDiff = this.computeDateDaysDiff;

      if (type === "done")
        return this.getDoneResultByFormula(formula, plan, daysDiff);

      if (type === "in_progress")
        return this.getInProgressResultByFormula(formula, plan, daysDiff);

      if (type === "publication_proccess")
        return this.getPublicationProcessResultByFormula(
          formula,
          plan,
          daysDiff
        );

      if (type === "waiting_content")
        return this.getWaitingForContentResultByFormula(
          formula,
          plan,
          daysDiff
        );

      if (type === "topic_approve")
        return this.getTopicApproveResultByFormula(formula, plan, daysDiff);
    },
    getDoneResultByFormula(formula, plan, daysDiff) {
      if (formula === "best") {
        const min = Math.min(Math.round(((daysDiff - 9) / 26) * plan));
        return Math.min(Math.max(min, 0), plan);
      }
      if (formula === "average") {
        const min = Math.min(Math.round(((daysDiff - 12) / 31) * plan));
        return Math.min(Math.max(min, 0), plan);
      }
    },
    getPublicationProcessResultByFormula(formula, plan, daysDiff) {
      if (formula === "best") {
        const min = Math.min(Math.round(((daysDiff - 5) / 20) * plan), plan);
        return Math.min(Math.max(min, 0), plan);
      }
      if (formula === "average") {
        const min = Math.min(Math.round(((daysDiff - 9) / 20) * plan), plan);
        return Math.min(Math.max(min, 0), plan);
      }
    },
    getWaitingForContentResultByFormula(formula, plan, daysDiff) {
      if (formula === "best") {
        const min = Math.min(Math.round(((daysDiff - 6) / 20) * plan), plan);
        return Math.min(Math.max(min, 0), plan);
      }
      if (formula === "average") {
        const min = Math.min(Math.round(((daysDiff - 10) / 20) * plan), plan);
        return Math.min(Math.max(min, 0), plan);
      }
    },
    getTopicApproveResultByFormula(formula, plan, daysDiff) {
      if (formula === "best") {
        const min = Math.min(Math.round(((daysDiff - 5) / 19) * plan), plan);
        return Math.min(Math.max(min, 0), plan);
      }
      if (formula === "average") {
        const min = Math.min(Math.round(((daysDiff - 9) / 19) * plan), plan);
        return Math.min(Math.max(min, 0), plan);
      }
    },
    getInProgressResultByFormula(formula, plan, daysDiff) {
      if (formula === "best") {
        const min = Math.min(Math.round(((daysDiff - 7) / 20) * plan), plan);
        return Math.min(Math.max(min, 0), plan);
      }
      if (formula === "average") {
        const min = Math.min(Math.round(((daysDiff - 11) / 20) * plan), plan);
        return Math.min(Math.max(min, 0), plan);
      }
    },
    getDoneColor(tableRowData) {
      try {
        const plan = tableRowData.plan;
        const done = tableRowData.done;

        if (!plan || done === null || done === undefined) return "";

        const bestResult = this.processResultOfWork("done", "best", plan);
        const averageResult = this.processResultOfWork("done", "average", plan);

        if (this.isHighlightFulfilled && done >= bestResult) return "green";

        if (done >= averageResult && done < bestResult) return "yellow";

        if (done < averageResult) return "red";

        return "";
      } catch (e) {
        console.error(e);
        return "";
      }
    },
    getInProgressColor(tableRowData) {
      try {
        const plan = tableRowData.plan;
        const inProgress = tableRowData.in_progress;
        const done = tableRowData.done;

        if (
          !plan ||
          done === null ||
          done === undefined ||
          inProgress === undefined ||
          inProgress === null
        )
          return "";

        const bestResult = this.processResultOfWork(
          "in_progress",
          "best",
          plan
        );
        const averageResult = this.processResultOfWork(
          "in_progress",
          "average",
          plan
        );

        const sum = inProgress + done;

        if (this.isHighlightFulfilled && sum >= bestResult) return "green";

        if (sum >= averageResult && sum < bestResult) return "yellow";

        if (sum < averageResult) return "red";

        return "";
      } catch (e) {
        console.error(e);
        return "";
      }
    },
    getPublicationProcessColor(tableRowData) {
      try {
        const plan = tableRowData.plan;
        const publicationProcess =
          tableRowData.publication_proccess &&
          tableRowData.publication_proccess;
        const done = tableRowData.done;

        if (
          !plan ||
          done === null ||
          done === undefined ||
          publicationProcess === undefined ||
          publicationProcess === null
        )
          return "";

        const bestResult = this.processResultOfWork(
          "publication_proccess",
          "best",
          plan
        );
        const averageResult = this.processResultOfWork(
          "publication_proccess",
          "average",
          plan
        );

        const sum = publicationProcess + done;

        if (this.isHighlightFulfilled && sum >= bestResult) return "green";

        if (sum >= averageResult && sum < bestResult) return "yellow";

        if (sum < averageResult) return "red";

        return "";
      } catch (e) {
        console.error(e);
        return "";
      }
    },
    getWaitingForContentColor(tableRowData) {
      try {
        const plan = tableRowData.plan;
        const publicationProcess =
          tableRowData.publication_proccess &&
          tableRowData.publication_proccess;
        const waitingContent = tableRowData.waiting_content;
        const done = tableRowData.done;

        if (
          !plan ||
          done === null ||
          done === undefined ||
          publicationProcess === undefined ||
          publicationProcess === null ||
          waitingContent === undefined ||
          waitingContent === null
        )
          return "";

        const bestResult = this.processResultOfWork(
          "waiting_content",
          "best",
          plan
        );
        const averageResult = this.processResultOfWork(
          "waiting_content",
          "average",
          plan
        );

        const sum = publicationProcess + done + waitingContent;

        if (this.isHighlightFulfilled && sum >= bestResult) return "green";

        if (sum >= averageResult && sum < bestResult) return "yellow";

        if (sum < averageResult) return "red";

        return "";
      } catch (e) {
        console.error(e);
        return "";
      }
    },
    getTopicApproveColor(tableRowData) {
      try {
        const plan = tableRowData.plan;
        const publicationProcess =
          tableRowData.publication_proccess &&
          tableRowData.publication_proccess;
        const waitingContent = tableRowData.waiting_content;
        const topicApprove = tableRowData.topic_approve;
        const done = tableRowData.done;

        if (
          !plan ||
          done === null ||
          done === undefined ||
          publicationProcess === undefined ||
          publicationProcess === null ||
          waitingContent === undefined ||
          waitingContent === null
        )
          return "";

        const bestResult = this.processResultOfWork(
          "topic_approve",
          "best",
          plan
        );
        const averageResult = this.processResultOfWork(
          "topic_approve",
          "average",
          plan
        );

        const sum = publicationProcess + done + waitingContent + topicApprove;

        if (this.isHighlightFulfilled && sum >= bestResult) return "green";

        if (sum >= averageResult && sum < bestResult) return "yellow";

        if (sum < averageResult) return "red";

        return "";
      } catch (e) {
        console.error(e);
        return "";
      }
    },
    getBgColor(val) {
      let color = "";
      if (val > 50) {
        color = "rgba(255,112,112,0.14)";
      } else if (val > 20) {
        color = "rgba(255,244,131,0.14)";
      }
      return "background-color:" + color;
    },
    _$smartCustomTableSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (a[sortBy[i]] === null && b[sortBy[i]] === null) continue;

          if (
            this.overdueCols.includes(sortBy[i]) ||
            sortBy[i] === "active_donors"
          ) {
            let aVal, bVal;
            if (
              a[sortBy[i]] === null ||
              a[sortBy[i]] === "" ||
              a[sortBy[i]] === 0
            )
              aVal = 0;
            if (
              b[sortBy[i]] === null ||
              b[sortBy[i]] === "" ||
              b[sortBy[i]] === 0
            )
              bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;

            if (a[sortBy[i]] > b[sortBy[i]]) return sortDesc[i] ? -1 : 1;
            if (a[sortBy[i]] < b[sortBy[i]]) return sortDesc[i] ? 1 : -1;
            continue;
          }

          const string =
            isNaN(parseInt(a[sortBy[i]])) && isNaN(parseInt(b[sortBy[i]]));

          if (string) {
            let aVal, bVal;
            if (
              a[sortBy[i]] === null ||
              a[sortBy[i]] === "" ||
              a[sortBy[i]] === "0"
            )
              aVal = 0;
            if (
              b[sortBy[i]] === null ||
              b[sortBy[i]] === "" ||
              b[sortBy[i]] === "0"
            )
              bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;

            if (a[sortBy[i]] > b[sortBy[i]]) return sortDesc[i] ? -1 : 1;
            if (a[sortBy[i]] < b[sortBy[i]]) return sortDesc[i] ? 1 : -1;
            continue;
          }

          let aVal, bVal;
          if (isNaN(parseInt(a[sortBy[i]])) || a[sortBy[i]] === 0) aVal = 0;
          if (isNaN(parseInt(b[sortBy[i]])) || b[sortBy[i]] === 0) bVal = 0;
          if (aVal === 0 && bVal !== 0) return 1;
          if (bVal === 0 && aVal !== 0) return -1;

          if (parseInt(a[sortBy[i]]) > parseInt(b[sortBy[i]]))
            return sortDesc[i] ? -1 : 1;
          if (parseInt(a[sortBy[i]]) < parseInt(b[sortBy[i]]))
            return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
    async fetchFilters() {
      try {
        this.loadings.filters = true;

        const payload = {
          type: "/gpcr/dashboard",
          take: ["p", "n", "dep", "seo", "resp", "team"],
          filter: {
            projectID: this.$store.getters.active_project || this.project_id,
          },
        };

        const resp = await this.$store.dispatch("global/getFilters", payload);

        if (resp) {
          const keys = Object.keys(resp);
          keys.forEach((key) => {
            this.filtersData[key] = resp[key];
          });
        }
      } catch (e) {
        console.error(
          "Error while loading filters for Content Current Result.",
          e
        );
      } finally {
        this.loadings.filters = false;
      }
    },
    async fetchData() {
      try {
        this.loadings.table = true;

        const url = "/site-workplace/current-result";

        const resp = await service.post(url, this.filters);

        if (resp) {
          this.tableData.items = resp.data.items;
          this.tableData.headers = resp.data.headers;
        }
      } finally {
        this.loadings.table = false;
      }
    },
  },
};
</script>

<style lang="scss">
.v-application.theme--dark {
  .content-current-result {
    &.magnify {
      td:nth-child(8),
      td:nth-child(9),
      td:nth-child(10) {
        white-space: nowrap;
        &:hover {
          background-color: #2f2f2f;
        }
      }
    }
  }
}
.content-current-result {
  &.magnify {
    td:nth-child(8),
    td:nth-child(9),
    td:nth-child(10) {
      &:hover {
        white-space: nowrap;
        border: none;
        font-size: 22px;
        transform: scale(1.6);
        background-color: #fff;
      }
    }
  }
  &__table {
    tbody {
      td:nth-child(1),
      td:nth-child(3),
      td:nth-child(5) {
        border-right: 1px solid rgba(0, 0, 0, 0.15);
      }
    }
  }
}
.v-application.theme--dark {
  .content-current-result {
    &__table {
      tbody {
        td:nth-child(1),
        td:nth-child(3),
        td:nth-child(5) {
          border-right: 1px solid rgba(255, 255, 255, 0.15);
        }
      }
    }
  }
}
</style>
