export default {
  methods: {
    columnStyle(value) {
      if (value === null) return "";

      if (value >= 90) {
        return "color: #0cce6a;";
      } else if (value >= 50) {
        return "color: #ffa400;";
      } else if (value >= 0) {
        return "color: #eb0f00;";
      }
      return "";
    },
    columnStyleFCP28(value) {
      if (value === null) return "";

      if (value > 3000) {
        return "color: #eb0f00;";
      } else if (value >= 1800) {
        return "color: #ffa400;";
      } else if (value >= 0) {
        return "color: #0cce6a;";
      }
      return "";
    },
    columnStyleFIP28(value) {
      if (value === null) return "";

      if (value > 300) {
        return "color: #eb0f00;";
      } else if (value >= 100) {
        return "color: #ffa400;";
      } else if (value >= 0) {
        return "color: #0cce6a;";
      }
      return "";
    },
    columnStyleLCP28(value) {
      if (value === null) return "";

      if (value > 4000) {
        return "color: #eb0f00;";
      } else if (value >= 2500) {
        return "color: #ffa400;";
      } else if (value >= 0) {
        return "color: #0cce6a;";
      }
      return "";
    },
    columnStyleCLS28(value) {
      if (value === null) return "";

      if (value > 25) {
        return "color: #eb0f00;";
      } else if (value >= 10) {
        return "color: #ffa400;";
      } else if (value >= 0) {
        return "color: #0cce6a;";
      }
      return "";
    },
    columnStyleTI(value) {
      if (value === null) return "";

      if (value > 7300) {
        return "color: #eb0f00;";
      } else if (value >= 3900) {
        return "color: #ffa400;";
      } else if (value >= 0) {
        return "color: #0cce6a;";
      }
      return "";
    },
    columnStyleSI(value) {
      if (value === null) return "";

      if (value > 5800) {
        return "color: #eb0f00;";
      } else if (value >= 3400) {
        return "color: #ffa400;";
      } else if (value >= 0) {
        return "color: #0cce6a;";
      }
      return "";
    },
    columnStyleTBI(value) {
      if (value === null) return "";

      if (value > 600) {
        return "color: #eb0f00;";
      } else if (value >= 200) {
        return "color: #ffa400;";
      } else if (value >= 0) {
        return "color: #0cce6a;";
      }
      return "";
    },
  },
};
