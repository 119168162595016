<template>
  <v-dialog
    v-model="dialog"
    width="480"
    content-class="my-shadow--e2"
    scrollable
  >
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2">
        <template v-if="title">
          <b>Select params for {{ title }}</b>
        </template>
        <template v-else>
          <b> Select params </b>
        </template>
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-treeview
          v-model="form.selectedParams"
          selectable
          @input="handleChangeParam"
          :items="allowedParams"
        >
          <template #label="{ item }">
            {{ item.name }}
            <v-badge
              v-if="item.status && item.status !== 'work'"
              color="green"
              :content="item.status"
            >
            </v-badge>

            <v-tooltip v-if="item.info" max-width="300" bottom>
              <template #activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small>
                  mdi-information-outline
                </v-icon>
              </template>
              <span v-html="item.info"></span>
            </v-tooltip>
          </template>

          <template #append="{ item }">
            <div v-if="item.id === 5">
              <template>
                <v-autocomplete
                  v-model="form.country"
                  :items="this.$store.state.reuse.countries"
                  item-key="id"
                  item-text="name"
                  item-value="id"
                  hide-details
                  dense
                  clearable
                  :label="item.name"
                  small-chips
                ></v-autocomplete>
              </template>
            </div>
            <div v-if="item.id === 39">
              <template>
                <v-autocomplete
                  v-model="form.niche"
                  :items="niches"
                  item-key="id"
                  item-text="name"
                  item-value="id"
                  hide-details
                  dense
                  :label="item.name"
                ></v-autocomplete>
              </template>
            </div>
            <div v-if="item.id === 40">
              <template>
                <v-autocomplete
                  v-model="form.region"
                  multiple
                  :items="swCountries"
                  item-key="id"
                  item-text="name"
                  item-value="id"
                  :label="item.name"
                  hide-details
                  clearable
                  deletable-chips
                  dense
                  small-chips
                  style="max-width: 380px"
                ></v-autocomplete>
              </template>
            </div>
          </template>
        </v-treeview>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn large class="text-normal px-6" @click="dialog = false">
          Close
        </v-btn>
        <v-btn
          large
          class="text-normal px-6"
          color="success"
          @click="dialog = false"
        >
          Done
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../mixins/Dialog";
import { NicheItems } from "./defaults";

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    ctx: {
      type: Object,
      default: () => ({}),
    },
  },

  mixins: [Dialog],

  computed: {
    niches: () => NicheItems,
    swCountries() {
      return this.$store.getters["paramsCollecting/getSwCountries"];
    },
    allowedParams() {
      return this.$store.getters["paramsCollecting/getAllowedParams"];
    },
    form: {
      get() {
        return this.ctx;
      },
      set(value) {
        this.$emit("update:ctx", value);
      },
    },
  },

  methods: {
    handleChangeParam() {
      if (this.form.selectedParams.indexOf(20) !== -1) {
        if (this.form.selectedParams.indexOf(12) === -1) {
          this.form.selectedParams.push(12);
        }
        if (this.form.selectedParams.indexOf(13) === -1) {
          this.form.selectedParams.push(13);
        }
        if (this.form.selectedParams.indexOf(15) === -1) {
          this.form.selectedParams.push(15);
        }
      }

      if (this.form.selectedParams.indexOf(27) !== -1) {
        if (this.form.selectedParams.indexOf(11) === -1) {
          this.form.selectedParams.push(11);
        }
      }
    },
  },
};
</script>
