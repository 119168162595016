<template>
  <v-dialog v-model="dialog" v-bind="$attrs" scrollable persistent width="640">
    <v-card class="styled-card--default" flat outlined>
      <v-card-title>
        Collect params by domain
        <v-spacer />
        <v-btn icon @click="handleCloseDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-row>
          <v-col>
            <v-row>
              <v-col cols="12">
                <div>
                  <div class="text-body-2">Task name</div>
                </div>
                <v-text-field
                  v-model="form.taskName"
                  outlined
                  hide-details
                  dense
                  clearable
                  placeholder="Enter task name"
                />
              </v-col>
              <v-col cols="12" class="pb-0">
                <div class="text-body-2">Presets</div>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-card flat outlined rounded="lg" class="text-body-2">
                  <v-card-title
                    class="pa-4 d-flex align-center"
                    @click="handleToggleTopDomainsPreset"
                  >
                    Top domain
                    <v-spacer />
                    <v-switch
                      :value="isTopDomainsPresetActive"
                      hide-details
                      dense
                      class="ma-0"
                    />
                  </v-card-title>
                  <template v-if="isTopDomainsPresetActive">
                    <v-card-text class="pt-3">
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="topDomainsForm.limit"
                            type="number"
                            hide-details
                            label="Domains limit ( max domains )"
                            dense
                            outlined
                            clearable
                            placeholder="Enter limit"
                            @click:clear="topDomainsForm.limit = 0"
                            @change="handleChangeTopDomainsLimit"
                          />
                        </v-col>
                        <v-col>
                          <v-tooltip
                            open-delay="300"
                            bottom
                            content-class="pa-0 rounded-lg"
                          >
                            <template #activator="{ on }">
                              <v-btn
                                v-on="on"
                                class="text-normal"
                                block
                                height="40"
                                @click="handleSelectParams(topDomainsForm)"
                                :color="
                                  topDomainsForm.errors['selectedParams']
                                    ? 'error'
                                    : ''
                                "
                              >
                                <v-icon small left>mdi-wrench-cog</v-icon>
                                Select params
                                <v-badge
                                  v-if="topDomainsForm.selectedParams?.length"
                                  inline
                                  color="secondary"
                                >
                                  <template #badge>
                                    {{ topDomainsForm.selectedParams?.length }}
                                    selected
                                  </template>
                                </v-badge>
                              </v-btn>
                              <div
                                v-show="topDomainsForm.errors['selectedParams']"
                                class="error--text text-body-2 mt-1"
                              >
                                {{ topDomainsForm.errors["selectedParams"] }}
                              </div>
                            </template>
                            <selected-params-info :ctx="topDomainsForm" />
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions
                      v-if="
                        !!topDomainsForm.domains?.length &&
                        topDomainsForm.domains?.length !==
                          parseInt(topDomainsForm.limit)
                      "
                      class="pa-4"
                      style="background: var(--card-darken-body-color)"
                    >
                      <my-badge
                        class="warning white--text mr-2"
                        style="display: inline"
                      >
                        {{ topDomainsForm.domains?.length }}
                      </my-badge>
                      Domains from table are selected
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-icon v-on="on" small class="ml-2">
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <template #default>
                          The specified limit is not the same as the number of
                          domains collected. Only
                          {{ topDomainsForm.domains?.length }} domains were
                          found by this filter.
                        </template>
                      </v-tooltip>
                    </v-card-actions>
                  </template>
                </v-card>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-card flat outlined rounded="lg" class="text-body-2">
                  <v-card-title
                    class="pa-4 d-flex align-center"
                    @click="handleToggleEmptyDataByDomainsPreset"
                  >
                    Empty data by domains
                    <v-spacer />
                    <v-switch
                      :value="isEmptyDataByDomainsPresetActive"
                      hide-details
                      dense
                      class="ma-0"
                    />
                  </v-card-title>
                  <template v-if="isEmptyDataByDomainsPresetActive">
                    <v-card-text
                      class="pa-4 d-flex flex-column"
                      style="gap: 0.5rem"
                    >
                      <template
                        v-for="param in Object.values(emptyDataByDomainsForm)"
                      >
                        <v-card
                          v-if="param"
                          :key="`param-${param.key}`"
                          flat
                          outlined
                          rounded="lg"
                        >
                          <v-card-title>
                            {{ param.title }} ({{ param.key }})
                            <v-spacer />
                            <v-btn
                              text
                              class="text-normal"
                              :disabled="
                                parseInt(param.limit) === param.domains?.length
                              "
                              @click="handleRecalculateParam(param)"
                            >
                              Recalculate
                              <v-icon small right>mdi-refresh</v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-divider />
                          <v-card-text>
                            <v-row dense>
                              <v-col>
                                <v-text-field
                                  v-model="param.limit"
                                  type="number"
                                  hide-details
                                  dense
                                  outlined
                                  clearable
                                  label="Domains limit (max domains)"
                                  placeholder="Enter limit"
                                  @click:clear="param.limit = 0"
                                  @change="
                                    handleChangeParamLimit(param, $event)
                                  "
                                />
                              </v-col>
                              <v-col>
                                <v-tooltip
                                  open-delay="300"
                                  bottom
                                  content-class="pa-0 rounded-lg"
                                >
                                  <template #activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      class="text-normal"
                                      block
                                      height="40"
                                      @click="handleSelectParams(param)"
                                      :color="
                                        param.errors['selectedParams']
                                          ? 'error'
                                          : ''
                                      "
                                    >
                                      <v-icon small left>mdi-wrench-cog</v-icon>
                                      Select params
                                      <v-badge
                                        v-if="param.selectedParams?.length"
                                        inline
                                        color="secondary"
                                      >
                                        <template #badge>
                                          {{ param.selectedParams?.length }}
                                          selected
                                        </template>
                                      </v-badge>
                                    </v-btn>

                                    <div
                                      v-show="param.errors['selectedParams']"
                                      class="error--text text-body-2 mt-1"
                                    >
                                      {{ param.errors["selectedParams"] }}
                                    </div>
                                  </template>
                                  <selected-params-info :ctx="param" />
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions
                            v-if="
                              !!param.domains?.length &&
                              param.domains?.length !== parseInt(param.limit)
                            "
                            class="px-4"
                            style="background: var(--card-darken-body-color)"
                          >
                            <my-badge
                              class="warning white--text mr-2"
                              style="display: inline"
                            >
                              {{ param.domains?.length }}
                            </my-badge>
                            Domains from table are selected
                            <v-tooltip bottom>
                              <template #activator="{ on }">
                                <v-icon v-on="on" small class="ml-2">
                                  mdi-information-outline
                                </v-icon>
                              </template>
                              <template #default>
                                The specified limit is not the same as the
                                number of domains collected. Only
                                {{ param.domains?.length }} domains were found
                                by this filter.
                              </template>
                            </v-tooltip>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-card-text>
                  </template>
                </v-card>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-card flat outlined rounded="lg" class="text-body-2">
                  <v-card-title
                    class="pa-4 d-flex align-center"
                    @click="handleToggleAhrefsParametersDomainsPreset"
                  >
                    Ahrefs parameters
                    <v-spacer />
                    <v-switch
                      :value="isAhrefsParametersPresetActive"
                      hide-details
                      dense
                      class="ma-0"
                    />
                  </v-card-title>
                  <template v-if="isAhrefsParametersPresetActive">
                    <v-card-text
                      class="pa-4 d-flex flex-column"
                      style="gap: 0.5rem"
                    >
                      <template
                        v-for="param in Object.values(ahrefsParametersForm)"
                      >
                        <v-card
                          v-if="param"
                          :key="`param-${param.key}`"
                          flat
                          outlined
                          rounded="lg"
                        >
                          <v-card-title>
                            {{ param.title }} ({{ param.key }})
                            <v-spacer />
                            <v-btn
                              text
                              class="text-normal"
                              :disabled="
                                parseInt(param.limit) === param.domains?.length
                              "
                              @click="handleRecalculateParam(param)"
                            >
                              Recalculate
                              <v-icon small right>mdi-refresh</v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-divider />
                          <v-card-text>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="param.limit"
                                  type="number"
                                  hide-details
                                  dense
                                  outlined
                                  clearable
                                  label="Domains limit (max domains)"
                                  placeholder="Enter limit"
                                  @click:clear="param.limit = 0"
                                  @change="
                                    handleChangeParamLimit(param, $event)
                                  "
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions
                            v-if="
                              !!param.domains?.length &&
                              param.domains?.length !== parseInt(param.limit)
                            "
                            class="px-4"
                            style="background: var(--card-darken-body-color)"
                          >
                            <my-badge
                              class="warning white--text mr-2"
                              style="display: inline"
                            >
                              {{ param.domains?.length }}
                            </my-badge>
                            Domains from table are selected
                            <v-tooltip bottom>
                              <template #activator="{ on }">
                                <v-icon v-on="on" small class="ml-2">
                                  mdi-information-outline
                                </v-icon>
                              </template>
                              <template #default>
                                The specified limit is not the same as the
                                number of domains collected. Only
                                {{ param.domains?.length }} domains were found
                                by this filter.
                              </template>
                            </v-tooltip>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-card-text>
                  </template>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn @click="resetForm" class="px-5 text-normal" large>
          Reset form <v-icon small right>mdi-refresh</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          @click="startCollection"
          color="primary"
          class="px-5 text-normal"
          large
          :loading="loadings.collection"
          :disabled="!isSomePresetsActive"
        >
          Start collection
        </v-btn>
      </v-card-actions>
    </v-card>
    <params-selector
      v-model="isSelectParamWindowVisible"
      :ctx="selectParamWindowContext"
      :title="selectParamWindowContext?.title"
    />
  </v-dialog>
</template>

<script>
import Dialog from "../../../mixins/Dialog";
import { NicheItems } from "./defaults";
import MyBadge from "../../UI/MyBadge.vue";
import ParamsSelector from "./ParamsSelector.vue";
import SelectedParamsInfo from "./SelectedParamsInfo.vue";
import Service from "../../../plugins/service";

export default {
  components: { SelectedParamsInfo, ParamsSelector, MyBadge },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    context: {
      type: Object,
      default: () => ({}),
    },
    taskName: {
      type: String,
      default: "",
    },
  },

  mixins: [Dialog],

  data: () => ({
    isTopDomainsPresetActive: false,
    isEmptyDataByDomainsPresetActive: false,
    isAhrefsParametersPresetActive: false,
    form: {
      selectedParams: [],
      domains: [],
      taskName: "",
      isOnlyHistoricalData: false,
      country: null,
      niche: null,
      region: null,
      domainsString: "",
    },
    topDomainsForm: {
      title: "Top domain",
      limit: 3000,
      selectedParams: [4, 17, 14, 48, 15, 12],
      country: null,
      niche: null,
      region: null,
      domains: [],
      errors: {},
    },
    emptyDataByDomainsForm: {
      gi: {
        title: "Google index",
        key: "gi",
        limit: null,
        domains: [],
        selectedParams: [4],
        country: null,
        niche: null,
        region: null,
        errors: {},
        filter: (item) => {
          return !item.gi;
        },
      },
      wa: {
        title: "Web Archive",
        key: "wa",
        limit: null,
        domains: [],
        selectedParams: [17],
        country: null,
        niche: null,
        region: null,
        errors: {},
        filter: (item) => {
          return !item.wa;
        },
      },
      whois: {
        title: "Whois",
        key: "whois",
        limit: null,
        domains: [],
        selectedParams: [14],
        country: null,
        niche: null,
        region: null,
        errors: {},
        filter: (item) => {
          return !item.whois;
        },
      },
      title: {
        title: "Main page title",
        key: "mp_title",
        limit: null,
        domains: [],
        selectedParams: [48],
        country: null,
        niche: null,
        region: null,
        errors: {},
        filter: (item) => {
          return !item.mp_title;
        },
      },
    },
    ahrefsParametersForm: {
      ahdr: {
        title: "Ahrefs Domain Rating",
        key: "ah_dr",
        limit: null,
        domains: [],
        selectedParams: [15],
        country: null,
        niche: null,
        region: null,
        errors: {},
        filter: (item) => {
          return !item.ah_dr;
        },
      },
      ahLinkData: {
        title: "Ahrefs Link Data",
        key: "ah_rd",
        limit: null,
        domains: [],
        selectedParams: [12],
        country: null,
        niche: null,
        region: null,
        errors: {},
        filter: (item) => {
          return !item.ah_rd;
        },
      },
      ahCommonTraffic: {
        title: "Ahrefs Common Traffic",
        key: "ah_tr_all",
        limit: null,
        domains: [],
        selectedParams: [13],
        country: null,
        niche: null,
        region: null,
        errors: {},
        filter: (item) => {
          return !item.ah_tr_all;
        },
      },
    },
    ignore: false,
    loadings: {
      params: false,
      swCountries: false,
      collection: false,
    },
    validatedDomains: [],
    errorDomains: [],
    errorMessages: {
      domains: [],
      params: [],
    },
    isSelectParamWindowVisible: false,
    selectParamWindowContext: {},
  }),

  watch: {
    domains: {
      handler(value) {
        try {
          if (!value || value?.length === 0) return;

          this.overwriteDomains(value);
        } catch {
          //
        }
      },
      deep: true,
      immediate: true,
    },
    dialog: {
      handler(isOpen) {
        if (isOpen) {
          this.initAllowedParams();
          this.initSwCountries();
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.form.taskName = this.taskName;

    if (this.dialog) {
      this.initAllowedParams();
      this.initSwCountries();
    }
  },

  computed: {
    isSomePresetsActive() {
      return [
        this.isTopDomainsPresetActive,
        this.isAhrefsParametersPresetActive,
        this.isEmptyDataByDomainsPresetActive,
      ].some((v) => !!v);
    },
    niches: () => NicheItems,
    swCountries() {
      return this.$store.getters["paramsCollecting/getSwCountries"];
    },
    allowedParams() {
      return this.$store.getters["paramsCollecting/getAllowedParams"];
    },
  },

  methods: {
    handleSelectParams(param) {
      this.isSelectParamWindowVisible = true;
      this.selectParamWindowContext = param;
      this.$set(param.errors, "selectedParams", null);
    },
    handleChangeTopDomainsLimit(limit) {
      this.applyTopDomainsPreset(limit);
    },
    handleToggleEmptyDataByDomainsPreset() {
      this.isEmptyDataByDomainsPresetActive =
        !this.isEmptyDataByDomainsPresetActive;

      if (this.isEmptyDataByDomainsPresetActive)
        this.applyEmptyParameterPreset(this.emptyDataByDomainsForm);

      if (!this.isEmptyDataByDomainsPresetActive) {
        this.disableEmptyParameterPreset(this.emptyDataByDomainsForm);
      }
    },
    handleToggleAhrefsParametersDomainsPreset() {
      this.isAhrefsParametersPresetActive =
        !this.isAhrefsParametersPresetActive;

      if (this.isAhrefsParametersPresetActive)
        this.applyEmptyParameterPreset(this.ahrefsParametersForm);

      if (!this.isAhrefsParametersPresetActive) {
        this.disableEmptyParameterPreset(this.ahrefsParametersForm);
      }
    },
    disableEmptyParameterPreset(parameters) {
      const params = Object.values(parameters);

      params.forEach((param) => {
        this.form.selectedParams = this.form.selectedParams.filter((p) =>
          param.selectedParams.includes(p)
        );
      });
    },
    applyEmptyParameterPreset(parameters) {
      const params = Object.values(parameters);

      params.forEach((param) => {
        this.setupDomainsForParam(param);
        this.form.selectedParams = [
          ...this.form.selectedParams,
          ...param.selectedParams,
        ];
        if (!param.limit)
          this.$set(param, "limit", param.domains?.length || Infinity);
      });
    },
    handleRecalculateParam(param) {
      param.limit = null;
      this.setupDomainsForParam(param);
      this.$nextTick(() => {
        param.limit = param.domains?.length || Infinity;
      });
    },
    handleChangeParamLimit(param) {
      this.setupDomainsForParam(param);
    },
    setupDomainsForParam(param) {
      const domains = this.getSortedTableItems()
        .filter(param.filter)
        .map((item) => item.domain)
        .splice(0, param.limit || Infinity);
      this.$set(param, "domains", domains);
    },
    handleToggleTopDomainsPreset() {
      this.isTopDomainsPresetActive = !this.isTopDomainsPresetActive;

      if (this.isTopDomainsPresetActive)
        this.applyTopDomainsPreset(this.topDomainsForm.limit);
    },
    getSortedTableItems() {
      return [...this.items].sort((a, b) => {
        const t10Diff = parseInt(b.t10 || 0) - parseInt(a.t10 || 0);
        if (t10Diff !== 0) return t10Diff;

        const t3Diff = parseInt(b.t3 || 0) - parseInt(a.t3 || 0);
        if (t3Diff !== 0) return t3Diff;

        const t1Diff = parseInt(b.t1 || 0) - parseInt(a.t1 || 0);
        if (t1Diff !== 0) return t1Diff;

        return parseInt(b.t20 || 0) - parseInt(a.t20 || 0);
      });
    },
    applyTopDomainsPreset(limit) {
      const domains = this.getSortedTableItems().map((item) => item.domain);

      const domainsString = domains.join("\n");
      const limitedDomains = domains.splice(0, limit);

      this.topDomainsForm.domains = limitedDomains;
      this.form.domains = limitedDomains;
      this.form.domainsString = domainsString;
    },
    handleCloseDialog() {
      this.dialog = false;
      this.resetErrors();
      this.resetForm();
    },
    handleBlurDomainsTextarea() {
      const domains =
        this.form.domainsString !== undefined
          ? this.form.domainsString
              .replaceAll(/\s+|;/gi, ",")
              .split(",")
              .map((v) => v.trim())
              .filter((v) => v.length > 0)
          : [];

      this.form.domainsString = domains
        .map((v) => {
          let domain;

          try {
            domain = new URL(v).hostname;
          } catch (e) {
            domain = new URL("http://" + v).hostname;
          }

          return domain.replace("www.", "");
        })
        .join("\n");

      this.form.domains = this.parseDomainsString(this.form.domainsString);
    },
    resetErrors() {
      this.errorDomains = [];
      this.errorMessages = {
        domains: [],
        params: [],
      };
    },
    validateForm() {
      this.resetErrors();

      let errors = 0;

      if (!this.form.domains) {
        this.errorMessages["domains"]?.push("Domains is required");
        errors++;
      }

      if (!this.validateDomains()) {
        this.errorMessages["domains"]?.push("Invalid domain found");
        errors++;
      }

      if (this.form.selectedParams?.length === 0) {
        this.errorMessages["params"] = "No params selected";
        errors++;
      }

      return errors === 0;
    },
    validateDomains() {
      let validatedDomains = [];
      let errorDomains = [];

      const domainsArr =
        this.form.domainsString !== undefined
          ? this.form.domainsString
              .replaceAll(/\s+|;/gi, ",")
              .split(",")
              .map((v) => v.trim())
              .filter((v) => v.length > 0)
          : [];

      domainsArr.forEach((domain) =>
        this.validateDomain(domain)
          ? validatedDomains.push(domain)
          : errorDomains.push(domain)
      );

      this.validatedDomains = validatedDomains;
      this.errorDomains = errorDomains;

      return validatedDomains.length > 0 && errorDomains.length === 0;
    },
    parseDomainsString(domainsString) {
      const result = [];

      const domainsArr =
        domainsString !== undefined
          ? domainsString
              .replaceAll(/\s+|;/gi, ",")
              .split(",")
              .map((v) => v.trim())
              .filter((v) => v.length > 0)
          : [];

      domainsArr.forEach((domain) =>
        this.validateDomain(domain) ? result.push(domain) : null
      );

      return result;
    },
    validateDomain(domainString) {
      const r = new RegExp("^([a-z0-9-]{1,63}\\.)+[A-Za-z]{2,25}$", "i");
      const m = domainString.toLowerCase().match(r);

      return m && m.length > 0;
    },
    handleChangeParam(form) {
      if (form.selectedParams.indexOf(20) !== -1) {
        if (form.selectedParams.indexOf(12) === -1) {
          form.selectedParams.push(12);
        }
        if (form.selectedParams.indexOf(13) === -1) {
          form.selectedParams.push(13);
        }
        if (form.selectedParams.indexOf(15) === -1) {
          form.selectedParams.push(15);
        }
      }

      if (form.selectedParams.indexOf(27) !== -1) {
        if (form.selectedParams.indexOf(11) === -1) {
          form.selectedParams.push(11);
        }
      }
    },
    async initAllowedParams() {
      try {
        this.loadings.params = true;

        await this.$store.dispatch("paramsCollecting/initAllowedParams");
      } catch (e) {
        console.error("Error while initializing allowed params.", e);
      } finally {
        this.loadings.params = false;
      }
    },
    async initSwCountries() {
      try {
        this.loadings.swCountries = true;

        await this.$store.dispatch("paramsCollecting/initSWCountries");
      } catch (e) {
        console.error("Error while initializing SW countries.", e);
      } finally {
        this.loadings.swCountries = false;
      }
    },
    // Set value over existed domainsValue
    overwriteDomains(value = []) {
      this.form.domains = value;
      this.form.domainsString = value.join("\n");

      this.$nextTick(() => {
        this.handleBlurDomainsTextarea();
      });
    },
    async resetForm() {
      return new Promise((resolve) => {
        this.isAhrefsParametersPresetActive = false;
        this.isEmptyDataByDomainsPresetActive = false;
        this.isTopDomainsPresetActive = false;

        this.topDomainsForm = {
          title: "Top domain",
          limit: 3000,
          selectedParams: [],
          country: null,
          niche: null,
          region: null,
          domains: [],
          errors: {},
        };
        this.emptyDataByDomainsForm = {
          gi: {
            title: "Google index",
            key: "gi",
            limit: null,
            domains: [],
            selectedParams: [4],
            country: null,
            niche: null,
            region: null,
            errors: {},
            filter: (item) => {
              return !item.gi;
            },
          },
          wa: {
            title: "Web Archive",
            key: "wa",
            limit: null,
            domains: [],
            selectedParams: [17],
            country: null,
            niche: null,
            region: null,
            errors: {},
            filter: (item) => {
              return !item.wa;
            },
          },
          whois: {
            title: "Whois",
            key: "whois",
            limit: null,
            domains: [],
            selectedParams: [14],
            country: null,
            niche: null,
            region: null,
            errors: {},
            filter: (item) => {
              return !item.whois;
            },
          },
          title: {
            title: "Main page title",
            key: "mp_title",
            limit: null,
            domains: [],
            selectedParams: [48],
            country: null,
            niche: null,
            region: null,
            errors: {},
            filter: (item) => {
              return !item.mp_title;
            },
          },
        };
        this.ahrefsParametersForm = {
          ahdr: {
            title: "Ahrefs Domain Rating",
            key: "ah_dr",
            limit: null,
            domains: [],
            selectedParams: [15],
            country: null,
            niche: null,
            region: null,
            errors: {},
            filter: (item) => {
              return !item.ah_dr;
            },
          },
          ahLinkData: {
            title: "Ahrefs Link Data",
            key: "ah_rd",
            limit: null,
            domains: [],
            selectedParams: [12],
            country: null,
            niche: null,
            region: null,
            errors: {},
            filter: (item) => {
              return !item.ah_rd;
            },
          },
          ahCommonTraffic: {
            title: "Ahrefs Common Traffic",
            key: "ah_tr_all",
            limit: null,
            domains: [],
            selectedParams: [13],
            country: null,
            niche: null,
            region: null,
            errors: {},
            filter: (item) => {
              return !item.ah_tr_all;
            },
          },
        };

        resolve();
      });
    },
    getFormData(param) {
      console.log("get data from form item:", param);
      if (typeof param !== "object")
        throw new Error(
          "Cant get data from form item because its not an object."
        );

      if (!("domains" in param))
        throw new Error("domains are not exist in form item.");
      if (!("selectedParams" in param))
        throw new Error("selectedParams are not exist in form item.");

      return {
        name: this.form.taskName + ` [${param.title}]`,
        domains: param.domains,
        params: param.selectedParams,
        country: param.country,
        niche: param.niche,
        region: param.region,
        context: this.context,
      };
    },
    setParamError(param, key, error) {
      if (!("errors" in param)) this.$set(param, "errors", {});

      this.$set(param.errors, key, error);
    },
    validate(param) {
      let errors = 0;

      if (!param.selectedParams?.length) {
        errors++;
        this.setParamError(param, "selectedParams", "Params is required.");
      }
      return errors === 0;
    },
    async collect(param) {
      try {
        const url = "/api/dct/task";

        if (!this.validate(param))
          throw new Error("The form did not pass validation.");

        const formData = this.getFormData(param);

        const response = await Service.post(
          url,
          formData,
          true,
          {},
          { prefix: "" }
        );

        return { ...response.data, status: true };
      } catch (e) {
        console.error("Error while processing collect action.", e);
        return { status: false };
      }
    },
    async startCollection() {
      this.loadings.collection = true;

      const items = [];

      if (this.isTopDomainsPresetActive) items.push(this.topDomainsForm);
      if (this.isEmptyDataByDomainsPresetActive)
        items.push(...Object.values(this.emptyDataByDomainsForm));
      if (this.isAhrefsParametersPresetActive)
        items.push(...Object.values(this.ahrefsParametersForm));

      const promises = [];

      items.forEach((item) => {
        promises.push(this.collect(item));
      });

      const results = await Promise.allSettled(promises);

      // Проверяем, есть ли ошибки
      const hasErrors = results.some((result) => !result.value?.status);

      this.loadings.collection = false;

      if (hasErrors) {
        this.$message.notification({
          title: "Error",
          text: "An unexpected error occurred, try again later.",
          type: "error",
        });
      } else {
        this.resetForm();
        this.$message.notification({
          title: "Done",
          text: "The collection has been successfully initiated.",
          type: "success",
          duration: 1500,
        });
      }
    },
    handleSuccessCollection() {
      // this.resetForm();
      // this.resetErrors();
      this.$message.notification({
        title: "Done",
        text: "The collection has been successfully initiated.",
        type: "success",
        duration: 1500,
      });
    },
  },
};
</script>
