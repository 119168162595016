<template>
  <v-row dense>
    <v-col cols="12">
      <page-header
        title="Technical Dashboard"
        :breadcrumbs="[
          { text: 'Home', to: '/' },
          { text: 'Technical Dashboard', disabled: true },
        ]"
      />
    </v-col>
    <v-col cols="12">
      <v-row dense>
        <v-col>
          <v-text-field
            v-model="filters.url"
            label="URL"
            hide-details
            dense
            outlined
            @change="handleFilterChange($event, 'url')"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-autocomplete
            v-model="filters.executive"
            label="Executive"
            :items="executive"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
            @change="handleFilterChange($event, FILTERS_QUERY_IDS.EXECUTIVE)"
            prepend-inner-icon="mdi-account-outline"
            :menu-props="menuProps"
            :loading="loadings.filters"
          ></v-autocomplete>
        </v-col>
        <v-col>
          <v-autocomplete
            v-model="filters.niche"
            label="Niche"
            :items="niches"
            item-value="id"
            item-text="name"
            outlined
            dense
            clearable
            @change="handleFilterChange($event, FILTERS_QUERY_IDS.NICHE)"
            hide-details
            :menu-props="menuProps"
          ></v-autocomplete>
        </v-col>
        <v-col>
          <v-autocomplete
            v-model="filters.project_id"
            label="Project"
            :items="projects"
            item-value="id"
            item-text="name"
            outlined
            dense
            clearable
            @change="handleFilterChange($event, FILTERS_QUERY_IDS.PROJECT_ID)"
            hide-details
            :menu-props="menuProps"
          ></v-autocomplete>
        </v-col>
        <v-col>
          <v-dialog
            ref="dialog"
            v-model="dateDialogModel"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="filters.date"
                label="Select day"
                prepend-inner-icon="mdi-calendar"
                hide-details
                class="mb-1"
                readonly
                dense
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filters.date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateDialogModel = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="dateDialogModel = false">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col>
          <v-btn
            :loading="loadings.table"
            @click="fetchData"
            color="primary"
            block
            style="height: 40px"
            >Get data</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-card flat outlined rounded="lg">
        <v-card-title class="pa-4">
          <v-text-field
            v-model="searchModel"
            append-icon="mdi-magnify"
            label="Search in table"
            hide-details
            dense
            outlined
            style="max-width: 320px"
          ></v-text-field>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-0 pb-3 pt-0">
          <v-row align="center" justify="center">
            <v-col style="position: relative">
              <template v-if="items.length > 0">
                <v-data-table
                  :headers="headers"
                  :items="items"
                  multi-sort
                  dense
                  :loading="loadings.table"
                  :custom-sort="customTableSort"
                  :search="searchModel"
                  class="elevation-1 technical-table"
                  fixed-header
                  :sort-by="['need_top', 'priority']"
                  :sort-desc="[false, false]"
                  :items-per-page="200"
                  :footer-props="{
                    'items-per-page-options': [100, 200, 300, 500, 1000, -1],
                  }"
                >
                  <template
                    v-for="h in headers"
                    #[`header.${h.value}`]="{ header }"
                  >
                    <v-tooltip bottom :key="h.text">
                      <template v-slot:activator="{ on }">
                        <span v-on="on">{{ header.text }}</span>
                      </template>
                      <span>{{ header.tooltip }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr
                        v-for="item in items"
                        :key="item.id_url"
                        style="white-space: nowrap"
                      >
                        <td>
                          <div class="text-body-2">
                            <div
                              class="primary--text"
                              style="font-size: 0.75rem"
                            >
                              {{ parseUrl(item.url)?.domain }}
                            </div>
                            <div>
                              {{ parseUrl(item.url)?.path }}
                            </div>
                          </div>
                        </td>
                        <td>{{ item.code }}</td>
                        <td class="text-center">
                          <v-chip
                            v-if="item.first_contentful_paint !== null"
                            class="flat-chip flat-chip--auto"
                            :style="
                              columnStyleFCP28(item.first_contentful_paint)
                            "
                          >
                            {{ item.first_contentful_paint }}
                          </v-chip>
                        </td>
                        <td class="text-center">
                          <v-chip
                            v-if="item.time_to_interactive !== null"
                            class="flat-chip flat-chip--auto"
                            :style="columnStyleTI(item.time_to_interactive)"
                          >
                            {{ item.time_to_interactive }}
                          </v-chip>
                        </td>
                        <td class="text-center">
                          <v-chip
                            v-if="item.speed_index !== null"
                            class="flat-chip flat-chip--auto"
                            :style="columnStyleSI(item.speed_index)"
                          >
                            {{ item.speed_index }}
                          </v-chip>
                        </td>
                        <td class="text-center">
                          <v-chip
                            v-if="item.total_blocking_time !== null"
                            class="flat-chip flat-chip--auto"
                            :style="columnStyleTBI(item.total_blocking_time)"
                          >
                            {{ item.total_blocking_time }}
                          </v-chip>
                        </td>
                        <td class="text-center">
                          <v-chip
                            v-if="item.largest_contentful_paint !== null"
                            class="flat-chip flat-chip--auto"
                            :style="
                              columnStyleLCP28(item.largest_contentful_paint)
                            "
                          >
                            {{ item.largest_contentful_paint }}
                          </v-chip>
                        </td>
                        <td class="text-center">
                          <v-chip
                            v-if="item.cumulative_layout_shift !== null"
                            class="flat-chip flat-chip--auto"
                            :style="
                              columnStyleCLS28(item.cumulative_layout_shift)
                            "
                          >
                            {{ item.cumulative_layout_shift }}
                          </v-chip>
                        </td>
                        <td class="text-center">
                          <v-chip
                            v-if="item.first_contentful_paint_28 !== null"
                            class="flat-chip flat-chip--auto"
                            :style="
                              columnStyleFCP28(item.first_contentful_paint_28)
                            "
                          >
                            {{ item.first_contentful_paint_28 }}
                          </v-chip>
                        </td>
                        <td class="text-center">
                          <v-chip
                            v-if="item.first_input_delay_28 !== null"
                            class="flat-chip flat-chip--auto"
                            :style="columnStyleFIP28(item.first_input_delay_28)"
                          >
                            {{ item.first_input_delay_28 }}
                          </v-chip>
                        </td>
                        <td class="text-center">
                          <v-chip
                            v-if="item.largest_contentful_paint_28 !== null"
                            class="flat-chip flat-chip--auto"
                            :style="
                              columnStyleLCP28(item.largest_contentful_paint_28)
                            "
                          >
                            {{ item.largest_contentful_paint_28 }}
                          </v-chip>
                        </td>
                        <td class="text-center">
                          <v-chip
                            v-if="item.cumulative_layout_shift_28 !== null"
                            class="flat-chip flat-chip--auto"
                            :style="
                              columnStyleCLS28(item.cumulative_layout_shift_28)
                            "
                          >
                            {{ item.cumulative_layout_shift_28 }}
                          </v-chip>
                        </td>
                        <!---->
                        <td class="text-center">
                          <v-chip
                            v-if="item.performance !== null"
                            class="flat-chip flat-chip--auto"
                            :style="columnStyle(item.performance)"
                          >
                            {{ item.performance }}
                          </v-chip>
                        </td>
                        <td class="text-center">
                          <v-chip
                            v-if="item.accessibility !== null"
                            class="flat-chip flat-chip--auto"
                            :style="columnStyle(item.accessibility)"
                          >
                            {{ item.accessibility }}
                          </v-chip>
                        </td>
                        <td class="text-center">
                          <v-chip
                            v-if="item.best_practices !== null"
                            class="flat-chip flat-chip--auto"
                            :style="columnStyle(item.best_practices)"
                          >
                            {{ item.best_practices }}
                          </v-chip>
                        </td>
                        <td class="text-center">
                          <v-chip
                            v-if="item.seo !== null"
                            class="flat-chip flat-chip--auto"
                            :style="columnStyle(item.seo)"
                          >
                            {{ item.seo }}
                          </v-chip>
                        </td>
                        <td class="text-center">
                          <v-chip
                            v-if="item.pwa !== null"
                            class="flat-chip flat-chip--auto"
                            :style="columnStyle(item.pwa)"
                          >
                            {{ item.pwa }}
                          </v-chip>
                        </td>
                        <td class="text-center">{{ item.need_top }}</td>
                        <td class="text-center">{{ item.priority }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </template>
              <template v-else>
                <div class="table-no-items-placeholders">
                  Select filters and try again... 🤷
                </div>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  DEFAULT_MENU_PROPS,
  DEFAULT_MOMENT_DATE_FORMAT,
  TECHNICAL_TABLE_HEADERS,
} from "@/utils/defaultData";
import TableColumnStylesMixins from "./TableColumnStylesMixins";
import Location from "@/mixins/Location";
import eventPipe from "@/events/eventPipe";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import Project from "@/mixins/Project";
import PageHeader from "../../UI/PageHeader.vue";

export default {
  name: "TechnicalIndex",
  components: { PageHeader },
  mixins: [TableColumnStylesMixins, Location, FiltersHandling, Project],
  data() {
    return {
      menuProps: DEFAULT_MENU_PROPS,
      headers: TECHNICAL_TABLE_HEADERS,
      filterMenuModel: null,
      loadings: {
        filters: false,
        table: false,
      },
      filters: {
        url: null,
        executive: null,
        niche: null,
        project_id: null,
        date: null,
      },
      dateDialogModel: false,
      executive: [],
      searchModel: "",
      FILTERS_QUERY_IDS: {
        NICHE: "niche",
        EXECUTIVE: "executive",
        PROJECT_ID: "project_id",
      },
      items: [],
    };
  },
  computed: {
    projects() {
      return this.$store.state.project.allowed_projects;
    },
    niches() {
      return this.$store.state.reuse.niches;
    },
  },
  methods: {
    parseUrl(urlString) {
      try {
        const parsedUrl = new URL(urlString);

        return {
          domain: parsedUrl.host,
          path: parsedUrl.pathname,
        };
      } catch (e) {
        console.error("Error while parsing url string.", e);
        return {
          domain: "Incorrect domain name",
          path: "Incorrect path",
        };
      }
    },
    getDateColor(date) {
      const currDate = this.$moment(new Date());
      date = this.$moment(date);
      const diff = currDate.diff(date, "days");
      if (diff >= 1) return "red";
      return "";
    },
    handleFilterChange(newValue, type) {
      if (type === this.FILTERS_QUERY_IDS.PROJECT_ID) {
        this._$setActiveProject(newValue);
      }

      this._$setQueryParams(type, newValue);
    },
    async fetchData() {
      this.loadings.table = true;

      const formData = {
        filter: this.filters,
      };

      const resp = await this.$store.dispatch(
        "technical/fetchTableData",
        formData
      );
      if (resp) {
        this.items = resp.items;
      }
      this.loadings.table = false;
    },
    customTableSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        for (let i in sortBy) {
          let aVal, bVal;
          if (isNaN(parseInt(a[sortBy[i]]))) aVal = 0;
          if (isNaN(parseInt(b[sortBy[i]]))) bVal = 0;
          if (aVal === 0 && bVal !== 0) return 1;
          if (bVal === 0 && aVal !== 0) return -1;

          if (a[sortBy[i]] > b[sortBy[i]]) return sortDesc[i] ? -1 : 1;
          if (a[sortBy[i]] < b[sortBy[i]]) return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
    customSort(data) {
      return data.sort((a, b) => {
        let { need_top: need_top1, priority: piority1 } = a;
        let { need_top: need_top2, priority: piority2 } = b;

        // small to big
        if (need_top1 > need_top2) return 1;
        if (need_top1 < need_top2) return -1;

        if (piority1 > piority2) return -1;
        if (piority1 < piority2) return 1;

        return 0;
      });
    },
    async fetchFilters() {
      this.loadings.filters = true;
      const payload = {
        type: "/dashboards/technical/pages",
        take: ["executive"],
      };
      const filters = await this.$store.dispatch("global/getFilters", payload);
      this.executive = filters.executive;
      this.loadings.filters = false;
    },
    handleUpdateActiveProject() {
      this.fetchData();
    },
  },
  async created() {
    this._$collectParams([], "filters");

    if (this.filters.date === null)
      this.filters.date = this.$moment().format(DEFAULT_MOMENT_DATE_FORMAT);

    const project_id = this.$route.query.project_id;
    if (project_id) {
      this.handleFilterChange(
        parseInt(project_id),
        this.FILTERS_QUERY_IDS.PROJECT_ID
      );
    }

    eventPipe.$on("update-active-project", () => {
      this.handleUpdateActiveProject.bind(this);
      this.filters.project_id = this.$store.getters.active_project;
    });

    await this.fetchFilters();
    this.fetchData();
  },
};
</script>

<style scoped></style>
