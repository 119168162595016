<template>
  <v-container fluid class="semantic-tool-item" style="padding-bottom: 200px">
    <v-row dense>
      <v-col cols="12">
        <h2
          class="project-name d-flex align-center"
          v-if="project && project.project_name"
        >
          <v-tooltip
            bottom
            v-if="
              project &&
              project.spiderlink &&
              typeof project.spiderlink.active === 'number'
            "
          >
            <template #activator="{ on }">
              <v-icon
                v-on="on"
                :color="`${project.spiderlink.active ? 'success' : 'error'}`"
                class="mr-2"
              >
                {{
                  project.spiderlink.active === 1
                    ? "mdi-checkbox-marked-circle"
                    : "close-circle"
                }}
              </v-icon>
            </template>
            <div>
              {{
                project.spiderlink.active ? "Project is active" : "Not active"
              }}
            </div>
          </v-tooltip>
          <span>{{ project.project_name }}</span>
          <v-btn
            v-if="project && project.spiderlink && project.spiderlink.link"
            icon
            class="ml-2"
            @click="goToProject(project.spiderlink.link)"
          >
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </h2>
        <h4 v-else>
          <v-skeleton-loader width="100%" type="heading"></v-skeleton-loader>
        </h4>
        <v-breadcrumbs class="px-0 pt-2" :items="breadcrumbs"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col class="d-flex align-end" style="gap: 0.5rem">
        <v-chip
          :disabled="!getFilteredItems?.length"
          outlined
          label
          @click="handleOpenCollectParamsDialog"
        >
          Collect params
          <v-icon small right>mdi-text-box-plus-outline</v-icon>
        </v-chip>

        <vue-json-to-csv
          namem="Semantic tool"
          :json-data="computedTableItemsToCsv"
          csv-title="Semantic_tool"
          :separator="'\t'"
        >
          <v-chip @click="() => {}" label outlined>
            Export to CSV <v-icon small right>mdi-export</v-icon>
          </v-chip>
        </vue-json-to-csv>
      </v-col>
      <v-col>
        <v-btn
          v-if="selected.length > 0"
          rounded
          class="mr-2"
          color="info"
          outlined
          block
          @click="addToNrd"
          :loading="loading.add_to_nrd"
        >
          <v-icon small>mdi-arrow-up</v-icon>
          Add to NRD
        </v-btn>
      </v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col>
        <v-text-field
          v-model="filter.min_nrd"
          type="number"
          hide-details
          dense
          outlined
          label="Min NRD"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="filter.good"
          hide-details
          dense
          style="margin-top: 0"
          label="Only Good"
        ></v-checkbox>
      </v-col>
      <v-col>
        <v-btn @click="fetchData" color="primary" block> Get Data </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card class="styled-card--light">
          <v-card-title>
            <v-row class="table-filters">
              <v-col cols="3">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  dense
                  single-line
                  hide-details
                  style="max-width: 325px"
                ></v-text-field>
              </v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col>
                <smart-date-filter
                  label="Date range filter"
                  :model-value="dateFilter"
                  @update:modelValue="dateFilter = $event"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="minRegPercent"
                  type="number"
                  label="Min Reg,%"
                  dense
                  outlined
                  clearable
                  hide-details
                ></v-text-field
              ></v-col>
            </v-row>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-0">
            <v-data-table
              v-model="selected"
              :search="search"
              item-key="domain"
              :headers="headers"
              @update:sort-by="sortBy = $event"
              @update:sort-desc="sortDesc = $event"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              :items="getFilteredItems"
              :loading="loading.data"
              :items-per-page="20"
              :footer-props="{
                'items-per-page-options': [20, 1000, 2000, 5000, -1],
              }"
              class="semantic-tool-table"
              show-select
              multi-sort
              :custom-sort="_$smartCustomTableSort"
            >
              <template #[`item.domain`]="{ item }">
                <div v-if="item.nrd" class="mark mark--error" />
                <div v-if="item._isSubfolder" class="mark mark--info" />
                <div
                  :class="`${item.nrd ? 'error--text' : ''}${
                    item._isSubfolder ? ' info--text' : ''
                  }`"
                >
                  {{ item.domain }}
                  <a
                    :href="'http://' + item.domain"
                    target="_blank"
                    style="text-decoration: none"
                  >
                    <v-icon small>mdi-open-in-new</v-icon>
                  </a>
                </div>
              </template>
              <template #[`item.reg_p`]="{ value }">
                <gradient-td :value="value" :max="100" />
              </template>
              <template #[`item.sl_exists`]="{ value }">
                <div
                  v-if="value.sync_at"
                  style="white-space: nowrap"
                  :class="`${
                    value.auto_update ? 'success--text' : 'error--text'
                  }`"
                >
                  {{ value.sync_at }}
                </div>
              </template>
              <template #[`item.wa`]="{ item }">
                <td-date :value="item.wa" />
              </template>
              <template #[`item.whois`]="{ item }">
                <td-date :value="item.whois" />
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions />
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="collectingDialog"
      content-class="remove-dialog-shadow pa-1"
      max-width="450px"
    >
      <v-card rounded="xl" class="shadow-e2-bordered">
        <v-card-title>Collecting</v-card-title>
        <v-card-subtitle
          >Number of domains sent for parmeter collection</v-card-subtitle
        >
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="amountDomainCollecting"
                type="number"
                dense
                outlined
                hide-details
                label="Amount"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            @click="handleCollectData"
            color="primary"
            class="rounded-br-lg"
          >
            Collect Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CollectParamsByDomains
      v-if="project.project_name"
      v-model="isCollectDialogVisible"
      width="1240"
      :items="getFilteredItems"
      content-class="my-shadow--e3 rounded-lg"
      :task-name="collectingParamsTaskName"
      :context="collectingParamsContext"
    />
  </v-container>
</template>

<script>
import axios from "axios";
import TdDate from "@/components/SemanticTool/TdDate";
import GradientTd from "@/components/UI/GradientTd";
import CopyComponentId from "@/mixins/copyComponentId";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import { deepClone } from "@/utils/functions";
import VueJsonToCsv from "vue-json-to-csv";
import TableSorting from "../../mixins/TableSorting";
import CollectParamsByDomains from "../ParamsCollection/CollectParamsByDomains/index.vue";

export default {
  name: "SemanticToolDashboard",
  components: {
    CollectParamsByDomains,
    SmartDateFilter,
    GradientTd,
    TdDate,
    VueJsonToCsv,
  },
  mixins: [CopyComponentId, TableSorting],
  data: () => ({
    sortBy: ["t10", "t3", "t1"],
    sortDesc: [true, true, true],
    isCollectDialogVisible: false,
    project: {
      name: null,
    },
    dateFilter: [null, null],
    minRegPercent: null,
    itemsPerPage: 100,
    pageCount: 1,
    filter_menu: false,
    selected: [],
    selectedDomainsForParamsCollecting: [],
    search: "",
    headers: [],
    collectingDialog: false,
    items: [],
    loadings: {
      filters: false,
    },
    copyComponentId: "m41n2ois=ad-ai12j41n2n",
    filter: {
      good: true,
      min_nrd: null,
    },
    loading: {
      data: false,
      add_to_nrd: false,
    },
    breadcrumbs: [
      {
        text: "Semantic tool",
        disabled: false,
        to: "/semantic-tool/index",
        exact: true,
      },
      {
        text: "Dashboard",
        disabled: true,
      },
    ],
    amountDomainCollecting: 1000,
    domainsOnCollecting: [],
  }),
  created() {
    this.fetchData();
  },
  computed: {
    collectingParamsTaskName() {
      return `ST-${this.$route.params.id} ${this.project.project_name}`;
    },
    collectingParamsContext() {
      return {
        service: "semantic-tool",
        id: this.$route.params.id,
      };
    },
    computedTableItemsToCsv() {
      try {
        const cloneItems = deepClone(this.items);

        const headerValues = this.headers.map((header) => header.value);

        cloneItems.forEach((item, idx) => {
          try {
            cloneItems[idx].sl_exists = cloneItems[idx].sl_exists.sync_at;
          } catch {
            cloneItems[idx].sl_exists = "";
          }
          Object.keys(item).forEach((key) => {
            if (!headerValues.includes(key)) {
              delete cloneItems[idx][key];
              return;
            }
            if (cloneItems[idx][key] === null) cloneItems[idx][key] = "";
          });
        });

        return cloneItems;
      } catch (e) {
        console.error("Error while preparing table items to csv.", e);
        return [];
      }
    },
    getFilteredItems() {
      let filteredItems = deepClone(this.items);

      if (this.minRegPercent) {
        filteredItems = filteredItems.filter(
          (item) => parseInt(item.reg_p) >= this.minRegPercent
        );
      }

      if (
        Array.isArray(this.dateFilter) &&
        this.dateFilter[0] &&
        this.dateFilter[1]
      ) {
        filteredItems = filteredItems.filter((item) => {
          const waDate = this.$moment(item.wa);
          const whoisDate = this.$moment(item.whois);

          const start = this.dateFilter[0];
          const end = this.dateFilter[1];

          return (
            (waDate.isAfter(start) && waDate.isBefore(end)) ||
            (whoisDate.isAfter(start) && whoisDate.isBefore(end))
          );
        });
      }

      return filteredItems;
    },
  },
  methods: {
    handleOpenCollectParamsDialog() {
      this.selectedDomainsForParamsCollecting = this.selected?.map(
        (item) => item.domain
      );

      this.$nextTick(() => {
        this.isCollectDialogVisible = true;
      });
    },
    goToProject(url) {
      window.open(url, "_blank");
    },
    handleCollectData() {
      this.amountDomainCollecting = 2000;
      this.$message.notification({
        title: "Success",
        text: "The task was successfully created",
        type: "success",
        duration: 6000,
      });
      this.collectingDialog = false;
    },
    addToNrd() {
      let self = this;

      self.loading.add_to_nrd = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let data = {
        domains: self.selected.map((v) => v.domain),
        taskID: this.$route.params.id,
      };

      axios
        .post(
          `${self.$store.state.server_url}/semantic-tool/dashboard/add-to-nrd`,
          data,
          config
        )
        .then(() => {
          self.loading.add_to_nrd = false;
          self.selected = [];
          self.fetchData();
        });
    },
    fetchData() {
      this.loading.data = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
        params: {
          taskID: this.$route.params.id,
          filter: this.filter,
        },
      };

      axios
        .get(
          `${this.$store.state.server_url}/semantic-tool/dashboard/all`,
          config
        )
        .then((r) => {
          this.headers = r.data.data.headers;
          this.items = r.data.data.items;
          this.project = {
            project_name: r.data.data.project_name,
            spiderlink: r.data.data.spiderlink,
          };
        })
        .finally(() => (this.loading.data = false));
    },
  },
};
</script>

<style lang="scss">
.semantic-tool-item {
  .semantic-tool-table {
    tr {
      th,
      td {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(10),
        &:nth-child(12) {
          border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          text-align: center !important;
        }
      }
      td {
        position: relative;
        & .mark {
          position: absolute;
          left: 0;
          top: 0;
          width: 3px;
          height: 100%;
          &--error {
            background-color: rgba(224, 14, 14, 0.44);
          }
          &--info {
            background-color: #22b2ce;
          }
        }
      }
    }
  }
  .project-name {
  }
}
</style>
