<template>
  <div>
    <v-card flat outlined rounded="lg">
      <v-card-text class="pa-6">
        <div>
          <v-row dense>
            <v-col
              cols="3"
              md="4"
              lg="3"
              xl="fill"
              class="d-flex"
              style="gap: 0.25rem"
            >
              <v-autocomplete
                label="Url"
                v-model="selectedUrl"
                :items="urlsItems"
                clearable
                outlined
                dense
                hide-details
                :disabled="selectedUrl !== null"
                @change="updateUrl"
              ></v-autocomplete>
              <v-tooltip open-delay="300" bottom>
                <template #activator="{ on }">
                  <v-btn
                    @click="goTo(selectedUrl)"
                    v-on="on"
                    :disabled="!selectedUrl"
                    class="px-2 flat-chip flat-chip--primary"
                    height="40"
                    style="min-width: 40px"
                  >
                    <v-icon small>mdi-open-in-new</v-icon>
                  </v-btn>
                </template>
                Visit url
              </v-tooltip>
              <v-tooltip open-delay="300" bottom>
                <template #activator="{ on }">
                  <v-btn
                    @click="reload"
                    :disabled="!selectedUrl"
                    v-on="on"
                    class="px-2 flat-chip flat-chip--error"
                    height="40"
                    style="min-width: 40px"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                Reload
              </v-tooltip>
            </v-col>
            <v-col cols="1" md="2" lg="1" xl="1">
              <v-autocomplete
                label="GI"
                v-model="googleIndexSelected"
                :items="googleIndexItems"
                clearable
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="1" md="2" lg="1" xl="1">
              <v-autocomplete
                label="Follow"
                v-model="followSelected"
                :items="followItems"
                clearable
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" md="2" lg="2" xl="2">
              <v-autocomplete
                label="Link Types"
                v-model="linkTypesSelected"
                :items="linkTypesItems"
                chips
                deletable-chips
                multiple
                small-chips
                dense
                outlined
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="auto" md="auto" lg="auto" xl="1">
              <v-menu
                open-on-hover
                :close-on-content-click="false"
                bottom
                nudge-bottom="35px"
                content-class="rounded-lg remove-dialog-shadow"
              >
                <template #activator="{ on }">
                  <v-btn v-on="on" class="text-normal" height="40">
                    <v-icon small left>mdi-eye-outline</v-icon>
                    Visibility
                    <v-icon small right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-card flat outlined rounded="lg">
                  <v-list>
                    <v-list-item>
                      <v-switch
                        v-model="show_on_page"
                        label="onPage"
                      ></v-switch>
                    </v-list-item>
                    <v-list-item>
                      <v-switch
                        v-model="hide_disavow"
                        label="Hide disavow"
                      ></v-switch>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="auto" md="auto" lg="auto" xl="2">
              <v-checkbox
                v-model="targetKeys"
                hide-details
                dense
                class="ma-1"
                label="Target Keys"
              ></v-checkbox>
            </v-col>
            <v-col cols="fill" class="d-flex justify-end">
              <v-btn class="text-normal px-6" height="40">
                <v-icon small left>mdi-refresh</v-icon>
                Update filters
                <v-icon small right>mdi-filter</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import eventPipe from "../../../events/eventPipe";
import events from "../../../events/components/OnePageAnalyzer/events";
import { OnePageAnalyzerIndex } from "../../../mixins/components/OnePageAnalyzer/Index";
import { CommonIndex } from "../../../mixins/Common";
import FiltersHandling from "../../../mixins/components/FiltersHandling";

export default {
  name: "MainFilter",
  mixins: [OnePageAnalyzerIndex, CommonIndex, FiltersHandling],
  data() {
    return {
      filters: {
        show_on_page: null,
        googleIndexSelected: null,
        followSelected: null,
        linkTypesSelected: [],
        anchorTypesSelected: null,
        selectedUrl: null,
        targetKeys: null,
        hide_disavow: null,
      },
      urlsItems: [],
      googleIndexItems: [
        {
          text: "Yes",
          value: 1,
        },
        {
          text: "No",
          value: 0,
        },
      ],
      followItems: [
        {
          text: "dF",
          value: "df",
        },
        {
          text: "nF",
          value: "nf",
        },
      ],
    };
  },
  async mounted() {
    await this.parseQuery("filters");

    if (Object.keys(this.$route.query).length)
      this.processFilters(this.filters);

    if (this.$route.query.url) {
      this.selectedUrl = this.$route.query.url;
      this.updateUrl();
    }
  },
  computed: {
    linkTypesItems() {
      return this.$store.state.reuse.link_types;
    },
    anchorTypesItems() {
      return this.$store.state.reuse.anchor_types;
    },
    show_on_page: {
      get: function () {
        return this.$store.state.OnePageAnalyzer.filter.show_on_page;
      },
      set: function (value) {
        this.$store.state.OnePageAnalyzer.filter.show_on_page = value;
        this.filters.show_on_page = value;
      },
    },
    hide_disavow: {
      get: function () {
        return this.$store.state.OnePageAnalyzer.filter.hide_disavow;
      },
      set: function (value) {
        this.$store.state.OnePageAnalyzer.filter.hide_disavow = value;
        this.filters.hide_disavow = value;
      },
    },
    googleIndexSelected: {
      get: function () {
        return this.$store.state.OnePageAnalyzer.filter.googleIndex;
      },
      set: function (value) {
        this.$store.state.OnePageAnalyzer.filter.googleIndex = value;
        this.filters.googleIndexSelected = value;
      },
    },
    followSelected: {
      get: function () {
        return this.$store.state.OnePageAnalyzer.filter.follow;
      },
      set: function (value) {
        this.$store.state.OnePageAnalyzer.filter.follow = value;
        this.filters.followSelected = value;
      },
    },
    linkTypesSelected: {
      get: function () {
        return this.$store.state.OnePageAnalyzer.filter.linkTypes;
      },
      set: function (value) {
        this.$store.state.OnePageAnalyzer.filter.linkTypes = value;
        this.filters.linkTypesSelected = value;
      },
    },
    anchorTypesSelected: {
      get: function () {
        return this.$store.state.OnePageAnalyzer.filter.anchorTypes;
      },
      set: function (value) {
        this.$store.state.OnePageAnalyzer.filter.anchorTypes = value;
        this.filters.anchorTypesSelected = value;
      },
    },
    selectedUrl: {
      get: function () {
        return this.$store.state.OnePageAnalyzer.selectedUrl;
      },
      set: function (value) {
        this.$store.state.OnePageAnalyzer.selectedUrl = value;
        this.filters.selectedUrl = value;
      },
    },
    targetKeys: {
      get: function () {
        return this.$store.state.OnePageAnalyzer.filter.targetKeys;
      },
      set: function (value) {
        this.$store.state.OnePageAnalyzer.filter.targetKeys = value;
        this.filters.targetKeys = value;
      },
    },
  },
  methods: {
    processFilters(filters) {
      Object.keys(filters).forEach((key) => {
        this[key] = filters[key];
      });

      if (this.selectedUrl)
        this.$nextTick(() => {
          this.updateUrl();
        });
    },
    updateUrl() {
      let self = this;

      self.$store.dispatch("fetch_link_types_list", {
        params: {
          by_url_ltt: self.selectedUrl,
        },
      });

      if (self.selectedUrl !== null) {
        events.$emit("update-url");
      }

      // this.fetchData()
      // this.updateFilter()
      // this.fetchDynamic()
      // this.fetchVision()
    },
    updateFilter() {
      events.$emit("update-main-filter");
      // this.fetchLinks('default')
      // this.fetchSmallColumns('follow')
      // this.fetchSmallColumns('link_types')
      // this.fetchSmallColumns('anchor_types')
      // this.fetchFollow()
      // this.fetchGI()
      // this.fetchLinksTypesDistribution()
      // this.fetchAnchorTypes()
    },
    fetchUrls() {
      let self = this;

      let url = `${self.$store.state.server_url}/keyword-control/${self.idProject}/get-urls`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios.get(url, config).then((r) => {
        self.urlsItems = r.data.items;
      });
    },
    reload() {
      location.href = location.origin + location.pathname;
    },
  },
  created() {
    let self = this;

    if (self.anchorTypesItems.length === 0) {
      self.$store.dispatch("fetch_anchor_types_list");
    }

    eventPipe.$on("update-active-project", () => {
      self.fetchUrls();
    });

    self.fetchUrls();
  },
};
</script>

<style scoped></style>
