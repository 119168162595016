<template>
  <v-container fluid style="padding-bottom: 200px">
    <v-row>
      <v-col cols="12" class="d-flex">
        <page-header
          title="Site Analysis"
          :breadcrumbs="[
            { text: 'Home', to: '/' },
            { text: 'Site Analysis', disabled: true },
          ]"
        />
        <v-spacer />

        <waiting-info
          :data="sectionsData.waiting"
          :loading="loadings.section.includes(SECTIONS.waiting.sectionName)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.niche"
              hide-details
              outlined
              dense
              label="Niche"
              clearable
              :items="filtersData.niche"
              :error-messages="validationResult?.niche"
              @blur="validationResult.niche = []"
              :menu-props="menuProps"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.link_type"
              hide-details
              outlined
              dense
              label="Link Type"
              clearable
              :loading="loadings.filters"
              :items="filtersData.link_type"
              :error-messages="validationResult?.link_type"
              @blur="validationResult.link_type = []"
              :menu-props="menuProps"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.country"
              :items="countries"
              item-text="name"
              item-value="id"
              outlined
              dense
              clearable
              hide-details
              :menu-props="menuProps"
              :error-messages="validationResult?.country"
              @blur="validationResult.country = []"
              placeholder="Type to search"
              label="Country"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.language"
              hide-details
              outlined
              dense
              label="Language"
              clearable
              :items="languages"
              item-text="name"
              item-value="id"
              :menu-props="menuProps"
              :error-messages="validationResult?.language"
              @blur="validationResult.language = []"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-text-field
              v-model="filters.spiderlink_project_id"
              hide-details
              dense
              outlined
              type="number"
              label="SpiderLink Project ID"
              :error-messages="validationResult?.spiderlink_project_id"
              @blur="validationResult.spiderlink_project_id = []"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-text-field
              v-model="filters.niche_analyzer_id"
              dense
              outlined
              label="Niche Analyzer ID"
              hide-details="auto"
              hint="Divider « , »"
              placeholder="Enter id's"
              :error-messages="validationResult?.niche_analyzer_id"
              @blur="validationResult.niche_analyzer_id = []"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-text-field
              v-model="filters.domain"
              label="Domain"
              name="domain"
              hide-details="auto"
              dense
              placeholder="Enter domain"
              outlined
              @change="handleChangeDomain"
              :error-messages="
                filtersErrorMessage.domain || validationResult?.domain
              "
              @blur="validationResult.domain = []"
              :append-icon="filters.domain ? 'mdi-open-in-new' : ''"
              @click:append="handleOpenDomain"
            />
          </v-col>
          <v-col xl="fill" class="d-flex justify-end">
            <v-btn
              height="40"
              width="180"
              class="text-normal"
              color="primary"
              @click="handleClickGetData"
              :loading="loadings.section.length > 0"
            >
              Get Data
              <v-icon right>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <template v-if="!isFirstUserEnter">
      <site-analysis-additional-info-horizontal
        :loadings="loadings"
        :sections-data="sectionsData"
        :sections="SECTIONS"
      />
      <v-row>
        <v-col>
          <v-card
            v-if="
              pros_cons &&
              Array.isArray(pros_cons.cons.comments) &&
              pros_cons.cons.comments.length > 0 &&
              !loadings.feedbacks
            "
            class="styled-card--borderless"
            elevation="0"
            flat
          >
            <v-card-title>
              <v-icon small left color="error"> mdi-close-circle</v-icon>
              Cons
            </v-card-title>
            <v-card-text class="d-flex flex-column" style="gap: 16px">
              <site-analysis-feedback-card
                v-for="item in pros_cons.cons.comments"
                :key="item.id"
                :item="item"
              />
            </v-card-text>
          </v-card>
          <template
            v-if="pros_cons?.cons?.after?.length > 0 && !loadings.feedbacks"
          >
            <site-analysis-cons-after-section :data="pros_cons" />
          </template>
          <template v-if="loadings.feedbacks">
            <div class="skeleton" style="height: 90px"></div>
          </template>
        </v-col>
        <v-col>
          <v-card
            v-if="
              pros_cons &&
              Array.isArray(pros_cons.pros) &&
              pros_cons.pros.length > 0 &&
              !loadings.feedbacks
            "
            class="styled-card--borderless"
            elevation="0"
            flat
          >
            <v-card-title>
              <v-icon small left color="success"> mdi-check-circle</v-icon>
              Pros
            </v-card-title>
            <v-card-text class="d-flex flex-column" style="gap: 16px">
              <site-analysis-feedback-card
                v-for="item in pros_cons.pros"
                :key="item.id"
                :item="item"
              />
            </v-card-text>
          </v-card>
          <template v-if="loadings.feedbacks">
            <div class="skeleton" style="height: 90px"></div>
          </template>
        </v-col>
      </v-row>
      <radial-bars-section
        class="mt-4"
        :data="sectionsData.score"
        :loading="loadings.section.includes(SECTIONS.score.sectionName)"
      />
      <radial-bars-section
        class="mt-4"
        :data="sectionsData.score2"
        :loading="loadings.section.includes(SECTIONS.score2.sectionName)"
      />
      <v-row>
        <v-col cols="6">
          <!---->
          <sr-dynamic-traffic-keywords-section
            :section-name="SECTIONS.sr_dynamic_traffic_keywords.sectionName"
            :data="sectionsData.sr_dynamic_traffic_keywords"
            :loading="
              loadings.section.includes(
                SECTIONS.sr_dynamic_traffic_keywords.sectionName
              )
            "
          />
        </v-col>
        <v-col cols="6">
          <!---->
          <ah-dynamic-traffic-keywords-section
            :section-name="SECTIONS.ah_dynamic_traffic_keywords.sectionName"
            :data="sectionsData.ah_dynamic_traffic_keywords"
            :loading="
              loadings.section.includes(
                SECTIONS.ah_dynamic_traffic_keywords.sectionName
              )
            "
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <!---->
          <sw-pro-traffic-by-country-section
            :section-name="SECTIONS.sw_traffic_by_countries.sectionName"
            :data="sectionsData.sw_traffic_by_countries"
            :loading="
              loadings.section.includes(
                SECTIONS.sw_traffic_by_countries.sectionName
              )
            "
          />
        </v-col>
        <v-col cols="6">
          <!---->
          <ah-traffic-by-country-section
            :section-name="SECTIONS.traffic_by_countries.sectionName"
            :data="sectionsData.traffic_by_countries"
            :loading="
              loadings.section.includes(
                SECTIONS.traffic_by_countries.sectionName
              )
            "
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <!----->
          <sw-traffic-sources-section
            :section-name="SECTIONS.sw_traffic_sources.sectionName"
            :data="sectionsData.sw_traffic_sources"
            :loading="
              loadings.section.includes(SECTIONS.sw_traffic_sources.sectionName)
            "
          />
        </v-col>
        <v-col cols="6">
          <!---->
          <sw-total-visits-section
            :section-name="SECTIONS.sw_total_visits.sectionName"
            :data="sectionsData.sw_total_visits"
            :loading="
              loadings.section.includes(SECTIONS.sw_total_visits.sectionName)
            "
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <gender-distribution
            :section-name="SECTIONS.gender.sectionName"
            :data="sectionsData.gender"
            :loading="loadings.section.includes(SECTIONS.gender.sectionName)"
          />
        </v-col>
        <v-col cols="8">
          <age-distribution
            :section-name="SECTIONS.age_distribution.sectionName"
            :data="sectionsData.age_distribution"
            :loading="
              loadings.section.includes(SECTIONS.age_distribution.sectionName)
            "
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <!---->
          <sw-keys-section
            :section-name="SECTIONS.sw_keys.sectionName"
            :data="sectionsData.sw_keys"
            :loading="loadings.section.includes(SECTIONS.sw_keys.sectionName)"
          />
        </v-col>
        <v-col cols="4">
          <!---->
          <tr-categories-section
            :section-name="SECTIONS.tr_categories.sectionName"
            :data="sectionsData.tr_categories"
            :loading="
              loadings.section.includes(SECTIONS.tr_categories.sectionName)
            "
          />
        </v-col>
        <v-col cols="4">
          <!---->
          <tr-topics-section
            :section-name="SECTIONS.tr_topics.sectionName"
            :data="sectionsData.tr_topics"
            :loading="loadings.section.includes(SECTIONS.tr_topics.sectionName)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="false" cols="6">
          <!---->
          <ah-detailed-by-country
            :section-name="SECTIONS.ah_detailed_by_countries.sectionName"
            :data="sectionsData.ah_detailed_by_countries"
            :loading="
              loadings.section.includes(
                SECTIONS.ah_detailed_by_countries.sectionName
              )
            "
          />
        </v-col>
        <v-col cols="6">
          <!---->
          <sr-links-profile
            :section-name="SECTIONS.links_profile.sectionName"
            :data="sectionsData.links_profile"
            :loading="
              loadings.section.includes(SECTIONS.links_profile.sectionName)
            "
          />
        </v-col>
        <v-col cols="6">
          <!---->
          <ah-dynamic-referring-domains-pages-section
            :section-name="SECTIONS.dynamic_referring_domains_pages.sectionName"
            :data="sectionsData.dynamic_referring_domains_pages"
            :loading="
              loadings.section.includes(
                SECTIONS.dynamic_referring_domains_pages.sectionName
              )
            "
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <!---->
          <ah-top-pages-section
            :section-name="SECTIONS.top_pages.sectionName"
            :data="sectionsData.top_pages"
            :loading="loadings.section.includes(SECTIONS.top_pages.sectionName)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <!---->
          <ah-top-keys-section
            :section-name="SECTIONS.organic_keywords.sectionName"
            :data="sectionsData.organic_keywords"
            :loading="
              loadings.section.includes(SECTIONS.organic_keywords.sectionName)
            "
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="d-flex">
          <!---->
          <ah-anchors-section
            :section-name="SECTIONS.ah_anchors.sectionName"
            :data="sectionsData.ah_anchors"
            :loading="
              loadings.section.includes(SECTIONS.ah_anchors.sectionName)
            "
          />
        </v-col>
        <v-col cols="6" class="d-flex">
          <!---->
          <ah-linked-anchors-section
            :section-name="SECTIONS.ah_linked_anchors.sectionName"
            :data="sectionsData.ah_linked_anchors"
            :loading="
              loadings.section.includes(SECTIONS.ah_linked_anchors.sectionName)
            "
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex">
          <!---->
          <backlinks
            :section-name="SECTIONS.backlinks.sectionName"
            :data="sectionsData.backlinks"
            :loading="loadings.section.includes(SECTIONS.backlinks.sectionName)"
          />
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-card class="shadow-e1-bordered mx-auto mt-10" rounded="lg" width="300">
        <v-card-title class="d-flex justify-center">
          <v-icon size="80px" color="rgba(155,155,155,0.5)"
            >mdi-information-variant-circle-outline
          </v-icon>
        </v-card-title>
        <v-card-text class="text-center">
          Select filters and click
          <v-chip small label color="primary" @click="handleClickGetData"
            >Get Data
            <v-icon small right>mdi-magnify</v-icon>
          </v-chip>
          to get information about the domain.
        </v-card-text>
      </v-card>
    </template>

    <v-row>
      <v-col cols="12">
        <v-card
          v-if="
            Array.isArray(guides.comments) &&
            guides.comments.length > 0 &&
            Array.isArray(guides.comments[0].comments) &&
            guides.comments[0].comments.length &&
            !loadings.guides
          "
          class="styled-card--borderless"
          elevation="0"
          flat
        >
          <v-card-title>
            <h2 style="font-weight: 500">Placement nuances</h2>
          </v-card-title>
          <v-card-text class="d-flex flex-column" style="gap: 16px">
            <site-analysis-feedback-card
              v-for="item in guides.comments"
              :key="item.id"
              :item="item"
              monochrome-rating="primary"
              disable-write-on-theme-button
            />
          </v-card-text>
        </v-card>
        <div
          v-if="
            guides.after &&
            Array.isArray(guides.after) &&
            guides.after.length > 0 &&
            !loadings.feedbacks
          "
          class="px-4"
        >
          <v-card class="styled-card--default" elevation="0" flat>
            <v-card-title> GPM Nuances</v-card-title>
            <v-divider />
            <v-card-text>
              <v-card
                v-for="(after, idx) in guides.after"
                :key="after.id || idx"
                class="styled-card py-2"
              >
                <div class="d-flex justify-space-between">
                  {{ after.text }}
                  <v-spacer />
                  <v-chip label class="flat-chip flat-chip--primary"
                    >{{ after.value }}
                  </v-chip>
                </div>
              </v-card>
            </v-card-text>
          </v-card>
        </div>
        <template v-if="loadings.guides">
          <div class="skeleton" style="height: 90px"></div>
        </template>
      </v-col>
    </v-row>

    <v-btn
      @click="handleOpenFeedbackDialog"
      fixed
      right
      large
      color="info"
      style="right: 144px; bottom: 22px"
      bottom
    >
      Feedback
      <v-icon small right>mdi-message-alert</v-icon>
    </v-btn>
    <v-btn
      @click="handleOpenPlacementNuancesDialog"
      fixed
      right
      large
      color="info"
      style="right: 290px; bottom: 22px"
      bottom
    >
      Placement nuances
      <v-icon small right>mdi-message-alert</v-icon>
    </v-btn>
    <v-menu top offset-y content-class="shadow-e1-bordered" rounded="lg">
      <template #activator="{ on }">
        <v-btn
          fab
          fixed
          right
          color="info"
          style="right: 80px"
          bottom
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense width="240px">
        <v-menu
          bottom
          content-class="shadow-e2-bordered"
          :close-on-content-click="false"
          rounded="lg"
        >
          <template #activator="{ on }">
            <v-list-item v-on="on">
              <v-list-item-icon>
                <v-icon>mdi-radar</v-icon>
              </v-list-item-icon>
              <v-list-item-content> Page navigation</v-list-item-content>
            </v-list-item>
          </template>
          <page-navigation
            :loading="loadings.section.length > 0"
            :successfully-uploaded-sections="successfullyUploadedSections"
            selector="[data-page-navigation]"
          />
        </v-menu>
        <v-menu
          bottom
          :close-on-content-click="false"
          content-class="my-shadow--e3"
          rounded="lg"
        >
          <template #activator="{ on }">
            <v-list-item v-on="on" @click="() => {}">
              <v-list-item-icon>
                <v-icon>mdi-refresh</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Force update controller
              </v-list-item-content>
            </v-list-item>
          </template>
          <force-update-controller />
        </v-menu>
      </v-list>
    </v-menu>
    <v-btn
      @click="scrollToTop"
      outlined
      fab
      fixed
      right
      color="info"
      bottom
      class="mr-2"
      style="right: 10px"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
    <feedback-dialog
      :model-value="feedbackDialogModel"
      @update:modelValue="feedbackDialogModel = $event"
      @update:formValue="feedbackForm = $event"
      @save="handleSaveFeedback"
      :saving-loading="loadings.savingFeedback"
      :theme-items="filtersData.theme_feedback"
      :comment-loading="loadings.comments"
      :comments="comments"
    />
    <placement-nuances-dialog
      :model-value="placementNuancesDialog"
      @update:modelValue="placementNuancesDialog = $event"
      :comments="guideComments"
      :theme-items="filtersData.theme_guide"
      :loading="loadings.guideComments || loadings.savingGuide"
    />
  </v-container>
</template>

<script>
import { DEFAULT_MENU_PROPS } from "../../utils/defaultData";
import FiltersHandling from "../../mixins/components/FiltersHandling";
import RadialBarsSection from "./Sections/RadialBarsSection.vue";
import service from "../../plugins/service";
import { deepClone, uid } from "../../utils/functions";
import AhDetailedByCountry from "./Sections/AhDetailedByCountry.vue";
import AhDynamicTrafficKeywordsSection from "./Sections/AhDynamicTrafficKeywordsSection.vue";
import SwProTrafficByCountrySection from "./Sections/SwProTrafficByCountrySection.vue";
import AhTrafficByCountrySection from "./Sections/AhTrafficByCountrySection.vue";
import SwTrafficSourcesSection from "./Sections/SwTrafficSourcesSection.vue";
import SwTotalVisitsSection from "./Sections/SwTotalVisitsSection.vue";
import SrDynamicTrafficKeywordsSection from "./Sections/SrDynamicTrafficKeywordsSection.vue";
import AhDynamicReferringDomainsPagesSection from "./Sections/AhDynamicReferringDomainsPagesSection.vue";
import WaitingInfo from "./Sections/WaitingInfo.vue";
import AhTopPagesSection from "./Sections/AhTopPagesSection.vue";
import SwKeysSection from "./Sections/SwKeysSection.vue";
import TrTopicsSection from "./Sections/TrTopicsSection.vue";
import AhTopKeysSection from "./Sections/AhTopKeysSection.vue";
import PageNavigation from "../UI/PageNavigation.vue";
import TrCategoriesSection from "./Sections/TrCategoriesSection.vue";
import GenderDistribution from "@/components/SiteAnalysis/Sections/GenderDistribution/index.vue";
import AgeDistribution from "@/components/SiteAnalysis/Sections/AgeDistribution/index.vue";
import eventPipe from "../../events/eventPipe";
import {
  SITE_ANALYSIS_SECTION_DATA,
  SITE_ANALYSIS_SECTIONS_INTERFACE,
} from "./defaultData";
import SiteAnalysisAdditionalInfoHorizontal from "./Sections/SiteAnalysisAdditionalInfoHorizontal.vue";
import AhAnchorsSection from "./Sections/AhAnchorsSection.vue";
import AhLinkedAnchorsSection from "./Sections/AhLinkedAnchorsSection.vue";
import ForceUpdateController from "./UI/ForceUpdateController.vue";
import FeedbackDialog from "@/components/SiteAnalysis/UI/FeedbackDialog.vue";
import Project from "@/mixins/Project";
import SiteAnalysisFeedbackCard from "@/components/SiteAnalysis/UI/SiteAnalysisFeedbackCard.vue";
import PlacementNuancesDialog from "@/components/SiteAnalysis/UI/PlacementNuancesDialog.vue";
import SiteAnalysisConsAfterSection from "@/components/SiteAnalysis/UI/SiteAnalysisConsAfterSection.vue";
import SrLinksProfile from "@/components/SiteAnalysis/Sections/SrLinksProfile.vue";
import Backlinks from "@/components/SiteAnalysis/Sections/Backlinks.vue";
import PageHeader from "../UI/PageHeader.vue";
import ApiErrorHandlingMixin from "../../mixins/ApiErrorHandlingMixin";

export default {
  components: {
    PageHeader,
    GenderDistribution,
    AgeDistribution,
    Backlinks,
    SrLinksProfile,
    SiteAnalysisConsAfterSection,
    PlacementNuancesDialog,
    SiteAnalysisFeedbackCard,
    FeedbackDialog,
    ForceUpdateController,
    AhLinkedAnchorsSection,
    AhAnchorsSection,
    SiteAnalysisAdditionalInfoHorizontal,
    TrCategoriesSection,
    PageNavigation,
    AhTopKeysSection,
    TrTopicsSection,
    SwKeysSection,
    AhTopPagesSection,
    WaitingInfo,
    AhDynamicReferringDomainsPagesSection,
    SrDynamicTrafficKeywordsSection,
    SwTotalVisitsSection,
    SwTrafficSourcesSection,
    AhTrafficByCountrySection,
    SwProTrafficByCountrySection,
    AhDynamicTrafficKeywordsSection,
    AhDetailedByCountry,
    RadialBarsSection,
  },
  mixins: [FiltersHandling, Project, ApiErrorHandlingMixin],
  data: () => ({
    feedbackDialogModel: false,
    placementNuancesDialog: false,
    feedbackForm: {
      theme: null,
      comment: null,
    },
    comments: [],
    lastUsedThemeId: null,
    lastUsedGuideThemeId: null,
    filters: {
      domain: null,
      language: 41,
      spiderlink_project_id: null,
      link_type: null,
      country: 200,
      niche: 2,
      niche_analyzer_id: null,
    },
    filtersData: {
      link_type: [],
      theme_feedback: [],
      theme_guide: [],
      niche: [],
    },
    loadings: {
      data: false,
      savingFeedback: false,
      savingGuide: false,
      filters: false,
      guides: false,
      guideComments: false,
      feedbacks: false,
      section: [],
      comments: false,
    },
    isfirstFetch: true,
    pros_cons: {
      pros: [],
      cons: [],
    },
    guides: [],
    guideComments: [],
    isFirstUserEnter: true,
    isUpdatePageNavigateSections: false,
    SECTIONS: SITE_ANALYSIS_SECTION_DATA,
    sectionsData: SITE_ANALYSIS_SECTIONS_INTERFACE,
    overallScoresSectionData: {},
    menuProps: DEFAULT_MENU_PROPS,
    filtersErrorMessage: {
      domain: null,
    },
  }),
  watch: {
    filters: {
      deep: true,
      handler(newData) {
        this._$handleFiltersChange(newData);
        this.$store.commit("site-analysis/setFilters", newData);
      },
    },
  },
  computed: {
    successfullyUploadedSections() {
      return this.$store.getters["site-analysis/successfullyUploadedSections"];
    },
    languages() {
      return this.$store.state.reuse.languages;
    },
    countries() {
      return this.$store.state.reuse.countries;
    },
  },
  async mounted() {
    await this.parseQuery("filters");

    const isHasParams = Object.keys(this.$route.query).length;

    this.handleChangeDomain(this.filters.domain);

    this.fetchFilters();

    if (isHasParams) this.fetchSections();

    eventPipe.$on("site-analytics-navigate-to", this.handleNavigateTo);
    eventPipe.$on(
      "site-analytics-dynamic-traffic-keywords-initialized",
      (data) => {
        this.handleDynamicTrafficKeywordsInitialized(data);
      }
    );
    eventPipe.$on("save-new-guide", (formData) => {
      this.handleSaveNewGuide(formData);
    });
    eventPipe.$on("update-pros-cons", () => {
      this.fetchFeedbacks();
    });
    eventPipe.$on("update-guides", () => {
      this.fetchGuides();
    });
    eventPipe.$on("update-guide-comments", (formData) => {
      this.updateGuideCommentsList(formData);
    });
    eventPipe.$on("update-feedback-list", (commentData) => {
      this.updateCommentsList(commentData);
    });

    eventPipe.$on("update-section-data", (sectionData) => {
      console.log("update section data: ", sectionData);
      this.fetchSection(sectionData.name, sectionData.prefix);
    });

    const _60_SEC = 60000;
    setInterval(() => {
      this.handleForceFetchSections(
        this.$store.getters["site-analysis/sectionsWithEnabledForceUpdate"]
      );
    }, _60_SEC);
  },

  methods: {
    handleDynamicTrafficKeywordsInitialized(data) {
      // {"name":"TR C I (sw)","value":100,"type":"percent","used":false}

      const newItems = [
        {
          name: "SR Key 5y",
          value: data?.data?.slope?.sr_key_5y,
          type: "number",
        },
        {
          name: "SR Key 3y",
          value: data?.data?.slope?.sr_key_3y,
          type: "number",
        },
        {
          name: "SR Key 1y",
          value: data?.data?.slope?.sr_key_1y,
          type: "number",
        },
        {
          name: "SR TR 5y",
          value: data?.data?.slope?.sr_tr_5y,
          type: "number",
        },
        {
          name: "SR TR 3y",
          value: data?.data?.slope?.sr_tr_3y,
          type: "number",
        },
        {
          name: "SR TR 1y",
          value: data?.data?.slope?.sr_tr_1y,
          type: "number",
        },
      ];

      newItems.forEach(async (item) => {
        await this.pushCustomDataTo(
          item,
          () => this.sectionsData.score2[0].items[0].items
        );
      });
    },
    pushCustomDataTo(data, getTargetPoint) {
      return new Promise((resolve) => {
        let attempt = 0;
        const interval = setInterval(() => {
          if (attempt > 60) {
            clearInterval(interval);
            return (attempt = 0);
          }

          try {
            const targetPoint = getTargetPoint();

            if (!targetPoint)
              throw new Error(`Target point not exist. Attempt: ${attempt}`);

            targetPoint.push(data);
            resolve();
            clearInterval(interval);
          } catch (e) {
            console.error(e);
          }
        }, 1000);
      });
    },
    async handleSaveNewGuide(formData) {
      await this.saveGuide(formData);
      this.fetchGuides(formData);
      this.guideComments = await this.fetchGuideComments(formData);
    },
    async saveGuide(formData) {
      try {
        const url = "/view-info-by-site/guide/set-guide";
        const payload = {
          domain: this.filters.domain,
          ...formData,
        };

        this.loadings.savingGuide = true;

        const response = await service.post(url, payload);

        if (response && response.data && response.data.success) {
          eventPipe.$emit("site-analysis-guide-success-saved");
        }
      } catch (e) {
        console.error("Error while saving new guide.", e);
      } finally {
        this.loadings.savingGuide = false;
      }
    },
    async handleSaveFeedback(data) {
      await this.saveFeedback(data);
      this.fetchFeedbacks();
    },
    async saveFeedback(data) {
      try {
        const url = "/view-info-by-site/set-feedback";

        const payload = {
          domain: this.filters.domain,
          ...data.form,
        };

        this.loadings.savingFeedback = true;

        const response = await service.post(url, payload);

        if (response && response.data && response.data.success) {
          eventPipe.$emit("site-analysis-feedback-success-saved");
        }
      } catch (e) {
        console.error("Error while saving feedback.", e);
      } finally {
        this.loadings.savingFeedback = false;
      }
    },
    async fetchFeedbacks() {
      try {
        const url = "/view-info-by-site/get-feedbacks";

        const payload = {
          domain: this.filters.domain,
        };

        this.loadings.feedbacks = true;

        const response = await service.post(url, payload);

        if (response && response.data && response.data.data) {
          this.pros_cons = response.data.data || { pros: [], cons: [] };
        }
      } catch (e) {
        console.error("Error while loading feedbacks.", e);
      } finally {
        this.loadings.feedbacks = false;
      }
    },
    async fetchGuides() {
      try {
        const url = "/view-info-by-site/guide/get-guides";

        const payload = {
          domain: this.filters.domain,
        };

        this.loadings.guides = true;

        const response = await service.post(url, payload);

        if (response && response.data && response.data.data) {
          this.guides = response.data.data || [];
        }
      } catch (e) {
        console.error("Error while loading guides.", e);
      } finally {
        this.loadings.guides = false;
      }
    },
    async updateGuideCommentsList(data) {
      if (data.theme_id) this.lastUsedGuideThemeId = data.theme_id;

      console.log("updateGuideCommentsList", data);

      this.guideComments = await this.fetchGuideComments(data);
    },
    async updateCommentsList(data) {
      if (data.theme_id) this.lastUsedThemeId = data.theme_id;

      this.comments = await this.fetchComments(data);
    },
    handleOpenFeedbackDialog() {
      this.feedbackDialogModel = true;
    },
    async handleOpenPlacementNuancesDialog() {
      this.placementNuancesDialog = true;
      // this.guideComments = await this.fetchGuideComments();
    },
    async fetchComments(data) {
      try {
        const url = "/view-info-by-site/get-comments";

        const payload = {
          theme_id: data.theme_id || this.lastUsedThemeId,
          domain: this.filters.domain,
        };

        this.loadings.comments = true;

        const response = await service.post(url, payload);

        if (response && response.data && response.data.data) {
          const arrayOfComments = response.data.data;
          return arrayOfComments;
        }
      } catch (e) {
        console.error("Error while loading feedbacks.", e);
        this.handleApiError(e);
        return [];
      } finally {
        this.loadings.comments = false;
      }
    },
    async fetchGuideComments(formData) {
      try {
        const url = "/view-info-by-site/guide/get-comments";

        const payload = {
          domain: this.filters.domain,
          theme_id: formData.theme_id,
        };

        this.loadings.guideComments = true;

        const response = await service.post(url, payload);

        if (response && response.data && response.data.data) {
          const arrayOfComments = response.data.data;
          return arrayOfComments;
        }
      } catch (e) {
        console.error("Error while loading guide comments.", e);
        this.handleApiError(e);
        return [];
      } finally {
        this.loadings.guideComments = false;
      }
    },
    handleForceFetchSections(arrayOfSectionNames = []) {
      arrayOfSectionNames.forEach((sectionName) => {
        const prefix = (this.SECTIONS[sectionName] || {}).prefix;

        this.fetchSection(sectionName, prefix, true).then((resp) => {
          if (
            resp.data &&
            resp.data.last_updated &&
            resp.data.last_updated.day_diff < 60
          ) {
            this.$store.commit(
              "site-analysis/setSectionsWithEnabledForceUpdate",
              this.removeFromForceUpdateList(sectionName) || []
            );
          }
        });
      });

      return null;
    },
    removeFromForceUpdateList(sectionName) {
      const sections =
        this.$store.getters[
          "site-analysis/sectionsWithEnabledForceUpdate"
        ].valueOf() || [];

      return sections.filter((v) => v !== sectionName);
    },
    addToForceUpdateList(sectionName) {
      const sections =
        this.$store.getters[
          "site-analysis/sectionsWithEnabledForceUpdate"
        ].valueOf() || [];

      return [...sections, sectionName];
    },
    handleChangeDomain(domain) {
      if (!domain) return "";

      let fixedDomain = domain;

      if (
        String(domain).includes("http://") ||
        String(domain).includes("https://")
      ) {
        fixedDomain = new URL(domain).host;
      }

      this.$store.commit("site-analysis/setActiveDomain", fixedDomain);
    },
    handleNavigateTo(sectionName) {
      try {
        const selector = `[data-page-navigation="${sectionName}"]`;

        const titleElement = document.querySelector(selector);

        this.navigateToDom(titleElement);
      } catch (e) {
        console.error(e);
      }
    },
    navigateToDom(domElement) {
      const yOffset = -100;

      const y =
        domElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async fetchFilters() {
      try {
        this.loadings.filters = true;

        const payload = {
          type: "/site-analysis",
          take: ["link_type", "theme_feedback", "theme_guide", "niche"],
        };

        const resp = await this.$store.dispatch("global/getFilters", payload);

        if (resp) {
          this.filtersData = resp;
        }
      } finally {
        this.loadings.filters = false;
      }
    },
    validate() {
      let error = false;

      if (!this.filters.domain) {
        this.filtersErrorMessage.domain = "Required field";
        error = true;
      }

      return error;
    },
    clearFiltersErrorMessages() {
      this.filtersErrorMessage.domain = null;
    },
    handleClickGetData() {
      this.isfirstFetch = true;

      eventPipe.$emit("site-analysis-handle-click-get-data");
      this.comments = [];

      this.fetchSections();
    },
    async fetchSections() {
      if (this.validate()) return;

      this.isFirstUserEnter = false;

      const promises = [];

      this.clearFiltersErrorMessages();

      Object.keys(this.SECTIONS).forEach((sectionName) => {
        const prefix = (this.SECTIONS[sectionName] || {}).prefix;
        promises.push(this.fetchSection(sectionName, prefix));
      });

      promises.push(this.fetchFeedbacks());
      promises.push(this.fetchGuides());

      await Promise.all(promises);

      this.isfirstFetch = false;
      this.handleUpdatePageNavigateSections();
    },
    handleUpdatePageNavigateSections() {
      this.isUpdatePageNavigateSections = true;
      setTimeout(() => {
        this.isUpdatePageNavigateSections = false;
      }, 1000);
    },
    async fetchSection(sectionName, prefix = "", force = false) {
      try {
        const url = "/view-info-by-site/get-data";

        const configs = {
          params: {
            first: this.isfirstFetch,
            force,
            type: prefix + sectionName,
            source: "view-info",
            ...this.filters,
            niche_analyzer_id: String(this.filters.niche_analyzer_id).split(
              ","
            ),
          },
        };
        this.loadings.section.push(sectionName);

        const resp = await service.get(url, true, configs);

        if (resp && resp.data) {
          await this.handleProcessSectionData(resp.data, sectionName);

          this.afterSectionDataProcessed(sectionName, resp.data);

          return resp.data;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.section = this.loadings.section.filter(
          (v) => v !== sectionName
        );
      }
    },
    afterSectionDataProcessed(sectionName, data) {
      if (
        sectionName === this.SECTIONS.sr_dynamic_traffic_keywords.sectionName
      ) {
        eventPipe.$emit(
          "site-analytics-dynamic-traffic-keywords-initialized",
          data
        );
      }
    },
    handleProcessSectionData({ data, success }, sectionName) {
      return new Promise((resolve) => {
        if (success) {
          const sectionNames = [
            ...this.$store.getters[
              "site-analysis/successfullyUploadedSections"
            ],
            sectionName,
          ];
          this.$store.commit(
            "site-analysis/setSuccessfullyUploadedSections",
            sectionNames
          );
        }

        if (["score", "score2"].includes(sectionName)) {
          this.sectionsData[sectionName] =
            this.overallScoresSectionAdapter(data);
          resolve();
          return;
        }

        if (sectionName === "main_static") {
          this.$store.commit("site-analysis/setStaticData", data);
        }

        if (sectionName === "tr_topics") {
          this.$store.commit("site-analysis/setTopicsData", data);
        }

        this.sectionsData[sectionName] = data;

        resolve();
      });
    },
    overallScoresSectionAdapter(rawSectionData) {
      try {
        let tmprData = deepClone(rawSectionData);

        if (!tmprData) return [];

        tmprData.forEach((row) => {
          row.items.forEach((card) => {
            card.id = uid();
          });

          const MIN_AMOUNT_OF_CARDS_IN_SECTION = 3;

          if (row.items.length < MIN_AMOUNT_OF_CARDS_IN_SECTION) {
            while (row.items.length < MIN_AMOUNT_OF_CARDS_IN_SECTION) {
              row.items.push({ placeholder: true });
            }
          }
        });

        return tmprData;
      } catch (e) {
        console.error(e);
      }
    },
    handleOpenDomain() {
      const domain = String(this.filters.domain.valueOf());
      if (domain.includes("http") || domain.includes("https")) {
        return window.open(this.filters.domain, "_blank");
      }
      window.open("https://" + this.filters.domain, "_blank");
    },
  },
};
</script>

<style lang="scss">
.site-analysis-table-section {
  .v-card {
    &__text {
      .v-data-table {
        &__wrapper {
          max-height: 600px;
          overflow: scroll;
        }
      }
    }
  }
}
</style>
