<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <h2>Research Hub</h2>
          <v-breadcrumbs
            class="pa-0"
            :items="items_breadcrumbs"
          ></v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row dense class="mt-4">
        <v-col cols="6" md="4" lg="2" xl="fill">
          <smart-date-filter
            :model-value="filter.date"
            @update:modelValue="filter.date = $event"
          />
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <v-autocomplete
            dense
            outlined
            v-model="filter.status"
            :items="filtersData.status"
            label="Status"
            chips
            :loading="loadings.filters"
            small-chips
            deletable-chips
            hide-details
            clearable
            multiple
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <v-autocomplete
            dense
            outlined
            v-model="filter.responsible"
            :items="filtersData.responsible"
            :loading="loadings.filters"
            label="Responsible"
            hide-details
            chips
            small-chips
            deletable-chips
            clearable
            multiple
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <v-autocomplete
            dense
            outlined
            v-model="filter.tags"
            :items="filtersData.gpm_donor_tags"
            :loading="loadings.filters"
            label="Tags"
            hide-details
            chips
            small-chips
            deletable-chips
            clearable
            multiple
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <v-autocomplete
            dense
            outlined
            v-model="filter.author"
            :items="filtersData.author"
            :loading="loadings.filters"
            label="Author"
            hide-details
            chips
            small-chips
            deletable-chips
            clearable
            multiple
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <v-autocomplete
            dense
            outlined
            v-model="filter.source"
            :items="filtersData.source"
            :loading="loadings.filters"
            label="Source"
            hide-details
            deletable-chips
            clearable
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <v-autocomplete
            dense
            outlined
            v-model="filter.exclude_projects"
            :items="filtersData.projects"
            :loading="loadings.filters"
            label="Exclude projects"
            hide-details
            chips
            small-chips
            deletable-chips
            clearable
            multiple
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <v-autocomplete
            dense
            outlined
            v-model="filter.exclude_teams"
            :items="filtersData.teams"
            :loading="loadings.filters"
            label="Exclude teams"
            hide-details
            chips
            small-chips
            deletable-chips
            clearable
            multiple
          ></v-autocomplete>
        </v-col>
        <v-col cols="fill" class="d-flex justify-end">
          <v-btn
            height="40"
            width="180"
            class="text-normal"
            @click="fetchData"
            color="primary"
            :loading="loading"
          >
            Get Data
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-checkbox
            label="Show without steps"
            v-model="filter.without_steps"
            :true-value="1"
            :false-value="null"
            hide-details
            dense
            class="ma-0"
          ></v-checkbox>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-menu offset-y content-class="shadow-e1-bordered">
            <template v-slot:activator="{ on, attrs }">
              <v-chip label @click="dialog_import_fields_data = true">
                Import fields data
                <v-icon class="ml-1" small>mdi-database-sync-outline</v-icon>
              </v-chip>
              <v-chip
                class="ml-2"
                label
                v-bind="attrs"
                v-on="on"
                :loading="export_loading"
                @click="() => {}"
              >
                <v-icon class="mr-1" small>mdi-cloud-download-outline</v-icon>
                Export
                <v-icon class="ml-1" small>mdi-chevron-down</v-icon>
              </v-chip>
            </template>
            <v-list dense>
              <v-list-item @click="dialog_filtered_domains = true">
                <v-list-item-icon>
                  <v-icon>mdi-filter-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content> Filtered Domains</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card rounded="lg" flat class="border">
            <v-card-title class="pa-4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                dense
                hide-details
                outlined
                class="align-end"
                style="max-width: 360px"
              ></v-text-field>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
              <v-card-title v-if="selected.length > 0" style="gap: 0.5rem">
                <v-chip
                  color="info"
                  label
                  @click="openDialogSendToTask"
                  v-if="selected.length > 0"
                >
                  <v-icon small left>mdi-arrow-up</v-icon>
                  Send To Tasks
                </v-chip>
                <v-chip
                  color="error"
                  label
                  @click="handleDeleteSelected"
                  v-if="selected.length > 0 && can('a')"
                >
                  <v-icon small left>mdi-trash-can</v-icon>
                  Delete
                </v-chip>
              </v-card-title>
              <v-data-table
                v-model="selected"
                :loading="loading"
                :headers="headers"
                :items="items"
                multi-sort
                :search="search"
                :custom-sort="customSort"
                @update:sort-by="updateSortBy"
                @update:sort-desc="updateSortDesc"
                :sort-by="sort_by"
                :sort-desc="sort_desc"
                item-key="domain"
                :footer-props="{ 'items-per-page-options': [10, 15, 50, -1] }"
                :items-per-page="50"
                show-select
              >
                <template #[`item.domain`]="{ item }">
                  <template v-if="item !== null">
                    <template v-if="onDeletingPending.includes(item.id)">
                      <div
                        class="skeleton skeleton--placeholder"
                        style="width: 120px; height: 24px"
                      ></div>
                    </template>
                    <template v-else>
                      <a :href="`http://${item.domain}`" target="_blank">{{
                        item.domain
                      }}</a>
                      <v-btn
                        icon
                        link
                        :href="`/site-analysis?domain=${
                          item.domain
                        }&country=200${
                          item.link_type ? `&link_type=${item.link_type}` : ''
                        }`"
                        target="_blank"
                      >
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        :to="`/gpm/view-entity/${(item && item.id) || ''}`"
                        link
                        target="_blank"
                      >
                        <v-icon>mdi-login-variant</v-icon>
                      </v-btn>
                      <v-chip
                        v-if="item.dbc_cost > 0"
                        color="info"
                        :title="item.dbc_cost + ' $'"
                        class="pa-1 ml-2"
                        x-small
                        label
                        >DBC
                      </v-chip>
                    </template>
                  </template>
                </template>
                <template #[`item.tags`]="{ value, item }">
                  <template v-if="Array.isArray(value)">
                    <div class="d-flex flex-wrap py-1" style="gap: 0.25rem">
                      <v-tooltip
                        v-for="(tag, idx) in value"
                        :key="`tag-${idx}`"
                        bottom
                      >
                        <template #activator="{ on }">
                          <v-chip
                            v-on="on"
                            small
                            :color="tag?.gpm_donor_tag?.color"
                          >
                            {{ tag?.gpm_donor_tag?.name }}
                            <v-progress-circular
                              v-if="checkTagOnLoading(tag.id, item.id)"
                              size="12"
                              width="2"
                              indeterminate
                              class="ml-1"
                            />
                            <v-icon
                              v-else
                              @click="handleRemoveTag(item, tag)"
                              small
                              right
                              >mdi-close-circle</v-icon
                            >
                          </v-chip>
                        </template>
                        <template #default>
                          <div class="pb-2">
                            {{ tag?.user?.name }}
                          </div>
                          <div>
                            {{
                              tag?.created_at
                                ? $moment(tag.created_at).format("ll")
                                : ""
                            }}
                          </div>
                        </template>
                      </v-tooltip>
                    </div>
                  </template>
                </template>
                <template #[`item.steps`]="{ value }">
                  <template>
                    <v-tooltip
                      v-for="step in value"
                      :key="step && step.id"
                      bottom
                      content-class="pa-0 shadow-e1-bordered"
                    >
                      <template #activator="{ on }">
                        <div v-on="on">
                          <b>{{ step && step.name }}</b>
                        </div>
                      </template>
                      <v-card class="pa-2">
                        <v-chip
                          v-for="task in step.tasks"
                          :key="task && task.id"
                          small
                          label
                          class="mx-2"
                        >
                          {{ task && task.name }}
                        </v-chip>
                      </v-card>
                    </v-tooltip>
                  </template>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-btn
      fixed
      dark
      fab
      bottom
      right
      color="pink"
      @click="dialog_upload_domains = true"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog
      v-model="dialog_import_fields_data"
      persistent
      scrollable
      max-width="1000px"
      content-class="shadow-e1-bordered"
    >
      <v-card rounded="lg">
        <v-card-title class="text-body-2">
          Import domains data
          <v-spacer />
          <v-icon @click="dialog_import_fields_data = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" v-if="url_alert.status">
              <v-alert text dismissible :type="url_alert.type"
                >{{ url_alert.msg === "" ? "Something wrong." : url_alert.msg }}
              </v-alert>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="import_form.columns"
                label="Columns"
                required
                hide-details="auto"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="import_form.rows"
                label="Rows"
                outlined
                no-resize
                hide-details="auto"
              ></v-textarea>
              <div style="opacity: 0.7" class="mt-2">
                example.com; example@gmail.com; example@gmail.com; 150; $;
                permanent; Casino,Essay; yes; Nice donor
              </div>
            </v-col>
            <v-col cols="12">
              <v-expansion-panels flat class="border rounded-lg">
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    Columns & Order
                    <template v-slot:actions>
                      <v-icon color="info"> mdi-alert-circle-outline</v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="elevation-0">
                    <div><b>Domain *</b></div>
                    <div><b>Email GP</b></div>
                    <div><b>Email WM/Donor</b></div>
                    <div><b>Price</b></div>
                    <div><b>Currency</b></div>
                    <div>
                      <b>Period</b>
                      <div class="pl-3">
                        permanent<br />
                        per year<br />
                        per month
                      </div>
                    </div>
                    <div>
                      <b>No thematic</b>
                      <div class="pl-3">
                        Casino<br />
                        Essay<br />
                        PaydayLoan<br />
                        Pharma<br />
                        Adult<br />
                      </div>
                    </div>
                    <div><b>Donor Bad Status</b></div>
                    <div><b>Comment</b></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-normal px-6"
            large
            text
            @click="dialog_import_fields_data = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            class="text-normal px-6"
            large
            @click="importFieldsData()"
            :disabled="dialog_loading"
            :loading="dialog_loading"
          >
            Import
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_upload_domains"
      persistent
      scrollable
      content-class="shadow-e1-bordered"
      max-width="600px"
    >
      <v-card rounded="lg">
        <v-card-title class="text-body-2 pa-4">
          Upload domains
          <v-spacer />
          <v-icon @click="dialog_upload_domains = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                outlined
                hide-details
                label="Responsible"
                v-model="form.responsible"
                :items="filtersData.responsible"
                required
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-combobox
                outlined
                hide-details
                label="Tags"
                multiple
                chips
                item-value="value"
                deletable-chips
                :loading="filtersDataPending?.gpm_donor_tags"
                v-model="form.tags"
                :items="filtersData.gpm_donor_tags"
              />
              <div class="text-body-2 mt-2">
                To add your own tag - enter its name and press
                <span class="keycup">Enter</span>
              </div>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="form.domains"
                label="Domains"
                hide-details
                outlined
                auto-grow
                :rows="5"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            large
            text
            @click="dialog_upload_domains = false"
            class="text-normal px-6"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            class="text-normal px-6"
            large
            @click="uploadDomains()"
            :disabled="dialog_loading"
            :loading="dialog_loading"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_add" persistent scrollable max-width="600px">
      <v-card>
        <v-card-title> Upload domains to {{ projectName }}</v-card-title>
        <v-card-text>
          <v-autocomplete
            solo
            label="Anchor Plan Task"
            v-model="anchor_plan_task"
            :items="anchor_plan_tasks"
            multiple
            required
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog_add = false">
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="sendToTask()"
            :disabled="dialog_add_loading"
            :loading="dialog_add_loading"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_filtered_domains"
      persistent
      scrollable
      max-width="400px"
      content-class="shadow-e1-bordered"
    >
      <v-card rounded="lg" flat class="border">
        <v-card-title class="pa-4 text-body-2">
          Export Filtered Domains
          <v-spacer />
          <v-icon @click="dialog_filtered_domains = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12">
              <v-dialog
                ref="dialog"
                v-model="date_modal"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filter_filtered_domains.date_range"
                    label="Picker in dialog"
                    prepend-inner-icon="mdi-calendar"
                    hide-details
                    class="mb-1"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filter_filtered_domains.date_range"
                  range
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="date_modal = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="date_modal = false">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                outlined
                v-model="filter_filtered_domains.projects"
                :items="projects"
                item-value="id"
                item-text="name"
                label="Projects"
                chips
                small-chips
                deletable-chips
                clearable
                multiple
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                outlined
                v-model="filter_filtered_domains.statuses"
                :items="filtersData.status"
                label="Statuses"
                hide-details
                chips
                small-chips
                deletable-chips
                clearable
                multiple
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                outlined
                v-model="filter_filtered_domains.responsible"
                :items="filtersData.responsible"
                label="Responsible"
                hide-details
                chips
                small-chips
                deletable-chips
                clearable
                multiple
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="filter_filtered_domains.columns"
                label="Email GP"
                value="email_gp"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="filter_filtered_domains.columns"
                label="Email WM"
                value="email_wm"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="filter_filtered_domains.columns"
                label="Price"
                value="price"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="filter_filtered_domains.columns"
                label="Currency"
                value="currency"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-normal px-6"
            large
            text
            @click="dialog_filtered_domains = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            class="text-normal px-6"
            large
            @click="exportFilteredDomains"
          >
            Export
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <agree-dialog
      :model-value="agreeDialogModel"
      @update:modelValue="agreeDialogModel = $event"
    >
      <template #title> Are you sure? </template>
      <template #default>
        You want do delete selected? The action cannot be undone or the deleted
        data cannot be returned.
      </template>
      <template #actions>
        <v-row dense no-gutters>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn @click="disagree" text color="grey">Cancel</v-btn>
            <v-btn @click="agree" color="error" class="ml-4 b-rb-shaped-4"
              >Delete</v-btn
            >
          </v-col>
        </v-row>
      </template>
    </agree-dialog>
  </div>
</template>

<script>
import axios from "axios";
import dateFormat from "dateformat";
import SmartDateFilter from "../../UI/SmartDateFilter.vue";
// import { DEFAULT_MOMENT_DATE_FORMAT } from "../../../utils/defaultData";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import { deepClone } from "../../../utils/functions";
import service from "../../../plugins/service";
import PermissionsMixin from "../../../mixins/PermissionsMixin";
import AgreeDialogMixin from "../../../mixins/AgreeDialogMixin";
import AgreeDialog from "../../UI/AgreeDialog.vue";
import { researchHubApiService } from "../services/ResearchHubApiService";
import UploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";

const date = new Date();

export default {
  name: "ResearchHub",
  components: { SmartDateFilter, AgreeDialog },
  mixins: [
    FiltersHandling,
    PermissionsMixin,
    AgreeDialogMixin,
    UploadFiltersDataMixin,
  ],
  data: () => ({
    onDeletingPending: [],
    selected: [],
    settingsData: [],
    import_form: {
      columns:
        "domain;email_gp;email_wm;price;currency;period;no_thematic;donor_bad_status;comment",
      rows: "",
    },
    url_alert: {
      status: false,
      type: "success",
      msg: "",
    },
    search: "",
    loading_filter: false,
    export_loading: false,
    loading: false,
    date_modal: false,
    date_modal_add: false,
    dialog_upload_domains: false,
    dialog_filtered_domains: false,
    dialog_import_fields_data: false,
    dialog_loading: false,
    dialog_add: false,
    dialog_add_loading: false,
    filter_active: false,
    anchor_plan_task: [],
    anchor_plan_tasks: [],
    filter: {
      source: null,
      status: [],
      tags: [],
      exclude_projects: [],
      exclude_teams: [],
      responsible: [],
      author: undefined,
      date: [null, null],
      without_steps: null,
    },
    filter_filtered_domains: {
      date_range: [
        dateFormat(
          new Date(date.getFullYear(), date.getMonth(), 1),
          "yyyy-mm-dd"
        ),
        dateFormat(
          new Date(date.getFullYear(), date.getMonth() + 1, 0),
          "yyyy-mm-dd"
        ),
      ],
      projects: [],
      statuses: [],
      columns: [],
      responsible: [],
    },
    items_breadcrumbs: [
      {
        text: "Gpm",
        disabled: true,
      },
      {
        text: "Research Hub",
        disabled: true,
      },
    ],
    headers: [
      { text: "Domain", value: "domain" },
      { text: "Price", value: "price" },
      { text: "Status", value: "status" },
      { text: "Tags", value: "tags" },
      { text: "Task", value: "task" },
      { text: "Responsible", value: "responsible" },
      { text: "Author", value: "author" },
      { text: "AH DR", value: "ah_dr" },
      { text: "AH Traffic", value: "ah_traffic" },
      { text: "AH Keywords", value: "ah_keywords" },
      { text: "RD LRD", value: "rd_lrd" },
      { text: "Steps", value: "steps" },
      { text: "Added At", value: "created_at" },
      { text: "", value: "actions" },
    ],
    sort_by: ["created_at", "ah_traffic"],
    sort_desc: [true, true],
    form: {
      responsible: null,
      tags: [],
      domains: null,
    },
    items: [],
    status_items: [],
    responsible_items: [],
    author_items: [],
    loadings: {
      removeTag: [],
      filters: false,
    },
    filtersData: {
      projects: [],
      teams: [],
      source: [],
      author: [],
      date: [],
      gpm_donor_tags: [],
      responsible: [],
      status: [],
    },
  }),
  computed: {
    projectName() {
      return this.$store.state.project.domain;
    },
    idProject() {
      return this.$store.state.project.active_project;
    },
    projects() {
      return this.$store.state.project.allowed_projects;
    },
    statuses() {
      let self = this;

      if (self.items.length === 0) {
        return [];
      }

      let statuses = [];
      let used_statuses = [];

      self.items.forEach((i) => {
        if (used_statuses.indexOf(i.status) === -1) {
          used_statuses.push(i.status);
          statuses.push({
            text: i.status,
            value: i.status_id,
          });
        }
      });

      return statuses;
    },
  },
  methods: {
    checkTagOnLoading(tagId, domainId) {
      return this.loadings.removeTag.includes(`tag-${tagId}${domainId}`);
    },
    async handleRemoveTag(domain, tag) {
      try {
        const service = researchHubApiService();

        this.loadings.removeTag.push(`tag-${tag.id}${domain.id}`);

        const { success } = await service.removeTagFromDomain(
          tag.domain_id,
          tag.gpm_donor_tag_id
        );

        if (success === 1)
          domain.tags = domain.tags.filter((t) => t.id !== tag.id);
      } catch (e) {
        console.log("Error while removing ");
      } finally {
        this.loadings.removeTag = this.loadings.removeTag.filter(
          (str) => str !== `tag-${tag.id}${domain.id}`
        );
      }
    },
    async handleDeleteSelected() {
      if (!(await this.open())) return;

      const service = researchHubApiService();

      for (const tableItem of this.selected) {
        this.onDeletingPending.push(tableItem.id);

        const isSuccessfullyDeleted = await service.deleteDomain(tableItem.id);

        if (isSuccessfullyDeleted) {
          this.selected = this.selected.filter(
            (item) => item.id !== tableItem.id
          );
          this.items = this.items.filter((item) => item.id !== tableItem.id);
          await this.$nextTick();
        }

        this.onDeletingPending = this.onDeletingPending.filter(
          (id) => id !== tableItem.id
        );
      }
    },
    importFieldsData() {
      let self = this;

      self.dialog_loading = true;

      let url = `${self.$store.state.server_url}/gpm/task-view/import-fields-data-research-hub`;

      let config = {
        params: {
          projectID: self.idProject,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .post(url, self.import_form, config)
        .catch(() => {
          self.url_alert = {
            status: true,
            type: "error",
            msg: "Server Error. Check columns count & rows!",
          };
        })
        .then((r) => {
          if (
            (r.data.error !== undefined && r.data.error !== null) ||
            !r.data.success
          ) {
            self.url_alert = {
              status: true,
              type: "error",
              msg:
                r.data.error === ""
                  ? "Server Error. Check columns count & rows!"
                  : r.data.error,
            };
          } else {
            self.url_alert = {
              status: true,
              type: "success",
              msg: "Data imported successfully.",
            };
          }
        })
        .finally(() => {
          self.dialog_loading = false;
        });
    },
    updateSortBy() {},
    updateSortDesc() {},
    customSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        for (let i in sortBy) {
          let a_val = parseInt(a[sortBy[i]]);
          let b_val = parseInt(b[sortBy[i]]);

          if (sortBy[i] === "created_at") {
            a_val = new Date(a[sortBy[i]]).getTime();
            b_val = new Date(b[sortBy[i]]).getTime();
          }

          if (sortBy[i] === "author_position" && a[sortBy[i]] === "SEO")
            return sortDesc[i] ? 1 : -1;
          if (sortBy[i] === "author_position" && b[sortBy[i]] === "SEO")
            return sortDesc[i] ? -1 : 1;

          if (a_val > b_val) return sortDesc[i] ? -1 : 1;
          if (a_val < b_val) return sortDesc[i] ? 1 : -1;
        }

        return 0;
      });

      return items;
    },
    exportFilteredDomains() {
      let self = this;

      self.export_loading = true;

      let url = `${self.$store.state.server_url}/export/base/get-hash`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .post(url, self.filter_filtered_domains, config)
        .then((response) => {
          if (response.data.success) {
            location.href =
              `${self.$store.state.server_url}/export/gpm/filtered-domains-research-hub/0?hash=` +
              response.data.hash;
          }
        })
        .finally(() => {
          self.export_loading = false;
        });
    },
    openDialogSendToTask() {
      let self = this;

      self.dialog_add = true;

      let url = `${self.$store.state.server_url}/gpm/task-view/get-anchor-plan-tasks`;

      let config = {
        params: {
          projectID: 0,
          type: 1,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios.get(url, config).then((r) => {
        self.anchor_plan_tasks = r.data.items;
      });
    },
    sendToTask() {
      let self = this;

      self.dialog_add_loading = true;

      let url = `${self.$store.state.server_url}/gpm/task-view/upload-domains`;

      let config = {
        params: {
          typeID: 1,
          source: "research_hub_upload",
          projectID: self.idProject,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let post_data = {
        domains: this.selected.map((v) => v.domain),
        entities: this.selected.map((v) => v.id),
        anchorPlanTaskIDs: self.anchor_plan_task,
      };

      axios.post(url, post_data, config).finally(() => {
        self.dialog_add_loading = false;
        self.dialog_add = false;
        self.fetchData();
      });
    },
    getFixedTags() {
      if (typeof this.form.tags === "string") return [this.form.tags];

      return this.form.tags.map((tag) => {
        if (typeof tag === "string") return tag;

        if (typeof tag === "object" && "value" in tag) return tag.value;

        return "";
      });
    },
    async uploadDomains() {
      try {
        this.dialog_loading = true;

        const url = "/gpm/task-view/upload-domains";

        const config = {
          params: {
            typeID: 3,
            source: "research_hub",
            projectID: this.idProject,
          },
        };

        const tags = this.getFixedTags();

        const post_data = {
          domains: this.form.domains
            .split("\n")
            .map((v) => v.trim())
            .filter((v) => v.length > 0),
          responsible: this.form.responsible,
          tags,
        };

        const resp = await service.post(url, post_data, true, config);

        if (resp && resp.status) {
          this.$message.notification({
            title: "Upload domains",
            text: `<div><div>Exists: <b>${resp.data.stat.exists}</b></div><div>Created: <b>${resp.data.stat.created}</b></div></div>`,
            type: "success",
            duration: 6000,
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.dialog_loading = false;
        this.dialog_upload_domains = false;
        this.fetchData();
      }
    },
    async fetchFilters() {
      try {
        this.loadings.filters = true;

        const payload = {
          type: "/gpm/research-hub",
          take: ["source", "author", "date", "responsible", "status", "steps"],
        };

        const resp = await this.$store.dispatch("global/getFilters", payload);

        if (resp) {
          this.filtersData = { ...this.filtersData, ...resp };
        }
      } finally {
        this.loadings.filters = false;
      }
    },
    async fetchData() {
      try {
        const url = "/gpm/task-view/get-dashboard-data";

        const fixedFilters = deepClone(this.filter);
        if (JSON.stringify(fixedFilters.date) === JSON.stringify([null, null]))
          fixedFilters.date = null;

        const config = {
          params: {
            typeID: 3,
            filter: fixedFilters,
            filter_header: this.$route.query,
            dashboardType: "research_hub",
          },
        };

        this.loading = true;

        const response = await service.get(url, true, config);

        if (response) {
          this.items = response.data.items;
          this.author_items = response.data.author_items;
          this.responsible_items = response.responsible_items;
        }
      } catch (e) {
        console.error("Error while loading data for research hub.", e);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    await this.parseQuery("filter");
    await this.fetchFilters();
    await this.uploadFiltersData(["gpm_donor_tags", "teams", "projects"]);

    if (Object.keys(this.$route.query).length > 0) this.fetchData();
  },
};
</script>
